import React, { useEffect, useMemo, useState } from 'react'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { keys, pick } from 'ramda'
import { routes } from 'core/utils/routes'
// import DocumentMeta from 'pf9-ui-components/built/components/DocumentMeta'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import {
  listVirtualMachines,
  listAllVirtualMachines,
  deleteVirtualMachine,
  getConsoleEndpoint,
  getNovaVersion,
} from 'openstack/components/vms/actions'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import {
  virtualMachinesSelector,
  allVirtualMachinesSelector,
} from 'openstack/components/vms/selectors'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { SortingState } from 'core/elements/grid/hooks/useGridSorting'
import InferActionParams from 'core/actions/InferActionParams'
import { useAppSelector } from 'app/store'
import { durationBetweenDates } from 'utils/misc'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import Text from 'core/elements/Text'
import VmStateCellComponent from 'openstack/components/vms/VmStateCellComponent'
import Tooltip from 'core/elements/tooltip'
import { humanReadableSize } from 'openstack/helpers'
import VmInfoCards from 'openstack/components/vms/VmInfoCards'
import ToggleSwitch from 'core/elements/ToggleSwitch'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listTenants } from 'account/components/userManagement/tenants/new-actions'
import { tenantsSelector } from 'account/components/userManagement/tenants/selectors'
import { createResourceLabelsCell } from 'k8s/components/common/entity/labels-and-annotations/helpers'

type ModelDataKey = DataKeys.OpenstackVirtualMachines
type SelectorModel = ArrayElement<ReturnType<typeof virtualMachinesSelector>>
type ActionParams = InferActionParams<typeof listVirtualMachines>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {}

const requiredParams: Array<keyof ActionParams> = []
const defaultParams: Params & SortingState = {
  orderBy: 'created',
  orderDirection: 'desc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'OpenstackVirtualMachines',
  listTablePrefs,
)

const searchTargets = ['name', 'id']

const IpAddressesCellComponent = ({ value }) => {
  const classes = useStyles()

  const ipAddressKeys = keys(value)
  return (
    <div className={classes.addresses}>
      {ipAddressKeys?.map((key: string) => {
        return (
          <div key={key}>
            <div>
              <b>{key}</b>
            </div>
            {value[key].map((address) => (
              <div key={address.addr}>{address.addr}</div>
            ))}
          </div>
        )
      })}
    </div>
  )
}

const FlavorCellComponent = ({ value, item }) => {
  const classes = useStyles()
  const flavor = item?.flavorDetails
  return (
    <div className={classes.inlineBlock}>
      <Tooltip
        customBody={
          <div className={classes.flavorTooltip}>
            <div>
              <b>VCPUs</b> {flavor?.vcpus}
            </div>
            <div>
              <b>Memory</b> {humanReadableSize(flavor?.ram * 1024 * 1024)}
            </div>
            <div>
              <b>Disk</b> {humanReadableSize(flavor?.disk * 1024 * 1024 * 1024)}
            </div>
          </div>
        }
      >
        <Text variant="body2" className={classes.linkAppearance}>
          {value}
        </Text>
      </Tooltip>
    </div>
  )
}

const TenantCellComponent = ({ value, item }) => {
  useListAction(listTenants)
  const tenants = useSelectorWithParams(tenantsSelector, {})
  const tenant = tenants.find((ten) => ten.id === value)
  return <Text variant="body2">{tenant?.name || tenant?.id || value}</Text>
}

const vmColumns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
  },
  {
    key: 'id',
    label: 'UUID',
    display: false,
  },
  {
    key: 'ownerName',
    label: 'Owner',
  },
  {
    key: 'tenant_id',
    label: 'Tenant',
    CellComponent: TenantCellComponent,
  },
  {
    key: 'state',
    label: 'State',
    CellComponent: VmStateCellComponent,
  },
  {
    key: 'addresses',
    label: 'IP Addresses',
    CellComponent: IpAddressesCellComponent,
  },
  {
    key: 'flavorName',
    label: 'Flavor',
    CellComponent: FlavorCellComponent,
  },
  {
    key: 'imageName',
    label: 'Source Image',
  },
  {
    key: 'OS-EXT-SRV-ATTR:hypervisor_hostname',
    label: 'Host',
    // Todo: cell component with link
  },
  {
    key: 'volumeNames',
    label: 'Volumes',
    render: (names) => names?.join(', '),
    // Todo: cell component with links
  },
  {
    key: 'created',
    label: 'Age',
    formatFn: (value) => durationBetweenDates({ labels: ['d'] })(value),
  },
  {
    key: 'security_groups',
    label: 'Security Groups',
    render: (groups) => groups?.map((group) => group?.name).join(', '),
  },
  {
    key: 'metadata',
    label: 'Metadata',
    CellComponent: createResourceLabelsCell({ type: 'table', separator: '=' }),
  },
]

export default function VirtualMachinesListPage({ host }) {
  const { allParams: params, updateGlobalParams, getParamsUpdater } = useGlobalParams(
    usePrefParams,
    defaultParams,
  )
  const classes = useStyles()

  const { message: allMessage, loading: allLoading, reload: allReload } = useListAction(
    listAllVirtualMachines,
    {
      params,
      requiredParams,
    },
  )
  const allData = useAppSelector(allVirtualMachinesSelector)
  const hostVms = useMemo(() => {
    return allData.filter((vm) => {
      return vm?.['OS-EXT-SRV-ATTR:host'] === host?.id
    })
  }, [allData, host])

  useEffect(() => {
    getNovaVersion()
  }, [])

  return (
    <>
      <VmInfoCards vms={hostVms} />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.OpenstackVirtualMachines}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={allLoading}
        loadingMessage={allMessage}
        onRefresh={allReload}
        data={hostVms}
        columns={vmColumns}
        getParamsUpdater={getParamsUpdater}
        showBreadcrumbs={false}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  addresses: {
    display: 'grid',
    gap: 8,
  },
  dropdownAction: {
    background: theme.components.dropdown.background,
    border: 'none',
    '& .button-text': {
      color: theme.components.dropdown.color,
      justifyContent: 'start',
    },
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  inlineBlock: {
    display: 'inline-block',
  },
  flavorTooltip: {
    padding: 8,
  },
  linkAppearance: {
    color: theme.palette.primary.main,
  },
}))
