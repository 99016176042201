import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import { updateFloatingIp } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import useParams from 'core/hooks/useParams'
import PicklistField from 'core/components/validatedForm/DropdownField'
import VmPicklist from './VmPicklist'
import AvailableVmPortsPicklist from './AvailableVmPortsPicklist'
import { waitSeconds } from 'openstack/components/infrastructure/blueprint/helpers'
import { refreshVirtualMachine } from 'openstack/components/vms/actions'

const useStyles = makeStyles<Theme>((theme) => ({
  fields: {
    gap: 16,
    display: 'grid',
  },
}))

export default function AssignFloatingIpDialog({ rows: [floatingIp], onClose }) {
  const classes = useStyles()
  const [submitting, setSubmitting] = useState(false)

  const defaultParams = {
    virtualMachine: '',
    port: '',
  }
  const { params, getParamsUpdater, updateParams, setParams } = useParams(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(updateFloatingIp)
  const { update: refreshVm } = useUpdateAction(refreshVirtualMachine)

  const handleSubmit = useCallback(async () => {
    setSubmitting(true)
    const asyncCalls = async () => {
      const body = {
        floatingip: {
          port_id: params.port,
        },
      }
      const { success } = await update({ id: floatingIp.id, body })
      await waitSeconds(2)
      await refreshVm({ id: params.virtualMachine })
      setSubmitting(false)
      if (success) handleClose()
    }
    asyncCalls()
  }, [params, floatingIp])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    onClose()
  }

  return (
    <ModalForm
      title="Assign Public IP"
      onSubmit={handleSubmit}
      onClose={onClose}
      loading={submitting}
      submitting={submitting}
      error={error}
      submitTitle={`Assign Public IP`}
      panel="dialog"
      // maxWidth={1200}
      open
    >
      <div className={classes.fields}>
        <Text variant="body2">
          Select a VM port to assign public IP {floatingIp.floating_ip_address} to.
        </Text>
        <PicklistField
          DropdownComponent={VmPicklist}
          id="virtualMachine"
          label="VM"
          tooltip="The VM to attach the public IP to"
          onChange={(val) => {
            updateParams({
              virtualMachine: val,
              port: '',
            })
          }}
          value={params.virtualMachine}
          required
        />
        <PicklistField
          DropdownComponent={AvailableVmPortsPicklist}
          id="port"
          label="VM Port"
          tooltip="List of available ports on the selected VM"
          onChange={getParamsUpdater('port')}
          value={params.port}
          vmId={params.virtualMachine}
          required
        />
      </div>
    </ModalForm>
  )
}
