import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

const options = [
  { label: 'Default (20 minutes)', value: '20' },
  { label: 'Aggressive (10 minutes)', value: '10' },
  { label: 'Conservative (30 minutes)', value: '30' },
]

interface Props {}

export default function RebalancingFrequencyPicklist({
  name = 'rebalancingFrequency',
  label = 'Rebalancing Frequency',
  compact = false,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  return <AsyncDropdown compact={compact} name={name} label={label} items={options} {...rest} />
}
