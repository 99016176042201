import React from 'react'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { listStacks, deleteStack } from './actions'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { stacksSelector } from './selectors'
import InferActionParams from 'core/actions/InferActionParams'
import { useAppSelector } from 'app/store'
import { routes } from 'core/utils/routes'
import CreateStackModal from './CreateStackModal'
import { durationBetweenDates } from 'utils/misc'
import Text from 'core/elements/Text'
import Tooltip from 'core/elements/tooltip'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import { middleLeft } from 'core/elements/menu/defaults'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import { IStackDetailsPageTabs } from './model'

type ModelDataKey = DataKeys.Stacks
type SelectorModel = ArrayElement<ReturnType<typeof stacksSelector>>
type ActionParams = InferActionParams<typeof listStacks>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {}

const defaultParams: Params = {
  orderBy: 'name',
  orderDirection: 'asc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>('Stacks', listTablePrefs)

const searchTargets = ['name', 'id']

const StatusCell = ({ value, item }) => {
  const classes = useStyles()
  const reason = item?.stack_status_reason || ''
  return (
    <div className={classes.inlineBlock}>
      <Tooltip message={reason} align={middleLeft.align} offset={middleLeft.offset}>
        <Text variant="body2" className={reason ? classes.linkAppearance : ''}>
          {value}
        </Text>
      </Tooltip>
    </div>
  )
}

const stackColumns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'stack_name',
    label: 'Name',
    width: 'medium',
    CellComponent: createGridLinkCell({
      routeToFn: ({ id, stack_name }) =>
        routes.openstack.stackDetails.path({
          id,
          name: stack_name,
          tab: IStackDetailsPageTabs.Overview,
        }),
    }),
  },
  {
    key: 'creation_time',
    label: 'Age',
    formatFn: (value) => durationBetweenDates({ labels: ['d'] })(value),
  },
  {
    key: 'updated_time',
    label: 'Last Updated',
    formatFn: (value) => (value ? durationBetweenDates({ labels: ['d'] })(value) : ''),
  },
  {
    key: 'stack_status',
    label: 'Status',
    CellComponent: StatusCell,
  },
  // {
  //   key: 'vcpus',
  //   label: 'vCPUs',
  // },
  // {
  //   key: 'ram',
  //   label: 'RAM',
  //   render: (ram) => humanReadableSize(ram * 1024 * 1024),
  // },
  // {
  //   key: 'disk',
  //   label: 'Disk',
  //   render: (disk) => humanReadableSize(disk * 1024 * 1024 * 1024),
  // },
]

export default function StacksListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { message, loading, reload } = useListAction(listStacks, {
    params,
  })
  const data = useAppSelector(stacksSelector)

  return (
    <>
      <DocumentMeta title="Stacks" />
      <CreateStackModal addRoute={routes.openstack.createStack} />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.Stacks}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={data}
        columns={stackColumns}
        addUrl={routes.openstack.createStack.path()}
        addText="Create Stack"
        getParamsUpdater={getParamsUpdater}
        deleteAction={deleteStack}
        nameProp="stack_name"
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  inlineBlock: {
    display: 'inline-block',
  },
  linkAppearance: {
    color: theme.palette.primary.main,
  },
}))
