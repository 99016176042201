import React, { useCallback, useMemo } from 'react'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import ModalForm from 'core/elements/modal/ModalForm'
import { Route } from 'core/plugins/route'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import CodeBlock from 'core/components/CodeBlock'
import { useSelector } from 'react-redux'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { RootState } from 'app/store'
import { prop } from 'ramda'
import useScopedPreferences from 'core/session/useScopedPreferences'
import Cookies from 'js-cookie'
import { DOMAIN_NAME } from 'app/constants'

const useStyles = makeStyles<Theme>((theme) => ({
  withUnits: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  unitsDropdown: {
    position: 'relative',
    bottom: 1,
  },
}))

interface Props {
  addRoute: Route
}

export default function ImportImageModal({ addRoute }: Props) {
  const { history } = useReactRouter()
  const classes = useStyles()

  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const {
    userDetails: { name },
    features,
    domain,
    activeRegion,
  } = session

  const isVmware = features?.experimental?.pmov2_du_type === 'vmware'
  const { prefs, updatePrefs } = useScopedPreferences()
  const { currentTenantName, currentRegion } = prefs
  const fqdn = window.location.host
  const currentDomain = domain?.name || Cookies.get(DOMAIN_NAME) || 'default'

  const handleClose = () => {
    history.push(routes.openstack.images.path())
  }

  return (
    <ModalForm
      route={addRoute}
      title={`Steps to Upload an Image to your Image Library`}
      // onSubmit={() => null}
      onClose={handleClose}
      submitting={false}
      // error={error}
      // submitTitle={`Create Flavor`}
    >
      <>
        <FormFieldSection title="Export the OpenStack RC environment variables" step={1}>
          <CodeBlock>
            {`export OS_USERNAME=${name}
export OS_PASSWORD=<your-password>
export OS_AUTH_URL=https://${fqdn}/keystone/v3
export OS_AUTH_TYPE=password
export OS_IDENTITY_API_VERSION=3
export OS_REGION_NAME=${activeRegion || currentRegion}
export OS_USER_DOMAIN_NAME=${currentDomain}
export OS_PROJECT_DOMAIN_NAME=${currentDomain}
export OS_PROJECT_NAME=${currentTenantName}
export OS_INTERFACE=admin`}
          </CodeBlock>
        </FormFieldSection>
        <FormFieldSection title="Upload the image using following command" step={2}>
          {isVmware ? (
            <CodeBlock>
              glance image-create --name &lt;image-name&gt; --file &lt;image-file-path&gt;
              --disk-format vmdk --container-format bare --property vmware_adaptertype="lsiLogic"
              --property vmware_disktype="sparse" --property vmware_ostype="ubuntu64Guest"
              [--property &lt;key=value&gt;]
            </CodeBlock>
          ) : (
            <CodeBlock>
              openstack image create --insecure --container-format bare --disk-format qcow2
              [--public | --private] [--protected | --unprotected] [--property &lt;key=value&gt;]
              --file &lt;image-file-path&gt; &lt;image-name&gt;
            </CodeBlock>
          )}
        </FormFieldSection>
        <FormFieldSection title="Pre-requisites">
          <Text variant="body2">
            OpenStack client needs to be installed for using the above CLI. If not already
            installed, please install it using below command:
          </Text>
          <CodeBlock>apt install python3-openstackclient -y</CodeBlock>
        </FormFieldSection>
      </>
    </ModalForm>
  )
}
