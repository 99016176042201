import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Button from 'core/elements/button'
import HostNetworkConfiguration, { defaultInterfaceConfig } from './HostNetworkConfiguration'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gap: 16,
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

const defaultNetworkConfigValues = {
  name: '',
  networkInterfaces: [defaultInterfaceConfig],
}

export default function HostNetworkConfigurations({ params, updateParams, getParamsUpdater }) {
  const classes = useStyles()

  const addConfiguration = useCallback(() => {
    updateParams({
      hostNetworkConfigs: [...params.hostNetworkConfigs, defaultNetworkConfigValues],
    })
  }, [params.hostNetworkConfigs])

  return (
    <div className={classes.root}>
      {params?.hostNetworkConfigs?.map((config, idx) => (
        <div key={idx}>
          <HostNetworkConfiguration
            params={params}
            updateParams={updateParams}
            getParamsUpdater={getParamsUpdater}
            config={config}
            idx={idx}
          />
        </div>
      ))}
      <div className={classes.buttonRow}>
        <Button type="button" icon="plus" onClick={addConfiguration}>
          Add Host Network Configuration
        </Button>
      </div>
    </div>
  )
}
