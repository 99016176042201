import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { clientActions } from 'core/client/clientReducers'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import useReactRouter from 'use-react-router'
import DocumentMeta from 'core/components/DocumentMeta'
import { routes } from 'core/utils/routes'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listVirtualMachines, listAllVirtualMachines } from '../actions'
import { virtualMachinesSelector, allVirtualMachinesSelector } from '../selectors'
import { IVmDetailsPageTabs } from './model'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Overview from './Overview'
import TasksPage from './TasksPage'

const useStyles = makeStyles<Theme>((theme) => ({
  grid: {
    display: 'grid',
    gap: 32,
  },
  actions: {
    display: 'grid',
    gridAutoColumns: 'auto max-content',
    gridAutoFlow: 'column',
    gap: 16,
  },
}))

const VmDetailsPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id } = match.params
  const { loading } = useListAction(listVirtualMachines)
  const vms = useSelectorWithParams(virtualMachinesSelector, {})
  const { loading: loadingAllVms } = useListAction(listAllVirtualMachines)
  const allVms = useSelectorWithParams(allVirtualMachinesSelector, {})
  const vm = useMemo(() => vms.find((vm) => vm.id === id) || allVms.find((vm) => vm.id === id), [
    id,
    vms,
    allVms,
  ])

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        id: vm?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [vm?.name, id])

  return (
    <>
      <DocumentMeta title="Virtual Machine Details" breadcrumbs />
      <Tabs route={routes.openstack.vmDetails}>
        <Tab label="Overview" value={IVmDetailsPageTabs.Overview}>
          <Overview vm={vm || []} loading={loading || loadingAllVms} />
        </Tab>
        <Tab label="Tasks" value={IVmDetailsPageTabs.Tasks}>
          <TasksPage vm={vm} loading={loading || loadingAllVms} />
        </Tab>
      </Tabs>
    </>
  )
}

export default VmDetailsPage
