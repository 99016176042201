import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import CreateAction from 'core/actions/CreateAction'
import DeleteAction from 'core/actions/DeleteAction'
import store from 'app/store'

const { dispatch } = store
const { nova } = ApiClient.getInstance()

export const serverGroupActions = ActionsSet.make<DataKeys.ServerGroups>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.ServerGroups,
  cacheKey: DataKeys.ServerGroups,
})

export const listServerGroups = serverGroupActions.add(
  new ListAction<DataKeys.ServerGroups>(async () => {
    return nova.getServerGroups()
  }),
)

export const createServerGroup = serverGroupActions.add(
  new CreateAction<DataKeys.ServerGroups, { body }>(async ({ body }) => {
    const created = await nova.createServerGroup(body)
    return created
  }),
)

export const deleteServerGroup = serverGroupActions.add(
  new DeleteAction<DataKeys.ServerGroups, { id: string }>(async ({ id }) => {
    await nova.deleteServerGroup(id)
  }),
)
