import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import {
  createFloatingIp,
  updateFloatingIp,
} from 'openstack/components/networks/floating-ips/actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import useParams from 'core/hooks/useParams'
import PicklistField from 'core/components/validatedForm/DropdownField'
import AvailableVmPortsPicklist from 'openstack/components/networks/floating-ips/AvailableVmPortsPicklist'
import AvailableFloatingIpsPicklist from 'openstack/components/networks/floating-ips/AvailableFloatingIpsPicklist'
import { refreshVirtualMachine } from './actions'
import { waitSeconds } from 'openstack/components/infrastructure/blueprint/helpers'
import RadioFields from 'core/components/validatedForm/radio-fields'
import ExternalNetworkPicklist from 'openstack/components/networks/routers/ExternalNetworkPicklist'

const useStyles = makeStyles<Theme>((theme) => ({
  fields: {
    gap: 16,
    display: 'grid',
    paddingBottom: 32, // To leave some room for the dropdown
  },
}))

const radioOptions = [
  {
    value: 'existing',
    label: `Use Existing Public IP`,
  },
  {
    value: 'new',
    label: `Create New Public IP`,
  },
]

export default function AddFloatingIpDialog({ rows: [vm], onClose }) {
  const classes = useStyles()
  const [submitting, setSubmitting] = useState(false)

  const defaultParams = {
    floatingIp: '',
    port: '',
    method: 'existing',
    externalNetwork: '',
  }
  const { params, getParamsUpdater, updateParams, setParams } = useParams(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(updateFloatingIp)
  const { update: refreshVm, updating: refreshingVm } = useUpdateAction(refreshVirtualMachine)
  const {
    update: createFip,
    updating: creatingFip,
    error: errorCreatingFip,
    reset: resetCreateFip,
  } = useUpdateAction(createFloatingIp)

  const handleSubmit = useCallback(() => {
    setSubmitting(true)
    const asyncCalls = async () => {
      let sharedSuccess = null
      if (params.method === 'existing') {
        const body = {
          floatingip: {
            port_id: params.port,
          },
        }
        const { success } = await update({ id: params.floatingIp, body })
        sharedSuccess = success
      }
      if (params.method === 'new') {
        const body = {
          floatingip: {
            floating_network_id: params.externalNetwork,
            port_id: params.port,
          },
        }
        const { success } = await createFip({ body })
        sharedSuccess = success
      }
      await waitSeconds(3) // wait 3 seconds for VM private IP to be updated first
      await refreshVm({ id: vm.id })
      setSubmitting(false)
      if (sharedSuccess) handleClose()
    }
    asyncCalls()
  }, [params, vm])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    resetCreateFip()
    onClose(true)
  }

  return (
    <ModalForm
      title="Add Public IP"
      onSubmit={handleSubmit}
      onClose={onClose}
      loading={submitting}
      submitting={submitting}
      error={error || errorCreatingFip}
      submitTitle={`Add Public IP`}
      panel="dialog"
      // maxWidth={1200}
      open
    >
      <div className={classes.fields}>
        <Text variant="body2">
          Select a public IP to match with a particular private IP on the selected VM, or create a
          new public IP. Only available public IPs and private IPs are listed.
        </Text>
        <RadioFields
          id="method"
          options={radioOptions}
          value={params.method}
          onChange={getParamsUpdater('method')}
        />
        {params.method === 'new' && (
          <PicklistField
            DropdownComponent={ExternalNetworkPicklist}
            id="externalNetwork"
            label="External Network"
            tooltip="The external network to create a public IP on"
            onChange={getParamsUpdater('externalNetwork')}
            value={params.externalNetwork}
            required
          />
        )}
        {params.method === 'existing' && (
          <PicklistField
            DropdownComponent={AvailableFloatingIpsPicklist}
            id="floatingIp"
            label="Public IP"
            tooltip="List of available public IPs"
            onChange={getParamsUpdater('floatingIp')}
            value={params.floatingIp}
            required
          />
        )}
        <PicklistField
          DropdownComponent={AvailableVmPortsPicklist}
          id="port"
          label="VM Port"
          tooltip="List of available ports on the selected VM"
          onChange={getParamsUpdater('port')}
          value={params.port}
          vmId={vm?.id}
          required
        />
      </div>
    </ModalForm>
  )
}
