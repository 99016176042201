import React from 'react'
import Alert from 'core/components/Alert'
import NumberedStepLabel from 'core/components/validatedForm/NumberedStepLabel'
import CodeBlock from 'core/components/CodeBlock'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import SimpleLink from 'core/components/SimpleLink'
import { routes } from 'core/utils/routes'
import Button from 'core/elements/button/Button'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'

export default function AddVmwareGatewayPage({ props }) {
  const classes = useStyles()
  const downloadUrl =
    'https://ngpc-prod-public-data.s3.us-east-2.amazonaws.com/opencloud/vmware-appliance/latest/platform9-vmware-appliance.ova'
  return (
    <div className={classes.root}>
      <Text variant="h3">Add a VMware vSphere Gateway</Text>
      <div className={classes.section}>
        <Text variant="subtitle2">Adding the Gateway</Text>
        <Alert
          title="In order to add your gateway, follow the steps below:"
          variant="primary"
          message={
            <div className={classes.tutorial}>
              <NumberedStepLabel
                step={1}
                title="Download the vSphere Gateway Appliance for your vCenter environment"
                className={classes.tutorialStep}
              />
              <NumberedStepLabel
                step={2}
                title="Copy the appliance to your vCenter environment"
                className={classes.tutorialStep}
              />
              <NumberedStepLabel
                step={3}
                title="Deploy the appliance within your vCenter environment"
                className={classes.tutorialStep}
              />
            </div>
          }
        />
        <SimpleLink src={downloadUrl}>
          <Button>
            Download Installer
            <FontAwesomeIcon className={classes.downloadIcon} size="sm" solid>
              download
            </FontAwesomeIcon>
          </Button>
        </SimpleLink>
        <Text variant="body2">
          Once the appliance is installed, you will see the Gateway in your list of{' '}
          <SimpleLink src={routes.openstack.hosts.path()}>Cluster Hosts</SimpleLink> waiting to be
          authorized.
        </Text>
      </div>
      <div className={classes.section}>
        <Text variant="subtitle2">Supported VMware Versions and Prerequisites</Text>
        <Text variant="body2">We currently support the following versions of VMware vCenter:</Text>
        <ul>
          <li>vSphere 5.1</li>
          <li>vSphere 5.5</li>
          <li>vSphere 6.0</li>
          <li>vSphere 6.5</li>
          <li>vSphere 6.7</li>
        </ul>
        <Text variant="body2">
          If your VMware version isn't supported, please reach out to support.
        </Text>
      </div>
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  tutorial: {
    display: 'grid',
    gridGap: theme.spacing(1),
  },
  tutorialStep: {
    '& .title': {
      ...theme.typography.body2,
    },
  },
  codeBlocks: {
    paddingLeft: 40,
  },
  root: {
    display: 'grid',
    gap: 24,
  },
  section: {
    display: 'grid',
    gap: 12,
  },
  downloadIcon: {
    marginLeft: theme.spacing(1),
    color: 'white',
  },
}))
