import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
// import DeleteAction from 'core/actions/DeleteAction'
import CustomAction from 'core/actions/CustomAction'
import CreateAction from 'core/actions/CreateAction'
// import UpdateAction from 'core/actions/UpdateAction'
import store from 'app/store'
import { cacheActions } from 'core/caching/cacheReducers'

const { dispatch } = store
const { nova } = ApiClient.getInstance()

export const availabilityZoneActions = ActionsSet.make<DataKeys.AvailabilityZones>({
  uniqueIdentifier: 'zoneName',
  entityName: entityNamesByKey.AvailabilityZones,
  cacheKey: DataKeys.AvailabilityZones,
})

export const listAvailabilityZones = availabilityZoneActions.add(
  new ListAction<DataKeys.AvailabilityZones>(async () => {
    const availabilityZones = await nova.getAvailabilityZones()
    return availabilityZones
  }),
)
