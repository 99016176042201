import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import DeleteAction from 'core/actions/DeleteAction'
// import CustomAction from 'core/actions/CustomAction'
import CreateAction from 'core/actions/CreateAction'
import store from 'app/store'

const { dispatch } = store
const { cinder } = ApiClient.getInstance()

export const volumeSnapshotActions = ActionsSet.make<DataKeys.OpenstackVolumeSnapshots>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.OpenstackVolumeSnapshots,
  cacheKey: DataKeys.OpenstackVolumeSnapshots,
})

export const listVolumeSnapshots = volumeSnapshotActions.add(
  new ListAction<DataKeys.OpenstackVolumeSnapshots>(async () => {
    return cinder.getVolumeSnapshots()
  }),
  // .addDependency(DataKeys.OpenstackVolumeSnapshots),
)

export const createVolumeSnapshot = volumeSnapshotActions.add(
  new CreateAction<DataKeys.OpenstackVolumeSnapshots, { body }>(async ({ body }) => {
    const created = await cinder.snapshotVolume(body)
    const snapshotId = created.id
    const newSnapshot = await cinder.getVolumeSnapshot(snapshotId)
    return newSnapshot
  }),
)

export const deleteVolumeSnapshot = volumeSnapshotActions.add(
  new DeleteAction<DataKeys.OpenstackVolumeSnapshots, { id: string }>(async ({ id }) => {
    await cinder.deleteVolumeSnapshot(id)
  }),
)
