import { makeStyles } from '@material-ui/styles'
import CodeBlock from 'core/components/CodeBlock'
import Button from 'core/elements/button/Button'
import Modal from 'core/elements/modal/Modal'
import Theme from 'core/themes/model'
import React from 'react'

const useStyles = makeStyles<Theme>((theme) => ({
  errorBlock: {
    color: 'red',
    backgroundColor: '#fcebeb',
  },
}))

interface ErrorDialogProps {
  error: string
  onClose: () => void
}

const ErrorModal = ({ error, onClose }: ErrorDialogProps) => {
  const classes = useStyles()
  const [copied, setCopied] = React.useState(false)

  const handleErrorCopy = () => {
    navigator.clipboard.writeText(error)
    setCopied(true)
  }

  return (
    <Modal
      title="Error Details"
      onClose={onClose}
      maxWidth={1000}
      panel="dialog"
      footer={
        <>
          <Button variant="secondary" onClick={handleErrorCopy}>
            {copied ? 'Copied!' : 'Copy'}
          </Button>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </>
      }
      open
    >
      <CodeBlock className={classes.errorBlock}>{error}</CodeBlock>
    </Modal>
  )
}

export default ErrorModal
