import React, { Fragment, useCallback, useMemo } from 'react'
import Text from 'core/elements/Text'
import TextField from 'core/components/validatedForm/TextField'
import { remove, update, uniq } from 'ramda'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Button from 'core/elements/button'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { middleLeft } from 'core/elements/menu/defaults'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { listResmgrHosts } from '../../actions'
import { resmgrHostsSelector } from '../../selectors'
import { customValidator } from 'core/utils/fieldValidators'

const useStyles = makeStyles<Theme>((theme) => ({
  table: {
    display: 'grid',
    gridTemplateColumns: 'auto auto max-content max-content max-content max-content max-content',
    alignItems: 'center',
  },
  withDelete: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  sameLine: {
    display: 'grid',
    gap: 8,
    alignItems: 'center',
    gridTemplateColumns: 'max-content max-content auto',
  },
  error: {
    marginLeft: 16,
    color: theme.components.badge.error.color,
  },
  errorText: {
    color: theme.components.badge.error.color,
  },
  sameLineCell: {
    display: 'grid',
    gap: 8,
    alignItems: 'center',
    gridTemplateColumns: 'max-content auto',
  },
  emptyHeader: {
    borderBottom: `1px solid ${theme.components.table.border}`,
    padding: '4px 0px',
    height: '100%',
  },
  header: {
    borderBottom: `1px solid ${theme.components.table.border}`,
    padding: '4px 8px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  cell: {
    padding: '8px 8px 16px',
  },
  remove: {
    color: theme.components.badge.primary.color,
    cursor: 'pointer',
  },
}))

export const defaultInterfaceConfig = {
  physnet: '',
  management: false,
  tunnel: false,
  hostLiveness: false,
  imageLibrary: false,
  vmConsole: false,
  physicalNetworkLabel: '',
}

const virtualNetworkLabelValidator = customValidator((label, params) => {
  // Technically don't need to test the label variable itself
  const allConfigs = params.hostNetworkConfigs
  const virtualNetworkLabels = []
  for (const config of allConfigs) {
    for (const iface of config.networkInterfaces) {
      if (iface.tunnel) {
        virtualNetworkLabels.push(iface.physicalNetworkLabel)
      }
    }
  }
  const uniqueLabels = uniq(virtualNetworkLabels)
  return uniqueLabels.length === 1
}, `The physical network label for the network interface serving virtual network tunnels must be the same across all Host Network Configurations`)

const getUniqueLabelValidator = (configIndex) =>
  customValidator((label, params) => {
    const config = params.hostNetworkConfigs?.[configIndex]
    const labels = config.networkInterfaces
      .map((iface) => iface.physicalNetworkLabel)
      ?.filter((label) => !!label)
    const uniqueLabels = uniq(labels)
    return labels.length === uniqueLabels.length
  }, 'Labels must be unique per network interface on a Host Network Configuration')

export default function HostNetworkConfiguration({
  params,
  updateParams,
  getParamsUpdater,
  config,
  idx,
}) {
  const classes = useStyles()
  const { message, loading, reload } = useListAction(listResmgrHosts, {
    params,
  })
  const hosts = useSelector(resmgrHostsSelector)
  const usedHostConfigIds = hosts?.map((host) => host?.hostconfig_id)?.filter((id) => !!id)

  const updateField = useCallback(
    (value, prop) => {
      const updatedConfig = {
        ...config,
        [prop]: value,
      }
      const updatedConfigs = update(idx, updatedConfig, params.hostNetworkConfigs)
      updateParams({ hostNetworkConfigs: updatedConfigs })
    },
    [params.hostNetworkConfigs, config, idx],
  )

  const deleteConfig = useCallback(() => {
    const updatedConfigs = remove(idx, 1, params.hostNetworkConfigs)
    updateParams({ hostNetworkConfigs: updatedConfigs })
  }, [params.hostNetworkConfigs, idx])

  const addNetworkInterface = useCallback(() => {
    const updatedConfig = {
      ...config,
      networkInterfaces: [...config.networkInterfaces, defaultInterfaceConfig],
    }
    const updatedConfigs = update(idx, updatedConfig, params.hostNetworkConfigs)
    updateParams({ hostNetworkConfigs: updatedConfigs })
  }, [params.hostNetworkConfigs, config, idx])

  const updateNetworkInterface = useCallback(
    (value, property, interfaceIndex) => {
      const currentInterface = config?.networkInterfaces?.[interfaceIndex]
      const updatedInterface = {
        ...currentInterface,
        [property]: value,
      }
      const updatedConfig = {
        ...config,
        networkInterfaces: update(interfaceIndex, updatedInterface, config?.networkInterfaces),
      }
      const updatedConfigs = update(idx, updatedConfig, params.hostNetworkConfigs)
      updateParams({ hostNetworkConfigs: updatedConfigs })
    },
    [params.hostNetworkConfigs, config, idx],
  )

  const removeNetworkInterface = useCallback(
    (interfaceIndex) => {
      const updatedConfig = {
        ...config,
        networkInterfaces: remove(interfaceIndex, 1, config?.networkInterfaces),
      }
      const updatedConfigs = update(idx, updatedConfig, params.hostNetworkConfigs)
      updateParams({ hostNetworkConfigs: updatedConfigs })
    },
    [params.hostNetworkConfigs, config, idx],
  )

  const trafficAssigned = useCallback(
    (property) => {
      const assigned = config.networkInterfaces?.some((iface) => !!iface[property])
      return assigned
    },
    [config],
  )

  const physicalNetworkLabelsAdded = useMemo(
    () => config?.networkInterfaces.some((iface) => !!iface?.physicalNetworkLabel),
    [config],
  )

  const allTrafficAssigned =
    trafficAssigned('management') &&
    trafficAssigned('vmConsole') &&
    trafficAssigned('imageLibrary') &&
    trafficAssigned('tunnel') &&
    trafficAssigned('hostLiveness')

  return (
    <div>
      <div className={classes.withDelete}>
        <div className={classes.sameLine}>
          <Text variant="caption1">Name this configuration:</Text>
          <TextField
            id={`hostNetworkConfigs.${idx}.name`}
            label=""
            onChange={(value) => updateField(value, 'name')}
            value={config?.name}
            placeholder="Name"
            required
          />
          {!physicalNetworkLabelsAdded && allTrafficAssigned && (
            <div className={classes.sameLine}>
              <FontAwesomeIcon className={classes.error}>circle-exclamation</FontAwesomeIcon>
              <Text variant="body2" className={classes.errorText}>
                Atleast one physical network label name should be provided
              </Text>
            </div>
          )}
          {!allTrafficAssigned && (
            <div className={classes.sameLine}>
              <FontAwesomeIcon className={classes.error}>circle-exclamation</FontAwesomeIcon>
              <Text variant="body2" className={classes.errorText}>
                All system traffic types must be assigned
              </Text>
            </div>
          )}
        </div>
        <Button
          type="button"
          variant="tertiary"
          disabled={usedHostConfigIds.includes(config?.id)}
          info={
            usedHostConfigIds.includes(config?.id)
              ? 'This configuration is in-use and may not be deleted'
              : ''
          }
          onClick={() => deleteConfig()}
        >
          Delete Configuration
        </Button>
      </div>
      <div className={classes.table}>
        <div className={classes.header}>
          <Text variant="caption1">Network Interface</Text>
        </div>
        <div className={classes.header}>
          <Text variant="caption1">Physical Network Label</Text>
        </div>
        <div className={classes.header}>
          <Text variant="caption1">Management</Text>
        </div>
        <div className={classes.header}>
          <Text variant="caption1">VM Console</Text>
        </div>
        <div className={classes.header}>
          <Text variant="caption1">
            Image
            <br />
            Library I/O
          </Text>
        </div>
        <div className={classes.header}>
          <Text variant="caption1">
            Virtual Network
            <br />
            Tunnels
          </Text>
        </div>
        <div className={classes.header}>
          <Text variant="caption1">
            Host Liveness
            <br />
            Checks
          </Text>
        </div>
        {config?.networkInterfaces?.map((iface, ifaceIdx) => (
          <Fragment key={ifaceIdx}>
            <div className={classes.cell}>
              <div className={classes.sameLineCell}>
                <FontAwesomeIcon
                  className={classes.remove}
                  onClick={() => removeNetworkInterface(ifaceIdx)}
                  solid
                >
                  circle-minus
                </FontAwesomeIcon>
                <TextField
                  id={`hostNetworkConfigs.${idx}.networkInterfaces.${ifaceIdx}.physnet`}
                  label=""
                  onChange={(value) => updateNetworkInterface(value, 'physnet', ifaceIdx)}
                  value={iface.physnet}
                  placeholder="Physical Interface Name"
                  required
                />
              </div>
            </div>
            <div className={classes.cell}>
              <TextField
                id={`hostNetworkConfigs.${idx}.networkInterfaces.${ifaceIdx}.physicalNetworkLabel`}
                label=""
                onChange={(value) =>
                  updateNetworkInterface(value, 'physicalNetworkLabel', ifaceIdx)
                }
                value={iface.physicalNetworkLabel}
                placeholder={
                  iface.tunnel && params.segmentationTechnology === 'vlan'
                    ? 'Label (required)'
                    : 'Label (optional)'
                }
                required={iface.tunnel && params.segmentationTechnology === 'vlan'}
                validations={
                  iface.tunnel && params.segmentationTechnology === 'vlan'
                    ? [virtualNetworkLabelValidator, getUniqueLabelValidator(idx)]
                    : [getUniqueLabelValidator(idx)]
                }
              />
            </div>
            <div className={classes.cell}>
              <CheckboxField
                id={`hostNetworkConfigs.${idx}.networkInterfaces.${ifaceIdx}.management`}
                label=""
                value={iface.management}
                onChange={(value) => updateNetworkInterface(value, 'management', ifaceIdx)}
                disabled={trafficAssigned('management') && !iface?.management}
                info={
                  trafficAssigned('management') && !iface?.management
                    ? 'Another interface has already been assigned management traffic'
                    : null
                }
                type="checkbox"
                tooltipAlign={middleLeft.align}
                tooltipOffset={middleLeft.offset}
              />
            </div>
            <div className={classes.cell}>
              <CheckboxField
                id={`hostNetworkConfigs.${idx}.networkInterfaces.${ifaceIdx}.vmConsole`}
                label=""
                value={iface.vmConsole}
                onChange={(value) => updateNetworkInterface(value, 'vmConsole', ifaceIdx)}
                disabled={trafficAssigned('vmConsole') && !iface?.vmConsole}
                info={
                  trafficAssigned('vmConsole') && !iface?.vmConsole
                    ? 'Another interface has already been assigned VM console traffic'
                    : null
                }
                type="checkbox"
                tooltipAlign={middleLeft.align}
                tooltipOffset={middleLeft.offset}
              />
            </div>
            <div className={classes.cell}>
              <CheckboxField
                id={`hostNetworkConfigs.${idx}.networkInterfaces.${ifaceIdx}.imageLibrary`}
                label=""
                value={iface.imageLibrary}
                onChange={(value) => updateNetworkInterface(value, 'imageLibrary', ifaceIdx)}
                disabled={trafficAssigned('imageLibrary') && !iface?.imageLibrary}
                info={
                  trafficAssigned('imageLibrary') && !iface?.imageLibrary
                    ? 'Another interface has already been assigned image library traffic'
                    : null
                }
                type="checkbox"
                tooltipAlign={middleLeft.align}
                tooltipOffset={middleLeft.offset}
              />
            </div>
            <div className={classes.cell}>
              <CheckboxField
                id={`hostNetworkConfigs.${idx}.networkInterfaces.${ifaceIdx}.tunnel`}
                label=""
                value={iface.tunnel}
                onChange={(value) => updateNetworkInterface(value, 'tunnel', ifaceIdx)}
                disabled={trafficAssigned('tunnel') && !iface?.tunnel}
                info={
                  trafficAssigned('tunnel') && !iface?.tunnel
                    ? 'Another interface has already been assigned virtual network tunnel traffic'
                    : null
                }
                type="checkbox"
                tooltipAlign={middleLeft.align}
                tooltipOffset={middleLeft.offset}
              />
            </div>
            <div className={classes.cell}>
              <CheckboxField
                id={`hostNetworkConfigs.${idx}.networkInterfaces.${ifaceIdx}.hostLiveness`}
                label=""
                value={iface.hostLiveness}
                onChange={(value) => updateNetworkInterface(value, 'hostLiveness', ifaceIdx)}
                disabled={trafficAssigned('hostLiveness') && !iface?.hostLiveness}
                info={
                  trafficAssigned('hostLiveness') && !iface?.hostLiveness
                    ? 'Another interface has already been assigned for host liveness checks'
                    : null
                }
                type="checkbox"
                tooltipAlign={middleLeft.align}
                tooltipOffset={middleLeft.offset}
              />
            </div>
          </Fragment>
        ))}
        <div className={classes.cell}>
          <Button type="button" icon="plus" onClick={addNetworkInterface}>
            Add Network Interface
          </Button>
        </div>
      </div>
    </div>
  )
}
