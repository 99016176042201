import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-evenly',
    margin: '24px 0px',
    maxWidth: 1100,
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    maxHeight: 100,
    maxWidth: 100,
  },
}))

export default function StorageTypeLogos() {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        <img src="/ui/images/ceph.png" className={classes.image} />
      </div>
      <div className={classes.logo}>
        <img src="/ui/images/netapp.png" className={classes.image} />
      </div>
      <div className={classes.logo}>
        <img src="/ui/images/purestorage.png" className={classes.image} />
      </div>
      <div className={classes.logo}>
        <img src="/ui/images/huawei.png" className={classes.image} />
      </div>
      <div className={classes.logo}>
        <img src="/ui/images/3par.png" className={classes.image} />
      </div>
    </div>
  )
}
