import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

export enum NetworkTypes {
  FLAT = 'flat',
  VLAN = 'vlan',
  VXLAN = 'vxlan',
}

const options = [
  { label: 'Flat (Untagged)', value: 'flat' },
  { label: 'VLAN (Tagged)', value: 'vlan' },
  { label: 'VXLAN (Overlay)', value: 'vxlan' },
]

const noVxlanOptions = [
  { label: 'Flat (Untagged)', value: 'flat' },
  { label: 'VLAN (Tagged)', value: 'vlan' },
]

interface Props {
  noVxlan?: boolean
}

export default function ProviderNetworkTypePicklist({
  name = 'networkType',
  label = 'Network Type',
  compact = false,
  noVxlan = false,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  return (
    <AsyncDropdown
      compact={compact}
      name={name}
      label={label}
      items={noVxlan ? noVxlanOptions : options}
      {...rest}
    />
  )
}
