import React, { useMemo } from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

interface Props {
  ips: string[]
}

export default function ImageLibraryAccessPicklist({
  name = 'imageLibraryAccess',
  label = 'Image Library Access Endpoint',
  compact = false,
  ips,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const options = useMemo(() => {
    const ipOptions = ips.map((ip) => {
      return { label: ip, value: ip }
    })
    return [{ label: 'Custom', value: 'custom' }, ...ipOptions]
  }, [ips])
  return <AsyncDropdown compact={compact} name={name} label={label} items={options} {...rest} />
}
