import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

const options = [
  { label: 'Ceph', value: 'Ceph' },
  { label: 'LVM', value: 'LVM' },
  { label: 'NFS', value: 'NFS' },
  { label: 'NetApp Data ONTAP', value: 'NetApp' },
  { label: 'Pure Storage ISCSI', value: 'PureStorageISCSI' },
  { label: 'Pure Storage FC', value: 'PureStorageFC' },
  { label: 'Pure Storage NVME', value: 'PureStorageNVME' },
  { label: 'Huawei Dorado ISCSI', value: 'HuaweiISCSI' },
  { label: 'Huawei Dorado FC', value: 'HuaweiFC' },
  { label: 'HPE 3PAR ISCSI', value: 'HPE3PARISCSI' },
  { label: 'HPE 3PAR FC', value: 'HPE3PARFC' },
  { label: 'Custom', value: 'Custom' },
]

interface Props {}

export default function BackendDriverPicklist({
  name = 'driver',
  label = 'Storage Driver',
  compact = false,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  return <AsyncDropdown compact={compact} name={name} label={label} items={options} {...rest} />
}
