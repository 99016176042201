import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import { createVolumeSnapshot } from '../snapshots/actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import TextField from 'core/components/validatedForm/TextField'
import useParams from 'core/hooks/useParams'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import CheckboxField from 'core/components/validatedForm/CheckboxField'

const useStyles = makeStyles<Theme>((theme) => ({
  fields: {
    gap: 16,
    display: 'grid',
  },
}))

export default function SnapshotVolumeDialog({ rows: [volume], onClose }) {
  const { history } = useReactRouter()
  const classes = useStyles()

  const defaultParams = {
    name: '',
    description: '',
    force: false,
  }
  const { params, getParamsUpdater, setParams } = useParams(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(createVolumeSnapshot)

  const handleSubmit = useCallback(async () => {
    if (volume.status === 'in-use' && !params.force) {
      return
    }
    const body = {
      snapshot: {
        name: params.name,
        description: params.description,
        volume_id: volume.id,
        force: params.force,
      },
    }
    const { success } = await update({ body })
    if (success) handleClose()
  }, [params, volume])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(routes.openstack.volumeSnapshots.path())
  }

  return (
    <ModalForm
      title="Snapshot Volume"
      onSubmit={handleSubmit}
      onClose={onClose}
      submitting={updating}
      error={error}
      submitTitle={`Create Snapshot`}
      panel="dialog"
      disableSubmit={volume.status === 'in-use' && !params.force}
      // maxWidth={1200}
      open
    >
      <div className={classes.fields}>
        <Text variant="body2">
          Create a new snapshot using volume <b>{volume.name || volume.id}</b> as its source.
        </Text>
        <TextField
          id="name"
          label="Name"
          onChange={getParamsUpdater('name')}
          value={params.name}
          required
        />
        <TextField
          id="description"
          label="Description"
          onChange={getParamsUpdater('description')}
          value={params.description}
        />
        {volume.status === 'in-use' && (
          <Text variant="body2">
            To snapshot an in-use volume, you must check the force option. Snapshotting this volume
            while it is in use may cause unforeseen consequences.
          </Text>
        )}
        <CheckboxField
          id="force"
          label="Force Snapshot"
          value={params.force}
          onChange={getParamsUpdater('force')}
        />
      </div>
    </ModalForm>
  )
}
