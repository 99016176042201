import React from 'react'
import SelectableCard from 'core/components/SelectableCard'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

const useStyles = makeStyles<Theme>((theme) => ({
  cards: {
    display: 'inline-grid',
    gridAutoFlow: 'column',
    gap: 16,
    gridAutoColumns: 'max-content',
  },
  card: {
    display: 'inline-block',
  },
  cardContainer: {
    display: 'grid',
    gridTemplateColumns: 'max-content auto',
    gap: 16,
    alignItems: 'center',
  },
  activeIcon: {
    color: theme.palette.blue[900],
  },
}))

const rebalancingStrategies = [
  {
    name: 'vm_workload_consolidation',
    label: 'Distribute workloads across hosts',
    icon: 'chart-network',
  },
  {
    name: 'node_resource_consolidation',
    label: 'Consolidate workloads on fewer hosts',
    icon: 'share-nodes',
  },
]

export default function ResourceRebalancingCards({ onClick, value }) {
  const classes = useStyles()

  return (
    <div className={classes.cards}>
      {rebalancingStrategies.map((type) => (
        <SelectableCard
          key={type.name}
          id={type.name}
          onClick={() => onClick(type.name)}
          active={value === type.name}
          className={classes.card}
          showCheckmarkIcon
        >
          <div className={classes.cardContainer}>
            {type.name === 'node_resource_consolidation' ? (
              <div>
                <FontAwesomeIcon
                  className={value === type.name ? classes.activeIcon : ''}
                  size="2xl"
                >
                  arrow-right-long-to-line
                </FontAwesomeIcon>
                <FontAwesomeIcon
                  className={value === type.name ? classes.activeIcon : ''}
                  size="2xl"
                >
                  arrow-left-long-to-line
                </FontAwesomeIcon>
              </div>
            ) : (
              <div>
                <FontAwesomeIcon
                  className={value === type.name ? classes.activeIcon : ''}
                  size="2xl"
                >
                  arrow-left-to-line
                </FontAwesomeIcon>{' '}
                <FontAwesomeIcon
                  className={value === type.name ? classes.activeIcon : ''}
                  size="2xl"
                >
                  arrow-right-to-line
                </FontAwesomeIcon>
              </div>
            )}
            {/* <FontAwesomeIcon className={value === type.name ? classes.activeIcon : ''} size="2xl">
              {type.icon}
            </FontAwesomeIcon> */}
            <Text variant="body2">
              <b>{type.label}</b>
            </Text>
          </div>
        </SelectableCard>
      ))}
    </div>
  )
}
