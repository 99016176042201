import React, { useMemo } from 'react'
import Dropdown from 'core/elements/dropdown'
import withProgress from 'core/components/progress/withProgress'
import useListAction from 'core/hooks/useListAction'
import { listServerGroups } from 'openstack/components/vms/server-groups/actions'
import { useSelector } from 'react-redux'
import { serverGroupsSelector } from 'openstack/components/vms/server-groups/selectors'
import useScopedPreferences from 'core/session/useScopedPreferences'

const AsyncDropdown = withProgress(Dropdown, { inline: true })

export default function ServerGroupsPicklist({
  disabled = false,
  name = 'serverGroups',
  label = 'Server Group',
  ...rest
}) {
  const { prefs } = useScopedPreferences()
  const { currentTenant } = prefs

  const { loading } = useListAction(listServerGroups)
  const data = useSelector(serverGroupsSelector)

  const options = useMemo(() => {
    return [
      { label: 'None', value: null },
      ...data.map((group) => {
        return {
          label: `${group.name} (${group.policies?.[0]})`,
          value: group,
        }
      }),
    ]
  }, [data])

  return (
    <AsyncDropdown
      {...rest}
      name={name}
      label={label}
      compact={false}
      disabled={disabled}
      loading={loading}
      items={options}
    />
  )
}
