import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import CreateAction from 'core/actions/CreateAction'
import DeleteAction from 'core/actions/DeleteAction'
import store from 'app/store'
import { cacheActions } from 'core/caching/cacheReducers'

const { dispatch } = store
const { nova } = ApiClient.getInstance()

export const sshKeyActions = ActionsSet.make<DataKeys.SshKeys>({
  uniqueIdentifier: 'name',
  entityName: entityNamesByKey.SshKeys,
  cacheKey: DataKeys.SshKeys,
})

export const listSshKeys = sshKeyActions.add(
  new ListAction<DataKeys.SshKeys>(async () => {
    const keypairs = await nova.getSshKeys()
    return keypairs.map((keypair) => keypair.keypair)
  }),
)

export const createSshKey = sshKeyActions.add(
  new CreateAction<DataKeys.SshKeys, { body }>(async ({ body }) => {
    const created = await nova.createSshKey(body)
    const keyName = created.name
    const newSshKey = await nova.getSshKey(keyName)
    return newSshKey
  }),
)

export const deleteSshKey = sshKeyActions.add(
  new DeleteAction<DataKeys.SshKeys, { name: string }>(async ({ name }) => {
    await nova.deleteSshKey(name)
  }),
)
