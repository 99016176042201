import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'

export const serverGroupsSelector = createSharedSelector(
  getDataSelector<DataKeys.ServerGroups>(DataKeys.ServerGroups),
  (serverGroups) => {
    return serverGroups.map((serverGroup) => {
      // any model changes go here
      return serverGroup
    })
  },
)

export const serverGroupsByInstanceIdSelector = createSharedSelector(
  getDataSelector<DataKeys.ServerGroups>(DataKeys.ServerGroups),
  (serverGroups) => {
    return serverGroups.reduce((accum, serverGroup) => {
      const forServerGroup = serverGroup?.members?.reduce((acc, member) => {
        return {
          ...acc,
          [member]: serverGroup,
        }
      }, {})
      return {
        ...accum,
        ...forServerGroup,
      }
    }, {})
  },
)
