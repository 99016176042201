import React, { useCallback, useMemo } from 'react'
import Text from 'core/elements/Text'
import { resizeVirtualMachine } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import useParams from 'core/hooks/useParams'
import ListTableField from 'core/components/validatedForm/ListTableField'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import ModalForm from 'core/elements/modal/ModalForm'
import { listFlavors } from '../flavors/actions'
import { flavorsSelector } from '../flavors/selectors'
import { humanReadableSize } from 'openstack/helpers'
import { Labels } from 'k8s/components/common/entity/labels-and-annotations/LabelsOrAnnotations'

const flavorColumns = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'id',
    label: 'ID',
    display: false,
  },
  {
    id: 'vcpus',
    label: 'vCPUs',
  },
  {
    id: 'ram',
    label: 'RAM',
    render: (ram) => humanReadableSize(ram * 1024 * 1024),
  },
  {
    id: 'disk',
    label: 'Disk',
    render: (disk) => humanReadableSize(disk * 1024 * 1024 * 1024),
  },
  {
    id: 'extra_specs',
    label: 'Metadata',
    render: (value) => (
      <Labels
        labels={value}
        separator="="
        containerType="table"
        maxVisible={2}
        showMoreButton={true}
      />
    ),
  },
]

export default function ResizeVirtualMachineDialog({ rows: [vm], onClose }) {
  const classes = useStyles()
  const { error, update: resizeFn, updating } = useUpdateAction(resizeVirtualMachine)
  const title = `Resize VM`
  const defaultParams = {
    flavor: [],
  }
  const { params, getParamsUpdater, updateParams, setParams } = useParams(defaultParams)

  const { loading: loadingFlavors } = useListAction(listFlavors, {
    params: {},
  })
  const flavors = useSelectorWithParams(flavorsSelector, {})
  const eligibleFlavors = useMemo(() => {
    if (!vm?.flavorDetails) {
      return []
    }
    return flavors.filter((flavor) => flavor.disk >= vm?.flavorDetails?.disk)
  }, [flavors, vm])

  const handleSubmit = useCallback(async () => {
    const body = {
      resize: {
        flavorRef: params.flavor[0]?.id,
      },
    }
    const { success } = await resizeFn({ id: vm.id, body })
    if (success) {
      onClose(true)
    }
  }, [params, vm])

  return (
    <ModalForm
      title={title}
      onSubmit={handleSubmit}
      onClose={onClose}
      loading={loadingFlavors}
      submitting={updating}
      error={error}
      submitTitle={title}
      panel="dialog"
      maxWidth={1000}
      disableSubmit={!vm?.flavorDetails}
      open
    >
      <div className={classes.container}>
        <Text variant="body2">
          You are about to resize <b>{vm.name || vm.id}</b>.
        </Text>
        {vm?.flavorDetails ? (
          <>
            <Text variant="body2">
              Select the flavor you would like to resize this VM to. Resizing a VM will result in
              the VM being rebuilt (powered off, then recreated using the source image on the same
              or different host) using the new flavor. If an availability zone was selected for
              creation of the original VM, the new VM will be deployed in the same availability
              zone.
            </Text>
            <Text variant="body2">
              <b>Note:</b> The disk size of the new flavor must be larger than or equal to the
              current flavor. <b>Only eligible flavors are shown below.</b>
            </Text>
            <div className={classes.currentConfigs}>
              <Text variant="body2">
                <b>Current Flavor:</b>
              </Text>
              <Text variant="body2">{`${vm?.flavorDetails?.name} - ${vm?.flavorDetails?.vcpus} vCPUs | ${vm?.flavorDetails?.ram}MiB RAM | ${vm?.flavorDetails?.disk}GiB Disk`}</Text>
              <Text variant="body2">
                <b>Availability Zone:</b>
              </Text>
              <Text variant="body2">{vm?.['OS-EXT-AZ:availability_zone'] || 'N/A'}</Text>
            </div>
            <ListTableField
              id="flavor"
              data={eligibleFlavors}
              loading={loadingFlavors}
              columns={flavorColumns}
              onChange={getParamsUpdater('flavor')}
              value={params.flavor}
              uniqueIdentifier="id"
              searchTargets={['name']}
              emptyText="No eligible flavors found"
              required
            />
          </>
        ) : (
          <Text variant="body2">
            <b>Note:</b> The image used to create this VM is not available anymore. VM resizing is
            unavailable.
          </Text>
        )}
      </div>
    </ModalForm>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: 'grid',
    gap: 24,
  },
  currentConfigs: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    gap: 16,
  },
}))
