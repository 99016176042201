import React, { useMemo } from 'react'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { listNetworks, deleteNetwork } from '../actions'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { networksSelector } from '../selectors'
import { SortingState } from 'core/elements/grid/hooks/useGridSorting'
import InferActionParams from 'core/actions/InferActionParams'
import { useAppSelector } from 'app/store'
import useReactRouter from 'use-react-router'
import { durationBetweenDates } from 'utils/misc'
import Text from 'core/elements/Text'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import { routes } from 'core/utils/routes'
import { INetworkDetailsPageTabs } from '../network-details/model'
import CreateVmwareNetworkModal from './CreateVmwareNetworkModal'
import EditVmwareNetworkModal from './EditVmwareNetworkModal'
import useScopedPreferences from 'core/session/useScopedPreferences'

type ModelDataKey = DataKeys.OpenstackNetworks
type SelectorModel = ArrayElement<ReturnType<typeof networksSelector>>
type ActionParams = InferActionParams<typeof listNetworks>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {}

const defaultParams: Params = {
  orderBy: 'name',
  orderDirection: 'asc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'OpenstackNetworks',
  listTablePrefs,
)

const searchTargets = ['name', 'id']

const SubnetsCellComponent = ({ value }) => {
  return (
    <>
      {value.map((subnet) => (
        <Text variant="body2" key={subnet.id}>
          {subnet.name}: <b>{subnet.cidr}</b>
        </Text>
      ))}
    </>
  )
}

const networkColumns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: createGridLinkCell({
      routeToFn: ({ id }) =>
        routes.openstack.vmwareNetworkDetails.path({ id, tab: INetworkDetailsPageTabs.Overview }),
    }),
  },
  {
    key: 'id',
    label: 'UUID',
    display: false,
  },
  {
    key: 'subnetDetails',
    label: 'Subnets',
    render: (value) => <SubnetsCellComponent value={value} />,
  },
  // {
  //   key: 'tenantId',
  //   label: 'Tenant',
  // },
  {
    key: 'port_security_enabled',
    label: 'Port Security',
    render: (val) => (val ? 'Enabled' : 'Disabled'),
  },
  {
    key: 'admin_state_up',
    label: 'Admin State',
    render: (val) => (val ? 'Up' : 'Down'),
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'availableIps',
    label: 'Available IPs',
  },
]

export default function VirtualNetworksListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { location } = useReactRouter()
  const { prefs } = useScopedPreferences()
  const { currentTenant } = prefs

  const { message, loading, reload } = useListAction(listNetworks, {
    params,
  })
  const data = useAppSelector(networksSelector)

  const networksInTenant = useMemo(() => {
    return data.filter((network) => {
      return network.project_id === currentTenant || network.shared
    })
  }, [data, currentTenant])

  return (
    <>
      <DocumentMeta title="Networks" />
      <CreateVmwareNetworkModal addRoute={routes.openstack.createVmwareNetwork} />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.OpenstackNetworks}
        label="Networks"
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={networksInTenant}
        columns={networkColumns}
        addUrl={routes.openstack.createVmwareNetwork.path()}
        addText="Create Network"
        getParamsUpdater={getParamsUpdater}
        deleteAction={deleteNetwork}
        EditDialogComponent={EditVmwareNetworkModal}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
