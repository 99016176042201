import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { clientActions } from 'core/client/clientReducers'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import useReactRouter from 'use-react-router'
import DocumentMeta from 'core/components/DocumentMeta'
import { routes } from 'core/utils/routes'
import { getStack } from './actions'
import { IStackDetailsPageTabs } from './model'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Overview from './Overview'
import StackResourcesPage from './StackResourcesPage'

const useStyles = makeStyles<Theme>((theme) => ({
  grid: {
    display: 'grid',
    gap: 32,
  },
  actions: {
    display: 'grid',
    gridAutoColumns: 'auto max-content',
    gridAutoFlow: 'column',
    gap: 16,
  },
}))

const StackDetailsPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id, name } = match.params
  const [loading, setLoading] = useState(true)
  const [stack, setStack] = useState<any>({})

  useEffect(() => {
    const makeCalls = async () => {
      const detailedStack = await getStack({ id, name })
      setStack(detailedStack)
      setLoading(false)
    }
    makeCalls()
  }, [id, name])

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        id: stack?.stack_name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [stack?.stack_name, id])

  return (
    <>
      <DocumentMeta title="Stack Details" breadcrumbs />
      <Tabs route={routes.openstack.stackDetails}>
        <Tab label="Overview" value={IStackDetailsPageTabs.Overview}>
          <Overview stack={stack} loading={loading} />
        </Tab>
        <Tab label="Resources" value={IStackDetailsPageTabs.Resources}>
          <StackResourcesPage stack={stack} loading={loading} />
        </Tab>
      </Tabs>
    </>
  )
}

export default StackDetailsPage
