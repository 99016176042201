import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import TextField from 'core/components/validatedForm/TextField'
import { remove, update } from 'ramda'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Text from 'core/elements/Text'
import PicklistField from 'core/components/validatedForm/DropdownField'
import RuleTypePicklist from './RuleTypePicklist'
import RuleDestinationPicklist from './RuleDestinationPicklist'
import SecurityGroupPicklist from './SecurityGroupPicklist'
import { defaultValuesForRuleType } from './helpers'
import { FieldValidator } from 'core/utils/fieldValidators'

const useStyles = makeStyles<Theme>((theme) => ({
  fields: {
    display: 'grid',
    gap: 16,
  },
  ruleFields: {
    display: 'grid',
    gap: 16,
    marginTop: 16,
    paddingLeft: 24,
  },
  configurations: {
    display: 'grid',
    gap: 16,
  },
  divider: {
    height: 1,
    background: theme.components.card.border,
    border: 0,
    width: '100%',
  },
  operationLabel: {
    width: 'fit-content',
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    gap: 8,
  },
  icon: {
    color: theme.components.badge.primary.color,
  },
}))

const defaultValues = {
  type: 'customTcp',
  destination: 'cidr',
}

interface Props {
  params: any
  getParamsUpdater: any
  updateParams: any
  direction: 'inbound' | 'outbound'
}

const portRangeValidator = new FieldValidator((value: string) => {
  const [minRange, maxRange] = value.split('-')
  const portMinRange = Number(minRange)
  const portMaxRange = Number(maxRange)

  if (!portMinRange || isNaN(portMinRange) || portMinRange > 65535 || portMinRange <= 0) {
    return false
  }

  if (
    maxRange &&
    (portMinRange > portMaxRange ||
      portMaxRange > 65535 ||
      portMaxRange <= 0 ||
      isNaN(portMaxRange))
  ) {
    return false
  }

  return true
}, 'Port range is invalid')

export default function SecurityGroupRulesParamFields({
  params,
  getParamsUpdater,
  updateParams,
  direction,
}: Props) {
  const classes = useStyles()
  const paramsProperty = {
    inbound: 'inboundSecurityGroupRules',
    outbound: 'outboundSecurityGroupRules',
  }[direction]

  const addNewRule = useCallback(() => {
    updateParams({
      [paramsProperty]: [...params[paramsProperty], defaultValues],
    })
  }, [defaultValues, params])

  const removeValue = useCallback(
    (index) => {
      updateParams({
        [paramsProperty]: remove(index, 1, params[paramsProperty]),
      })
    },
    [params[paramsProperty], updateParams],
  )

  const updateRule = useCallback(
    ({ idx, rule, values }) => {
      const updatedRule = {
        ...rule,
        ...values,
      }
      const updatedRules = update(idx, updatedRule, params[paramsProperty])
      updateParams({
        [paramsProperty]: updatedRules,
      })
    },
    [params[paramsProperty], updateParams],
  )

  return (
    <div className={classes.fields}>
      {params[paramsProperty].map((rule, idx) => (
        <div key={idx}>
          <div className={classes.operationLabel} onClick={() => removeValue(idx)}>
            <FontAwesomeIcon className={classes.icon} size="lg" solid>
              circle-minus
            </FontAwesomeIcon>
            <Text variant="subtitle2">
              {direction === 'outbound' ? 'Outbound' : 'Inbound'} Rule {idx + 1}
            </Text>
          </div>
          <div className={classes.ruleFields}>
            <PicklistField
              DropdownComponent={RuleTypePicklist}
              id="type"
              label="Type"
              tooltip="Type of traffic this rule will influence"
              onChange={(value) => {
                const defaultRuleValues = defaultValuesForRuleType[value]
                updateRule({ idx, rule, values: { type: value, ...defaultRuleValues } })
              }}
              value={rule.type}
              required
            />
            {['customTcp', 'customUdp', 'customIcmp'].includes(rule.type) && (
              <>
                {['customTcp', 'customUdp'].includes(rule.type) && (
                  <TextField
                    id={`${paramsProperty}.${idx}.portRange`}
                    label="Port Range"
                    onChange={(value) => {
                      updateRule({ idx, rule, values: { portRange: value } })
                    }}
                    value={rule.portRange}
                    placeholder="e.g. 80 or 1 - 65535"
                    required
                    validations={[portRangeValidator]}
                  />
                )}
                {rule.type === 'customIcmp' && (
                  <>
                    <TextField
                      id={`${paramsProperty}.${idx}.icmpType`}
                      label="ICMP Type"
                      onChange={(value) => {
                        updateRule({ idx, rule, values: { icmpType: value } })
                      }}
                      value={rule.portRange}
                      placeholder="e.g. 3"
                      required
                    />
                    <TextField
                      id={`${paramsProperty}.${idx}.icmpCode`}
                      label="ICMP Code"
                      onChange={(value) => {
                        updateRule({ idx, rule, values: { icmpCode: value } })
                      }}
                      value={rule.portRange}
                      placeholder="e.g. 0"
                      required
                    />
                  </>
                )}
                <PicklistField
                  DropdownComponent={RuleDestinationPicklist}
                  id="destination"
                  label={direction === 'outbound' ? 'Destination Type' : 'Source Type'}
                  tooltip="Allow access from IP addresses based on CIDR or from instances assigned with the specified security group."
                  onChange={(value) => {
                    updateRule({ idx, rule, values: { destination: value } })
                  }}
                  value={rule.destination}
                  required
                />
                {rule.destination === 'cidr' && (
                  <TextField
                    id={`${paramsProperty}.${idx}.cidr`}
                    label="CIDR"
                    onChange={(value) => {
                      updateRule({ idx, rule, values: { cidr: value } })
                    }}
                    value={rule.cidr}
                    info="Enter CIDR for access. Define CIDR of 0.0.0.0/0 to allow traffic for all addresses."
                    placeholder="e.g. 192.0.2.0/24"
                    required
                  />
                )}
                {rule.destination === 'securityGroup' && (
                  <PicklistField
                    DropdownComponent={SecurityGroupPicklist}
                    id={`${paramsProperty}.${idx}.securityGroup`}
                    label="Security Group"
                    tooltip="Select a security group for access"
                    onChange={(value) =>
                      updateRule({ idx, rule, values: { securityGroup: value } })
                    }
                    value={rule.securityGroup}
                    required
                  />
                )}
              </>
            )}
          </div>
        </div>
      ))}
      {!!params[paramsProperty]?.length && <hr className={classes.divider} />}
      <div className={classes.operationLabel} onClick={addNewRule}>
        <FontAwesomeIcon className={classes.icon} size="lg" solid>
          circle-plus
        </FontAwesomeIcon>
        <Text variant="body2">Add Rule</Text>
      </div>
    </div>
  )
}
