import React, { useEffect, useMemo, useState } from 'react'
import Dropdown from 'core/elements/dropdown'
import withProgress from 'core/components/progress/withProgress'
import { getHostNetworkConfigs } from '../actions'

const AsyncDropdown = withProgress(Dropdown, { inline: true })

export default function HostNetworkConfigPicklist({
  disabled = false,
  name = 'hostNetworkConfig',
  label = 'Host Network Config',
  ...rest
}) {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  // TODO: Will need to filter down by current blueprint in the future I assume
  useEffect(() => {
    const makeCalls = async () => {
      const configs: any = await getHostNetworkConfigs()
      setData(configs)
      setLoading(false)
    }
    makeCalls()
  }, [])

  const options = useMemo(() => {
    return data.map((config) => {
      return {
        label: config.name || config.id,
        value: config.id,
      }
    })
  }, [data])

  return (
    <AsyncDropdown
      {...rest}
      name={name}
      label={label}
      compact={false}
      disabled={disabled}
      loading={loading}
      items={options}
    />
  )
}
