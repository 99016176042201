import React, { useCallback } from 'react'
import Text from 'core/elements/Text'
import { updateVirtualMachine } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import useParams from 'core/hooks/useParams'
import TextField from 'core/components/validatedForm/TextField'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'

export default function updateVirtualMachineDialog({ rows: [vm], onClose }) {
  const classes = useStyles()
  const defaultParams = {
    name: `${vm?.name}`,
  }
  const { params, getParamsUpdater } = useParams(defaultParams)

  const { update, updating, error } = useUpdateAction(updateVirtualMachine)
  const submitForm = useCallback(async () => {
    const body = {
      server: {
        name: params.name,
      },
    }
    const { success } = await update({
      id: vm.id,
      body,
    })
    if (success) onClose(true)
  }, [vm, params])

  return (
    <ModalForm
      open
      panel="dialog"
      title={`Rename VM`}
      onSubmit={submitForm}
      onClose={onClose}
      submitting={updating}
      error={error}
      submitTitle={`Rename VM`}
    >
      <div className={classes.body}>
        <Text variant="body2">
          Rename VM instance <b>{vm?.name || vm?.id}</b>
        </Text>
        <TextField
          id="name"
          label="Name"
          onChange={getParamsUpdater('name')}
          value={params.name}
          info="Choose a name new name for this VM"
          required
        />
      </div>
    </ModalForm>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  body: {
    display: 'grid',
    gap: 12,
  },
}))
