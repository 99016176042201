import React, { useState } from 'react'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import { routes } from 'core/utils/routes'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { GridBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import { listVolumes, listAllVolumes, deleteVolume } from './actions'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { volumesSelector, allVolumesSelector } from './selectors'
import InferActionParams from 'core/actions/InferActionParams'
import { useAppSelector } from 'app/store'
import { durationBetweenDates } from 'utils/misc'
import CreateVolumeModal from './CreateVolumeModal'
import SnapshotVolumeDialog from './SnapshotVolumeDialog'
import AttachVolumeDialog from './AttachVolumeDialog'
import DetachVolumeDialog from './DetachVolumeDialog'
import UploadVolumeAsImageDialog from './UploadVolumeAsImageDialog'
import { humanReadableSize } from 'openstack/helpers'
import Text from 'core/elements/Text'
import ToggleSwitch from 'core/elements/ToggleSwitch'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listTenants } from 'account/components/userManagement/tenants/new-actions'
import { tenantsSelector } from 'account/components/userManagement/tenants/selectors'
import { isAdmin } from 'app/plugins/infrastructure/components/common/helpers'
import ExtendVolumeDialog from './ExtendVolumeDialog'
import DeleteVolumeDialog from './DeleteVolumeDialog'

type ModelDataKey = DataKeys.OpenstackVolumes
type SelectorModel = ArrayElement<ReturnType<typeof volumesSelector>>
type ActionParams = InferActionParams<typeof listVolumes>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {}

const defaultParams: Params = {
  orderBy: 'created_at',
  orderDirection: 'desc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'OpenstackVolumes',
  listTablePrefs,
)

const searchTargets = ['name', 'id']

const TenantCellComponent = ({ value, item }) => {
  useListAction(listTenants)
  const tenants = useSelectorWithParams(tenantsSelector, {})
  const tenant = tenants.find((ten) => ten.id === value)
  return <Text variant="body2">{tenant?.name || tenant?.id || value}</Text>
}

const volumeColumns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
  },
  {
    key: 'id',
    label: 'UUID',
    display: false,
  },
  {
    key: 'os-vol-tenant-attr:tenant_id',
    label: 'Tenant',
    CellComponent: TenantCellComponent,
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'volume_type',
    label: 'Type',
  },
  {
    key: 'description',
    label: 'Description',
  },
  {
    key: 'attachedVm',
    label: 'VM',
    render: (vm) => vm?.name,
  },
  {
    key: 'device',
    label: 'Device',
  },
  {
    key: 'size',
    label: 'Capacity',
    render: (size) => humanReadableSize(size * 1024 * 1024 * 1024),
  },
  {
    key: 'bootable',
    label: 'Bootable',
  },
  {
    key: 'created_at',
    label: 'Age',
    formatFn: (value) => durationBetweenDates({ labels: ['d'] })(value),
  },
]

export default function VolumesListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { message, loading, reload } = useListAction(listVolumes, {
    params,
  })
  const data = useAppSelector(volumesSelector)
  const { message: allMessage, loading: allLoading, reload: allReload } = useListAction(
    listAllVolumes,
    {
      params,
    },
  )
  const allVolumes = useAppSelector(allVolumesSelector)

  const [selectedVolume, setSelectedVolume] = useState(null)
  const [showSnapshotVolumeDialog, setShowSnapshotVolumeDialog] = useState(false)
  const [showUploadVolumeDialog, setShowUploadVolumeDialog] = useState(false)
  const [showAttachVolumeDialog, setShowAttachVolumeDialog] = useState(false)
  const [showDetachVolumeDialog, setShowDetachVolumeDialog] = useState(false)
  const [showExtendVolumeDialog, setShowExtendVolumeDialog] = useState(false)
  const [showDeleteVolumeDialog, setShowDeleteVolumeDialog] = useState(false)
  const [allTenants, setAllTenants] = useState(false)

  const batchActions: GridBatchActionSpec<SelectorModel>[] = [
    {
      // cond: () => isDeccoEnv(),
      icon: 'camera',
      label: 'Snapshot',
      handleAction: (volumes) => {
        setSelectedVolume(volumes[0] ?? null)
        setShowSnapshotVolumeDialog(true)
      },
    },
    {
      // cond: () => isDeccoEnv(),
      icon: 'upload',
      label: 'Upload as Image',
      handleAction: (volumes) => {
        setSelectedVolume(volumes[0] ?? null)
        setShowUploadVolumeDialog(true)
      },
    },
    {
      cond: (volumes) => volumes[0]?.status === 'available',
      icon: 'plus-square',
      label: 'Attach',
      handleAction: (volumes) => {
        setSelectedVolume(volumes[0] ?? null)
        setShowAttachVolumeDialog(true)
      },
    },
    {
      cond: (volumes) => volumes[0]?.status === 'in-use',
      icon: 'minus-square',
      label: 'Detach',
      handleAction: (volumes) => {
        setSelectedVolume(volumes[0] ?? null)
        setShowDetachVolumeDialog(true)
      },
    },

    {
      cond: (volumes) => volumes[0]?.status === 'available',
      icon: 'minus-square',
      label: 'Extend Volume',
      disabledTooltip: 'You can only extend a volume when it is in the available status',
      handleAction: (volumes) => {
        setSelectedVolume(volumes[0] ?? null)
        setShowExtendVolumeDialog(true)
      },
    },
  ]

  return (
    <>
      <DocumentMeta title="Volumes" />
      <CreateVolumeModal addRoute={routes.openstack.createVolume} />
      {showSnapshotVolumeDialog && (
        <SnapshotVolumeDialog
          rows={[selectedVolume]}
          onClose={() => setShowSnapshotVolumeDialog(false)}
        />
      )}
      {showUploadVolumeDialog && (
        <UploadVolumeAsImageDialog
          rows={[selectedVolume]}
          onClose={() => setShowUploadVolumeDialog(false)}
        />
      )}
      {showAttachVolumeDialog && (
        <AttachVolumeDialog
          rows={[selectedVolume]}
          onClose={() => setShowAttachVolumeDialog(false)}
        />
      )}
      {showDetachVolumeDialog && (
        <DetachVolumeDialog
          rows={[selectedVolume]}
          onClose={() => setShowDetachVolumeDialog(false)}
        />
      )}
      {showExtendVolumeDialog && (
        <ExtendVolumeDialog
          rows={[selectedVolume]}
          onClose={() => setShowExtendVolumeDialog(false)}
        />
      )}
      {showDeleteVolumeDialog && (
        <DeleteVolumeDialog
          rows={[selectedVolume]}
          onClose={() => setShowDeleteVolumeDialog(false)}
        />
      )}
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.OpenstackVolumes}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={(!allTenants && loading) || (allTenants && allLoading)}
        loadingMessage={(!allTenants && message) || (allTenants && allMessage)}
        onRefresh={allTenants ? allReload : reload}
        data={allTenants ? allVolumes : data}
        columns={volumeColumns}
        addUrl={routes.openstack.createVolume.path()}
        addText="Create New Volume"
        getParamsUpdater={getParamsUpdater}
        deleteAction={deleteVolume}
        batchActions={batchActions}
        DeleteDialogComponent={DeleteVolumeDialog}
        extraHeaderContent={
          isAdmin() ? (
            <ToggleSwitch
              active={allTenants}
              onClick={() => setAllTenants(!allTenants)}
              label="View Volumes in All Tenants"
            />
          ) : null
        }
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
