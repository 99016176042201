import React, { useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { getFieldsForCard } from 'core/components/InfoPanel'
import InfoCard from 'k8s/components/common/entity/info-card'
import Progress from 'core/components/progress/Progress'
import { durationBetweenDates } from 'utils/misc'
import LabelsAndAnnotationsSection from 'k8s/components/common/entity/labels-and-annotations/LabelsAndAnnotationsSection'
import Card from 'core/elements/card'
import Grid from 'core/elements/grid'
import Text from 'core/elements/Text'
import ErrorModal from '../ErrorModal'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { listPorts } from 'openstack/components/networks/actions'
import { portsSelector } from 'openstack/components/networks/selectors'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
}))

const metadataFields = ({ setShowErrorDialog }) => [
  {
    id: 'name',
    title: 'Name',
  },
  {
    id: 'id',
    title: 'ID',
  },
  {
    id: 'OS-EXT-SRV-ATTR:hypervisor_hostname',
    title: 'Host',
  },
  {
    id: 'status',
    title: 'Status',
  },
  {
    id: 'OS-EXT-STS:vm_state',
    title: 'VM State',
    render: (value) =>
      value.toLowerCase() === 'error' ? (
        <Text
          variant="caption1"
          onClick={() => {
            setShowErrorDialog(true)
          }}
          style={{ cursor: 'pointer' }}
        >
          {value}
        </Text>
      ) : (
        value
      ),
  },
  {
    id: 'OS-EXT-STS:task_state',
    title: 'Task State',
  },
  {
    id: 'created',
    title: 'Age',
    render: (value) => durationBetweenDates({ labels: ['d'] })(value),
  },
  {
    id: 'imageName',
    title: 'Image',
  },
  {
    id: 'key_name',
    title: 'SSH Key',
  },
  {
    id: 'security_groups',
    title: 'Security Groups',
    render: (groups) => groups?.map((group) => group?.name).join(', '),
  },
  {
    id: 'serverGroup',
    title: 'Server Group',
    render: (group) => (group ? `${group?.name} (${group?.policies?.[0]})` : null),
  },
]

const specsFields = [
  {
    id: 'name',
    title: 'Flavor',
  },
  {
    id: 'ram',
    title: 'RAM',
    render: (val) => `${val} MiB`,
  },
  {
    id: 'vcpus',
    title: 'vCPUs',
  },
  {
    id: 'disk',
    title: 'Disk',
    render: (val) => `${val} GiB`,
  },
]

const volumeTableColumns = [
  { key: 'name', label: 'Name' },
  { key: 'description', label: 'Description' },
  { key: 'attachments', label: 'Device', render: (val) => val?.[0]?.device },
]

export default function Overview({ vm, loading }) {
  const classes = useStyles({})
  const [showErrorDialog, setShowErrorDialog] = useState(false)

  const { message, loading: loadingPorts, reload: reloadPorts } = useListAction(listPorts)
  const ports = useSelector(portsSelector)
  const vmPorts = useMemo(() => {
    return ports?.filter((port) => port.device_id === vm?.id)
  }, [ports, vm])

  const addressTableColumns = useMemo(
    () => [
      {
        key: 'networkName',
        label: 'Network',
      },
      { key: 'addr', label: 'IP Address' },
      { key: 'OS-EXT-IPS-MAC:mac_addr', label: 'MAC Address' },
      { key: 'version', label: 'Version' },
      {
        key: 'OS-EXT-IPS:type',
        label: 'Type',
      },
      {
        key: 'index',
        label: 'Security Groups',
        render: (val, item) => {
          // not sure if there could be duplicate mac addresses or not
          const port = vmPorts.find(
            (vmPort) => item['OS-EXT-IPS-MAC:mac_addr'] === vmPort.mac_address,
          )
          const securityGroupsString = port?.securityGroups?.map(
            (group) => group?.name || group?.id,
          )
          return securityGroupsString || 'N/A'
        },
      },
    ],
    [vmPorts],
  )

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields({ setShowErrorDialog }), vm)
  }, [vm])
  const specs = useMemo(() => {
    return getFieldsForCard(specsFields, vm.flavorDetails)
  }, [vm])

  const addresses = useMemo(() => {
    const networkNames = Object.keys(vm?.addresses)
    return networkNames.reduce((accum, name) => {
      const networkAddresses = vm.addresses[name]
      const withNetworkName = networkAddresses.map((address, idx) => {
        return {
          ...address,
          networkName: name,
          index: idx,
        }
      })
      return [...accum, ...withNetworkName]
    }, [])
  }, [vm])

  const volumes = useMemo(() => {
    const volumeKeys = Object.keys(vm?.volumeDetails)
    return volumeKeys.reduce((accum, id) => {
      return [...accum, vm?.volumeDetails?.[id]]
    }, [])
  }, [vm])

  return (
    <Progress loading={loading}>
      {showErrorDialog && (
        <ErrorModal error={vm?.fault?.message} onClose={() => setShowErrorDialog(false)} />
      )}
      <div className={classes.overview}>
        <div className={classes.column}>
          <InfoCard
            items={metadata}
            title="Properties"
            footer={
              <LabelsAndAnnotationsSection
                entity={vm}
                resourceType="vm"
                showLabels={false}
                showAnnotations={false}
                showMetadata
              />
            }
          />
          <InfoCard items={specs} title="Specs" />
        </div>
        <div className={classes.column}>
          <Card title="Networks" withCustomBody>
            <Grid
              uniqueIdentifier="addr"
              data={addresses}
              columns={addressTableColumns}
              loading={loading}
              compact
              disableToolbar
            />
          </Card>
          <Card title="Volumes" withCustomBody>
            <Grid
              uniqueIdentifier="id"
              data={volumes}
              columns={volumeTableColumns}
              loading={loading}
              compact
              disableToolbar
            />
          </Card>
        </div>
      </div>
    </Progress>
  )
}
