import React, { useEffect, useMemo, useState } from 'react'
import Dropdown from 'core/elements/dropdown'
import withProgress from 'core/components/progress/withProgress'
import { getResmgrServiceConfig } from 'openstack/components/infrastructure/actions'
import { uniq } from 'ramda'

const AsyncDropdown = withProgress(Dropdown, { inline: true })

export default function PhysicalNetworkLabelPicklist({
  disabled = false,
  name = 'networkLabel',
  label = 'Network Label',
  ...rest
}) {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  useEffect(() => {
    const getLabels = async () => {
      const neutronConfig = await getResmgrServiceConfig('neutron-server')
      const vlanRangeValues = neutronConfig.network_vlan_ranges.replace(/\s/g, '').split(',')
      const labelValues = vlanRangeValues.map((range) => {
        const rangeValues = range.split(':')
        return rangeValues[0]
      })
      setData(uniq(labelValues))
      setLoading(false)
    }
    getLabels()
  }, [])

  const options = useMemo(() => {
    return data.map((label) => {
      return {
        label: label,
        value: label,
      }
    })
  }, [data])

  return (
    <AsyncDropdown
      {...rest}
      name={name}
      label={label}
      compact={false}
      disabled={disabled}
      loading={loading}
      items={options}
    />
  )
}
