import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

const options = [
  { label: 'Affinity', value: 'affinity' },
  { label: 'Anti-Affinity', value: 'anti-affinity' },
]

interface Props {}

export default function PolicyPicklist({
  name = 'policy',
  label = 'Policy',
  compact = false,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  return <AsyncDropdown compact={compact} name={name} label={label} items={options} {...rest} />
}
