import React, { useMemo } from 'react'
import Dropdown from 'core/elements/dropdown'
import withProgress from 'core/components/progress/withProgress'
import useListAction from 'core/hooks/useListAction'
import { listNetworks } from 'openstack/components/networks/actions'
import { useSelector } from 'react-redux'
import { networksByIdSelector, subnetsSelector } from 'openstack/components/networks/selectors'

const AsyncDropdown = withProgress(Dropdown, { inline: true })

export default function SubnetPicklist({
  disabled = false,
  name = 'subnet',
  label = 'Subnet',
  networkId = null,
  includeNoneOption = false,
  ...rest
}) {
  const { loading } = useListAction(listNetworks)
  const networksById = useSelector(networksByIdSelector)
  const subnets = useSelector(subnetsSelector)
  const networkSubnets = useMemo(() => {
    if (!networkId) {
      return subnets
    }
    return subnets.filter((subnet) => subnet.network_id === networkId)
  }, [networkId, subnets])

  const options = useMemo(() => {
    // will need to filter out taken private IPs
    const subnetOptions = networkSubnets.map((subnet) => {
      const subnetWithNetwork = {
        ...subnet,
        networkObj: networksById[subnet.network_id],
      }
      return {
        label: `${subnetWithNetwork?.networkObj?.name || subnetWithNetwork?.networkObj?.id}: ${
          subnetWithNetwork?.cidr
        } (${subnetWithNetwork?.name || subnetWithNetwork?.id})`,
        value: subnet,
      }
    })
    return includeNoneOption ? [{ label: 'None', value: null }, ...subnetOptions] : subnetOptions
  }, [networksById, networkSubnets])

  return (
    <AsyncDropdown
      {...rest}
      name={name}
      label={label}
      compact={false}
      disabled={disabled}
      loading={loading}
      items={options}
    />
  )
}
