import React, { useCallback, useMemo, useState } from 'react'
import useParams from 'core/hooks/useParams'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createSecurityGroup, createSecurityGroupRules, deleteSecurityGroupRules } from './actions'
import { listVirtualMachines } from 'openstack/components/vms/actions'
import ModalForm from 'core/elements/modal/ModalForm'
import { Route } from 'core/plugins/route'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import ExternalNetworkPicklist from '../routers/ExternalNetworkPicklist'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import PicklistField from 'core/components/validatedForm/DropdownField'
import useListAction from 'core/hooks/useListAction'
import TextField from 'core/components/validatedForm/TextField'
import SecurityGroupRulesParamFields from './SecurityGroupRulesParamFields'
import { getRuleJson } from './helpers'
import Text from 'core/elements/Text'
import { customValidator } from 'core/utils/fieldValidators'

const useStyles = makeStyles<Theme>((theme) => ({}))

interface Props {
  addRoute: Route
}

const nameValidator = customValidator((value) => {
  if (!value) {
    return true
  }
  return value !== 'default'
}, 'You may not create a securiy group named default. Please use another name.')

export default function CreateSecurityGroupModal({ addRoute }: Props) {
  const { history } = useReactRouter()
  const classes = useStyles()
  const defaultParams = {
    name: '',
    description: '',
    outboundSecurityGroupRules: [
      {
        cidr: '0.0.0.0/0',
        destination: 'cidr',
        portRange: 'all',
        securityGroup: null,
        type: 'allTraffic',
      },
    ],
    inboundSecurityGroupRules: [],
  }
  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  const {
    update: createSecurityGroupFn,
    updating: creatingSecurityGroup,
    error: creatingSecurityGroupError,
    reset,
  } = useUpdateAction(createSecurityGroup)
  const {
    update: rulesUpdate,
    updating: updatingRules,
    error: rulesError,
    reset: rulesReset,
  } = useUpdateAction(createSecurityGroupRules)
  const {
    update: deleteRules,
    updating: deletingRules,
    error: deleteRulesError,
    reset: deleteRulesReset,
  } = useUpdateAction(deleteSecurityGroupRules)

  const submitForm = useCallback(async () => {
    const body = {
      security_group: {
        name: params.name,
        description: params.description,
      },
    }
    const { success, response } = await createSecurityGroupFn({ body })
    if (success) {
      const securityGroupId = response?.id
      // Todo: If we allow editing of default security group rules, then
      // this will need to be updated too
      // Filter out outbound allTraffic, is considered a duplicate rule, and
      // will cause any other rules to not be applied
      const removeDefaultRules = !params.outboundSecurityGroupRules.some(
        (rule) => rule.type === 'allTraffic',
      )
      if (removeDefaultRules) {
        const rulesToRemove = response.security_group_rules
        const ruleIds = rulesToRemove.map((rule) => rule.id)
        await deleteRules({ id: response.id, ruleIds })
      } else {
        // only remove the ipv6 rule
        const ruleToRemove = response.security_group_rules.find(
          (rule) => rule?.ethertype === 'IPv6',
        )
        if (ruleToRemove) {
          await deleteRules({ id: response.id, ruleIds: [ruleToRemove.id] })
        }
      }
      const outboundRuleJsons = params.outboundSecurityGroupRules
        .filter((rule) => rule.type !== 'allTraffic')
        .map((rule) => getRuleJson({ rule, direction: 'egress', securityGroupId }))
      const inboundRuleJsons = params.inboundSecurityGroupRules.map((rule) =>
        getRuleJson({ rule, direction: 'ingress', securityGroupId }),
      )
      const rulesBody = {
        security_group_rules: [...outboundRuleJsons, ...inboundRuleJsons],
      }
      await rulesUpdate({ id: securityGroupId, body: rulesBody })
      handleClose()
    }
  }, [params])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    rulesReset()
    deleteRulesReset()
    history.push(routes.openstack.securityGroups.path())
  }

  return (
    <ModalForm
      route={addRoute}
      // loading={loading}
      title={`Create Security Group`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={creatingSecurityGroup || updatingRules || deletingRules}
      error={creatingSecurityGroupError || rulesError || deleteRulesError}
      submitTitle={`Create Security Group`}
    >
      <>
        <FormFieldSection title="Basic Fields">
          <TextField
            id="name"
            label="Name"
            onChange={getParamsUpdater('name')}
            value={params.name}
            validations={[nameValidator]}
            required
          />
          <TextField
            id="description"
            label="Description"
            onChange={getParamsUpdater('description')}
            value={params.description}
          />
        </FormFieldSection>
        <FormFieldSection title="Outbound Security Group Rules">
          <Text variant="body2">
            <b>NOTE:</b> A rule allowing all outbound traffic is added by default. You can remove it
            if desired.
          </Text>
          <SecurityGroupRulesParamFields
            params={params}
            getParamsUpdater={getParamsUpdater}
            updateParams={updateParams}
            direction="outbound"
          />
        </FormFieldSection>
        <FormFieldSection title="Inbound Security Group Rules">
          <SecurityGroupRulesParamFields
            params={params}
            getParamsUpdater={getParamsUpdater}
            updateParams={updateParams}
            direction="inbound"
          />
        </FormFieldSection>
      </>
    </ModalForm>
  )
}
