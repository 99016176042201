import React, { useCallback } from 'react'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import { snapshotVirtualMachine } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import Modal from 'core/elements/modal'
import ModalForm from 'core/elements/modal/ModalForm'
import useParams from 'core/hooks/useParams'
import moment from 'moment'
import TextField from 'core/components/validatedForm/TextField'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'

export default function SnapshotVirtualMachineDialog({ rows: [vm], action, onClose }) {
  const classes = useStyles()
  const defaultParams = {
    name: `${vm?.name}-${moment().format('YYYY-MM-DD-HH:mm:ss')}`,
  }

  const { params, getParamsUpdater, updateParams, setParams } = useParams(defaultParams)

  const { update: snapshotVm, updating, error } = useUpdateAction(snapshotVirtualMachine)
  const title = `${vm?.name}`
  const submitForm = useCallback(async () => {
    const body = {
      createImage: {
        name: params.name,
      },
    }
    const { success } = await snapshotVm({
      id: vm.id,
      body,
    })
    if (success) onClose(true)
  }, [params, vm])

  return (
    <ModalForm
      open
      panel="dialog"
      title={`Snapshot VM`}
      onSubmit={submitForm}
      onClose={onClose}
      submitting={updating}
      error={error}
      submitTitle={`Snapshot VM`}
    >
      <div className={classes.body}>
        <Text variant="body2">
          You are about to take an image snapshot of the VM instance <b>{vm?.name || vm?.id}</b>
        </Text>
        <TextField
          id="name"
          label="Image Name"
          onChange={getParamsUpdater('name')}
          value={params.name}
          info="Choose a name for the created image"
          required
        />
      </div>
    </ModalForm>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  body: {
    display: 'grid',
    gap: 12,
  },
}))
