import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { clientActions } from 'core/client/clientReducers'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import useReactRouter from 'use-react-router'
import DocumentMeta from 'core/components/DocumentMeta'
import { routes } from 'core/utils/routes'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listNetworks } from '../actions'
import { virtualNetworksSelector } from '../selectors'
import { INetworkDetailsPageTabs } from '../network-details/model'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Overview from '../network-details/Overview'
import SubnetsPage from '../network-details/SubnetsPage'
import PortsPage from '../network-details/PortsPage'

const useStyles = makeStyles<Theme>((theme) => ({
  grid: {
    display: 'grid',
    gap: 32,
  },
  actions: {
    display: 'grid',
    gridAutoColumns: 'auto max-content',
    gridAutoFlow: 'column',
    gap: 16,
  },
}))

const VirtualNetworkDetailsPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id } = match.params
  const { loading } = useListAction(listNetworks)
  const networks = useSelectorWithParams(virtualNetworksSelector, {})
  const network = useMemo(() => networks.find((network) => network.id === id) || {}, [id, networks])

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        id: network?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [network?.name, id])

  return (
    <>
      <DocumentMeta title="Virtual Network Details" breadcrumbs />
      <Tabs route={routes.openstack.virtualNetworkDetails}>
        <Tab label="Overview" value={INetworkDetailsPageTabs.Overview}>
          <Overview network={network} loading={loading} />
        </Tab>
        <Tab label="Subnets" value={INetworkDetailsPageTabs.Subnets}>
          <SubnetsPage network={network} loading={loading} />
        </Tab>
        <Tab label="Ports" value={INetworkDetailsPageTabs.Ports}>
          <PortsPage network={network} loading={loading} />
        </Tab>
      </Tabs>
    </>
  )
}

export default VirtualNetworkDetailsPage
