import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'

export const securityGroupsSelector = createSharedSelector(
  getDataSelector<DataKeys.SecurityGroups>(DataKeys.SecurityGroups),
  (securityGroups) => {
    return securityGroups.map((securityGroup) => {
      return {
        ...securityGroup,
        inboundRules: securityGroup?.security_group_rules?.filter(
          (rule) => rule.direction === 'ingress',
        ),
        outboundRules: securityGroup?.security_group_rules?.filter(
          (rule) => rule.direction === 'egress',
        ),
      }
    })
  },
)

export const securityGroupsByIdSelector = createSharedSelector(
  securityGroupsSelector,
  (securityGroups) => {
    return securityGroups.reduce((accum, securityGroup) => {
      return {
        ...accum,
        [securityGroup.id]: securityGroup,
      }
    }, {})
  },
)
