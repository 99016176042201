import React from 'react'
import SelectableCard from 'core/components/SelectableCard'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

const useStyles = makeStyles<Theme>((theme) => ({
  cards: {
    display: 'inline-grid',
    gridAutoFlow: 'column',
    gap: 16,
    gridAutoColumns: 'max-content',
  },
  card: {
    display: 'inline-block',
  },
  cardContainer: {
    display: 'grid',
    height: 68,
    alignItems: 'center',
    width: 60,
    justifyItems: 'center',
    textAlign: 'center',
  },
  activeIcon: {
    color: theme.palette.blue[900],
  },
}))

const sourceTypes = [
  {
    name: 'none',
    label: 'None (Empty Volume)',
  },
  {
    name: 'snapshot',
    label: 'Snapshot',
  },
  {
    name: 'volume',
    label: 'Another Volume',
  },
  {
    name: 'image',
    label: 'Image',
  },
]

export default function VolumeSourceCards({ onClick, value }) {
  const classes = useStyles()

  return (
    <div className={classes.cards}>
      {sourceTypes.map((type) => (
        <SelectableCard
          key={type.name}
          id={type.name}
          onClick={() => onClick(type.name)}
          active={value === type.name}
          className={classes.card}
          showCheckmarkIcon
        >
          <div className={classes.cardContainer}>
            <Text variant="body2">
              <b>{type.label}</b>
            </Text>
          </div>
        </SelectableCard>
      ))}
    </div>
  )
}
