import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import { attachVolume } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import useParams from 'core/hooks/useParams'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import ListTableField from 'core/components/validatedForm/ListTableField'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listVirtualMachines } from 'openstack/components/vms/actions'
import { virtualMachinesSelector } from 'openstack/components/vms/selectors'
import { GridViewColumn } from 'core/elements/grid/Grid'

const useStyles = makeStyles<Theme>((theme) => ({
  fields: {
    gap: 16,
    display: 'grid',
  },
}))

const vmColumns = [
  {
    id: 'name',
    label: 'Available VMs',
    width: 'medium',
  },
  {
    id: 'OS-EXT-STS:vm_state',
    label: 'State',
  },
]

export default function AttachVolumeDialog({ rows: [volume], onClose }) {
  const classes = useStyles()

  const defaultParams = {
    vm: null,
  }
  const { params, getParamsUpdater, setParams } = useParams(defaultParams)

  const { loading: loadingVms } = useListAction(listVirtualMachines, {
    params: {},
  })

  const vms = useSelectorWithParams(virtualMachinesSelector, {})
  const vmsList = useMemo(() => {
    return vms.filter((vm) => {
      return ['active', 'suspended', 'stopped'].includes(vm['OS-EXT-STS:vm_state'])
    })
  }, [vms])

  const { update, updating, error, reset } = useUpdateAction(attachVolume)

  const handleSubmit = useCallback(async () => {
    const vm = params.vm[0]
    const body = {
      volumeAttachment: {
        volumeId: volume.id,
      },
    }
    const { success } = await update({ id: volume.id, vmId: vm.id, body })
    if (success) handleClose()
  }, [params, volume])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    onClose()
  }

  return (
    <ModalForm
      title="Attach Volume"
      onSubmit={handleSubmit}
      onClose={onClose}
      submitting={updating}
      error={error}
      submitTitle={`Attach Volume`}
      panel="dialog"
      maxWidth={1200}
      open
    >
      <div className={classes.fields}>
        <Text variant="body2">Select the VM that you would like to attach the volume to.</Text>
        <Text variant="body2">
          You can attach a volume to at most one virtual machine instance. Attaching a volume to a
          VM makes it accessible to that VM as an independent disk.
        </Text>
        <Text variant="body2">
          <b>NOTE</b>: An instance must be in <b>active</b>, <b>suspended</b>, or <b>stopped</b>{' '}
          state before you can attach a volume to it. Only instances in these states are listed
          below.
        </Text>
        <ListTableField
          id="vm"
          data={vmsList}
          loading={loadingVms}
          columns={vmColumns}
          onChange={getParamsUpdater('vm')}
          value={params.vm}
          uniqueIdentifier="id"
          searchTargets={['name', 'id']}
          required
        />
      </div>
    </ModalForm>
  )
}
