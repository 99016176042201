import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import DeleteAction from 'core/actions/DeleteAction'
import UpdateAction from 'core/actions/UpdateAction'
import CustomAction from 'core/actions/CustomAction'
import CreateAction from 'core/actions/CreateAction'
import store from 'app/store'
import { cacheActions } from 'core/caching/cacheReducers'

const { dispatch } = store
const { cinder, nova } = ApiClient.getInstance()

export const volumeTypeActions = ActionsSet.make<DataKeys.VolumeTypes>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.VolumeTypes,
  cacheKey: DataKeys.VolumeTypes,
})

export const listVolumeTypes = volumeTypeActions.add(
  new ListAction<DataKeys.VolumeTypes>(async () => {
    return cinder.getVolumeTypes()
  }),
)

export const createVolumeType = volumeTypeActions.add(
  new CreateAction<DataKeys.VolumeTypes, { body }>(async ({ body }) => {
    const created = await cinder.createVolumeType(body)
    const volumeTypeId = created.id
    const newVolumeType = await cinder.getVolumeType(volumeTypeId)
    return newVolumeType
  }),
)

export const updateVolumeType = volumeTypeActions.add(
  new UpdateAction<
    DataKeys.VolumeTypes,
    {
      id: string
      body: unknown
      keysToDelete: string[]
    }
  >(async ({ id, body, keysToDelete }) => {
    await cinder.updateVolumeType(id, body, keysToDelete)
    const updatedVolumeType = await cinder.getVolumeType(id)
    return updatedVolumeType
  }),
)

export const deleteVolumeType = volumeTypeActions.add(
  new DeleteAction<DataKeys.VolumeTypes, { id: string }>(async ({ id }) => {
    await cinder.deleteVolumeType(id)
  }),
)
