import React, { useCallback, useMemo, useState } from 'react'
import Text from 'core/elements/Text'
import { migrateVirtualMachine } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import useParams from 'core/hooks/useParams'
import ListTableField from 'core/components/validatedForm/ListTableField'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listResmgrHosts, listHypervisors } from 'openstack/components/infrastructure/actions'
import {
  resmgrHostsSelector,
  hypervisorsSelector,
} from 'openstack/components/infrastructure/selectors'
import ModalForm from 'core/elements/modal/ModalForm'
import {
  HostStatusCell,
  IpAddressCellComponent,
} from 'openstack/components/infrastructure/hosts/HostsListPage'
import StatsCell from 'app/plugins/infrastructure/components/common/cells/StatsCell'
import { uniq, without } from 'ramda'

const hostColumns = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'id',
    label: 'ID',
    display: false,
  },
  {
    id: 'info.responding',
    label: 'Responding',
    render: (value) => (value === undefined ? 'Unknown' : value ? 'True' : 'False'),
  },
  {
    id: 'interfaces',
    label: 'IP Address',
    render: (value, item) => <IpAddressCellComponent value={value} />,
  },
  {
    id: 'usage',
    label: 'Resource Utilization',
    render: (value, item) => <StatsCell value={value} item={item} />,
  },
]

export default function MigrateVirtualMachineDialog({ rows: vms, onClose }) {
  const classes = useStyles()
  const [submitting, setSubmitting] = useState(false)
  const { error, update: migrateFn, updating } = useUpdateAction(migrateVirtualMachine)
  const title = `Migrate ${vms.length > 1 ? 'VMs' : 'VM'}`
  const vmHostIds = useMemo(() => {
    return uniq(vms.map((vm) => vm['OS-EXT-SRV-ATTR:host']))
  }, [vms])
  const defaultParams = {
    hosts: [],
  }
  const { params, getParamsUpdater, updateParams, setParams } = useParams(defaultParams)

  const { loading: loadingHosts } = useListAction(listResmgrHosts, {
    params: {},
  })
  const hosts = useSelectorWithParams(resmgrHostsSelector, {})

  const { loading: loadingHypervisors } = useListAction(listHypervisors, {
    params: {},
  })
  const hypervisors = useSelectorWithParams(hypervisorsSelector, {})

  const hypervisorHosts = useMemo(() => {
    if (loadingHosts || loadingHypervisors) {
      return []
    }

    const hypervisorIds = hypervisors.flatMap((hypervisor) => {
      // Filter hypervisors which have scheduling disabled
      if (hypervisor?.status === 'enabled') {
        return [hypervisor?.service?.host]
      }
      return []
    })
    const validHypervisorIds = without(vmHostIds, hypervisorIds)
    return hosts.filter((host) => {
      return validHypervisorIds.includes(host?.id)
    })
  }, [hosts, hypervisors, loadingHosts, loadingHypervisors, vmHostIds])

  const handleSubmit = useCallback(() => {
    const asyncActions = async () => {
      let success = true
      const body = {
        'os-migrateLive': {
          host: params.hosts[0]?.id,
          block_migration: 'auto',
        },
      }
      for (const vm of vms) {
        try {
          await migrateFn({ id: vm.id, body })
        } catch (e) {
          success = false
          console.log(e)
        }
      }
      if (success) {
        setSubmitting(false)
        onClose(true)
      }
    }
    setSubmitting(true)
    asyncActions()
  }, [params, vms])

  return (
    <ModalForm
      title={title}
      onSubmit={handleSubmit}
      onClose={onClose}
      loading={loadingHosts || loadingHypervisors}
      submitting={submitting}
      error={error}
      submitTitle={title}
      panel="dialog"
      maxWidth={1200}
      open
    >
      <div className={classes.container}>
        <Text variant="body2">
          You are about to migrate the following VM instances:{' '}
          <b>{vms.map((vm) => vm.name || vm.id).join(', ')}</b>
        </Text>
        <Text variant="body2">
          Select the cluster server you would like to migrate the selected VMs to
        </Text>
        <Text variant="body2">
          <b>Note:</b> Cluster servers hosting a selected VM instance will not be displayed in the
          list below.
        </Text>
        <ListTableField
          id="hosts"
          data={hypervisorHosts}
          loading={loadingHosts || loadingHypervisors}
          columns={hostColumns}
          onChange={getParamsUpdater('hosts')}
          value={params.hosts}
          uniqueIdentifier="id"
          searchTargets={['name', 'id']}
          emptyText="No available cluster servers found"
          required
        />
      </div>
    </ModalForm>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: 'grid',
    gap: 24,
  },
}))
