export const defaultValuesForRuleType = {
  customTcp: {
    portRange: '',
    destination: 'cidr',
    cidr: '',
    securityGroup: null,
  },
  customUdp: {
    portRange: '',
    destination: 'cidr',
    cidr: '',
    securityGroup: null,
  },
  // Todo: icmp should take a type for port_range_min and code for port_range_max
  customIcmp: {
    portRange: '',
    destination: 'cidr',
    cidr: '',
    securityGroup: null,
  },
  allTcp: {
    portRange: '1 - 65535',
    destination: 'cidr',
    cidr: '0.0.0.0/0',
    securityGroup: null,
  },
  allUdp: {
    portRange: '1 - 65535',
    destination: 'cidr',
    cidr: '0.0.0.0/0',
    securityGroup: null,
  },
  allIcmp: {
    portRange: 'all',
    destination: 'cidr',
    cidr: '0.0.0.0/0',
    securityGroup: null,
  },
  allTraffic: {
    portRange: 'all',
    destination: 'cidr',
    cidr: '0.0.0.0/0',
    securityGroup: null,
  },
}

export const getRuleJson = ({ rule, direction, securityGroupId }) => {
  const defaultMinRange = 1
  const defaultMaxRange = 65535

  const [minRange, maxRange] = rule.portRange.split('-')
  const portMinRange = Number(minRange) || defaultMinRange
  const portMaxRange = Number(maxRange) || Number(minRange) || defaultMaxRange

  if (rule.type === 'allTraffic') {
    return {
      direction,
      ethertype: 'IPv4',
      security_group_id: securityGroupId,
    }
  } else if (rule.type === 'allTcp') {
    return {
      direction,
      ethertype: 'IPv4',
      protocol: 'tcp',
      security_group_id: securityGroupId,
    }
  } else if (rule.type === 'allUdp') {
    return {
      direction,
      ethertype: 'IPv4',
      protocol: 'udp',
      security_group_id: securityGroupId,
    }
  } else if (rule.type === 'allIcmp') {
    return {
      direction,
      ethertype: 'IPv4',
      protocol: 'icmp',
      security_group_id: securityGroupId,
    }
  } else if (rule.type === 'customTcp') {
    return {
      direction,
      ethertype: 'IPv4',
      protocol: 'tcp',
      port_range_min: portMinRange,
      port_range_max: portMaxRange,
      remote_group_id: rule.destination === 'securityGroup' ? rule.securityGroup?.id : undefined,
      remote_ip_prefix: rule.destination === 'cidr' ? rule.cidr : undefined,
      security_group_id: securityGroupId,
    }
  } else if (rule.type === 'customUdp') {
    return {
      direction,
      ethertype: 'IPv4',
      protocol: 'udp',
      port_range_min: portMinRange,
      port_range_max: portMaxRange,
      remote_group_id: rule.destination === 'securityGroup' ? rule.securityGroup?.id : undefined,
      remote_ip_prefix: rule.destination === 'cidr' ? rule.cidr : undefined,
      security_group_id: securityGroupId,
    }
  } else if (rule.type === 'customIcmp') {
    return {
      direction,
      ethertype: 'IPv4',
      protocol: 'icmp',
      port_range_min: rule.icmpType,
      port_range_max: rule.icmpCode,
      remote_group_id: rule.destination === 'securityGroup' ? rule.securityGroup?.id : undefined,
      remote_ip_prefix: rule.destination === 'cidr' ? rule.cidr : undefined,
      security_group_id: securityGroupId,
    }
  }
  return {}
}

const getTypeDisplay = (rule) => {
  if (rule.protocol === 'tcp') {
    if (
      !rule.port_range_min &&
      !rule.port_range_max &&
      !rule.remote_group_id &&
      !rule.remote_ip_prefix
    ) {
      return 'All TCP'
    }
    return 'Custom TCP'
  } else if (rule.protocol === 'udp') {
    if (
      !rule.port_range_min &&
      !rule.port_range_max &&
      !rule.remote_group_id &&
      !rule.remote_ip_prefix
    ) {
      return 'All UDP'
    }
    return 'Custom UDP'
  } else if (rule.protocol === 'icmp') {
    if (
      !rule.port_range_min &&
      !rule.port_range_max &&
      !rule.remote_group_id &&
      !rule.remote_ip_prefix
    ) {
      return 'All ICMP'
    }
    return 'Custom ICMP'
  } else if (!rule.protocol) {
    if (
      !rule.port_range_min &&
      !rule.port_range_max &&
      !rule.remote_group_id &&
      !rule.remote_ip_prefix
    ) {
      return 'All Traffic'
    }
    return 'Custom All'
  }
  return 'N/A'
}

const getPortOrCodeDisplay = (rule) => {
  if (!rule.port_range_max && !rule.port_range_min) {
    return 'All'
  }
  if (rule.protocol === 'icmp') {
    return `Type ${rule.port_range_min} Code ${rule.port_range_max}`
  }
  return `${rule.port_range_min} - ${rule.port_range_max}`
}

export const transformSecurityGroupRuleForGrid = ({ rule, securityGroupsById }) => {
  return {
    ...rule,
    directionDisplay:
      rule.direction === 'ingress' ? 'Inbound' : rule.direction === 'egress' ? 'Outbound' : 'N/A',
    typeDisplay: getTypeDisplay(rule),
    protocolDisplay: rule.protocol ? rule.protocol.toUpperCase() : 'All',
    portOrCodeDisplay: getPortOrCodeDisplay(rule),
    destination: !!rule.remote_ip_prefix
      ? 'CIDR'
      : !!rule.remote_group_id
      ? 'Security Group'
      : 'N/A',
    cidrOrSecurityGroupDisplay: !!rule.remote_ip_prefix
      ? rule.remote_ip_prefix
      : !!rule.remote_group_id
      ? securityGroupsById?.[rule.remote_group_id]?.name
      : 'N/A',
  }
}
