import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

const options = [
  { label: 'Custom TCP Rule', value: 'customTcp' },
  { label: 'Custom UDP Rule', value: 'customUdp' },
  { label: 'Custom ICMP Rule', value: 'customIcmp' },
  { label: 'All TCP', value: 'allTcp' },
  { label: 'All UDP', value: 'allUdp' },
  { label: 'All ICMP', value: 'allIcmp' },
  { label: 'All Traffic', value: 'allTraffic' },
]

interface Props {}

export default function RuleTypePicklist({
  name = 'type',
  label = 'Type',
  compact = false,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  return <AsyncDropdown compact={compact} name={name} label={label} items={options} {...rest} />
}
