import { makeStyles } from '@material-ui/styles'
import { listTablePrefs } from 'app/constants'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import Theme from 'core/themes/model'
import React, { useMemo, useState } from 'react'
import { GridViewColumn } from 'core/elements/grid/Grid'
import ListContainer from 'core/containers/ListContainer'
import { pick } from 'ramda'
import { listSubnets, listNetworks, listPorts } from '../actions'
import {
  networksSelector,
  subnetsSelector,
  portsSelector,
  portsAsInterfacesSelector,
} from '../selectors'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import usePluginRouter from 'core/hooks/usePluginRouter'
import useListAction from 'core/hooks/useListAction'
import CreateRouterInterfaceModal from './CreateRouterInterfaceModal'
import { routes } from 'core/utils/routes'
import { useSelector } from 'react-redux'
import { GridBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import DeleteRouterInterfaceDialog from './DeleteRouterInterfaceDialog'

type ModelDataKey = DataKeys.OpenstackPorts
type SelectorModel = ArrayElement<ReturnType<typeof portsSelector>>

const useStyles = makeStyles<Theme>((theme) => ({
  interfaces: {
    marginTop: '16px',
  },
}))

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'subnetLabel',
    label: 'Subnet',
  },
  {
    key: 'staticIp',
    label: 'Private IP',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'device_owner',
    label: 'Type',
  },
  {
    key: 'admin_state_up',
    label: 'Admin State',
    render: (state) => (state ? 'Up' : 'Down'),
  },
]

const usePrefParams = createUsePrefParamsHook('RouterInterfaces', listTablePrefs)

export default function RouterInterfacesPage({ router, loading }) {
  const classes = useStyles()
  const { params, getParamsUpdater } = usePrefParams({})
  const [selectedInterface, setSelectedInterface] = useState(null)
  const [showDeleteInterfaceModal, setShowDeleteInterfaceModal] = useState(false)

  const {
    message: networksMessage,
    loading: loadingNetworks,
    reload: reloadNetworks,
  } = useListAction(listNetworks, {
    params,
  })
  const networks = useSelector(networksSelector)
  const { message: subnetsMessage, loading: loadingSubnets, reload: reloadSubnets } = useListAction(
    listSubnets,
    {
      params,
    },
  )
  const subnets = useSelector(subnetsSelector)
  const { loading: loadingPorts, reload: reloadPorts } = useListAction(listPorts, {
    params,
  })
  const ports = useSelector(portsAsInterfacesSelector)

  const routerInterfaces = useMemo(() => {
    const routerPorts = ports.filter((port) => {
      return port.device_id === router.id && port.device_owner
    })
    return routerPorts
  }, [router, networks, subnets, ports])

  const batchActions: GridBatchActionSpec<SelectorModel>[] = [
    {
      cond: (port) => {
        return !!port
      },
      label: 'Delete Interface',
      icon: 'trash-alt',
      handleAction: ([port]) => {
        setSelectedInterface(port)
        setShowDeleteInterfaceModal(true)
      },
      refreshAfterSuccess: true,
    },
  ]

  return (
    <article className={classes.interfaces}>
      <CreateRouterInterfaceModal
        addRoute={routes.openstack.createRouterInterface}
        router={router}
      />
      {showDeleteInterfaceModal && (
        <DeleteRouterInterfaceDialog
          onClose={() => setShowDeleteInterfaceModal(false)}
          rows={[selectedInterface]}
        />
      )}
      <ListContainer<ModelDataKey, SelectorModel>
        label="Interfaces"
        showBreadcrumbs={false}
        dataKey={DataKeys.OpenstackPorts}
        searchTargets={['name']}
        uniqueIdentifier="id"
        loading={loading || loadingSubnets || loadingNetworks || loadingPorts}
        loadingMessage={networksMessage || subnetsMessage}
        onRefresh={() => {
          reloadSubnets(true, true)
          reloadNetworks(true, true)
          reloadPorts(true, true)
        }}
        data={routerInterfaces}
        columns={columns}
        addUrl={routes.openstack.createRouterInterface.path({ id: router?.id })}
        addText="Create Interface"
        getParamsUpdater={getParamsUpdater}
        batchActions={batchActions}
        // deleteAction={deleteSubnet}
        // EditDialogComponent={allowCrud ? EditSubnetModal : undefined}
        {...pick(listTablePrefs, params)}
      />
    </article>
  )
}
