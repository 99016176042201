import React, { useCallback } from 'react'
import useParams from 'core/hooks/useParams'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createPort, listNetworks } from '../actions'
import { listSecurityGroups } from '../security-groups/actions'
import { securityGroupsSelector } from '../security-groups/selectors'
import ModalForm from 'core/elements/modal/ModalForm'
import { Route } from 'core/plugins/route'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import useListAction from 'core/hooks/useListAction'
import { INetworkDetailsPageTabs } from './model'
import { useSelector } from 'react-redux'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { RootState } from 'app/store'
import { prop, remove, update } from 'ramda'
import TextField from 'core/components/validatedForm/TextField'
import AdminStatePicklist from '../AdminStatePicklist'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import ListTableField from 'core/components/validatedForm/ListTableField'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import Text from 'core/elements/Text'
import { customValidator } from 'core/utils/fieldValidators'
import {
  isValidIpv4Address,
  isValidIpv6Address,
  isValidIpv4Cidr,
  isValidIpv6Cidr,
} from 'app/plugins/infrastructure/components/clusters/form-components/validators'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import PicklistField from 'core/components/validatedForm/DropdownField'
import SubnetPicklist from './SubnetPicklist'
import * as IpAddress from 'ip-address'
import { IPVersions } from 'app/constants'

const useStyles = makeStyles<Theme>((theme) => ({
  operationLabel: {
    width: 'fit-content',
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    gap: 8,
  },
  icon: {
    color: theme.components.badge.primary.color,
  },
  pairFields: {
    display: 'grid',
    gap: 16,
    marginTop: 16,
    paddingLeft: 24,
  },
}))

export const securityGroupColumns = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'description',
    label: 'Description',
  },
]

export const getSubnetRequiredValidator = (subnet) =>
  customValidator((value, formValues) => {
    if (!value) {
      return true
    }
    return !!subnet
  }, 'Subnet selection required')

export const getFixedIpValidator = (subnet) =>
  customValidator((value) => {
    if (!value || !subnet?.cidr) {
      return true
    }
    try {
      const cidr = subnet?.cidr
      const ipVersion = subnet?.ip_version
      const testCidr =
        ipVersion === IPVersions.IPv4 ? new IpAddress.Address4(cidr) : new IpAddress.Address6(cidr)
      return ipVersion === IPVersions.IPv4
        ? new IpAddress.Address4(value).isInSubnet(testCidr)
        : new IpAddress.Address6(value).isInSubnet(testCidr)
    } catch (err) {
      return false
    }
  }, 'Private IP not within subnet CIDR')

export const ipAddressValidator = customValidator((value) => {
  if (!value) {
    return true
  }
  return (
    isValidIpv4Address(value) ||
    isValidIpv6Address(value) ||
    isValidIpv4Cidr(value) ||
    isValidIpv6Address(value)
  )
}, 'Invalid IP address or CIDR provided')

export const macAddressValidator = customValidator((value) => {
  if (!value) {
    return true
  }
  const regex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/
  return regex.test(value)
}, 'Invalid MAC address provided')

interface Props {
  addRoute: Route
  isVirtualNetwork?: boolean
}

export default function CreatePortModal({ addRoute, isVirtualNetwork = false }: Props) {
  const classes = useStyles()
  const { history, match } = useReactRouter()
  const { id: networkId } = match.params
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const { features } = session
  const isVmware = features?.experimental?.pmov2_du_type === 'vmware'
  const { loading: loadingSecurityGroups } = useListAction(listSecurityGroups, {
    params: {},
  })
  const securityGroupsList = useSelector(securityGroupsSelector)

  const parentRoute = isVmware
    ? routes.openstack.vmwareNetworkDetails
    : isVirtualNetwork
    ? routes.openstack.virtualNetworkDetails
    : routes.openstack.networkDetails

  const defaultParams = {
    name: '',
    adminState: 'Up',
    allowedAddressPairs: [],
    macAddress: '',
    applyPortSecurity: false,
    securityGroups: [],
    fixedIps: [{ fixedIp: '', subnet: null }],
  }
  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  const { reload } = useListAction(listNetworks, {})
  const { update: createFn, updating, error, reset } = useUpdateAction(createPort)

  const addNewFixedIp = useCallback(() => {
    updateParams({
      fixedIps: [...params.fixedIps, { subnet: null, fixedIp: '' }],
    })
  }, [params.fixedIps])

  const updateFixedIp = useCallback(
    ({ idx, ip, values }) => {
      const updatedIp = {
        ...ip,
        ...values,
      }
      const updatedIps = update(idx, updatedIp, params.fixedIps)
      updateParams({
        fixedIps: updatedIps,
      })
    },
    [params.fixedIps, updateParams],
  )

  const removeFixedIp = useCallback(
    (index) => {
      updateParams({
        fixedIps: remove(index, 1, params.fixedIps),
      })
    },
    [params.fixedIps],
  )

  const updatePair = useCallback(
    ({ idx, pair, values }) => {
      const updatedPair = {
        ...pair,
        ...values,
      }
      const updatedPairs = update(idx, updatedPair, params.allowedAddressPairs)
      updateParams({
        allowedAddressPairs: updatedPairs,
      })
    },
    [params.allowedAddressPairs, updateParams],
  )

  const addNewPair = useCallback(() => {
    updateParams({
      allowedAddressPairs: [...params.allowedAddressPairs, { ipAddress: '', macAddress: '' }],
    })
  }, [params.allowedAddressPairs])

  const removePair = useCallback(
    (index) => {
      updateParams({
        allowedAddressPairs: remove(index, 1, params.allowedAddressPairs),
      })
    },
    [params.allowedAddressPairs],
  )

  const createPortFn = async (params, networkId) => {
    const addressPairs = params.allowedAddressPairs.map((pair) => {
      return {
        ip_address: pair.ipAddress,
        mac_address: pair.macAddress ? pair.macAddress : undefined,
      }
    })
    const definedFixedIps = params.fixedIps?.filter((ip) => ip.fixedIp || ip.subnet)
    const body = {
      port: {
        name: params.name,
        network_id: networkId,
        fixed_ips: definedFixedIps?.length
          ? definedFixedIps.map((ip) => ({
              ip_address: ip?.fixedIp ? ip.fixedIp : undefined,
              subnet_id: ip?.subnet?.id,
            }))
          : undefined,
        mac_address: params.macAddress ? params.macAddress : undefined,
        port_security_enabled: params.applyPortSecurity,
        admin_state_up: params.adminState === 'Up' ? true : false,
        allowed_address_pairs: addressPairs,
        security_groups: params.securityGroups.map((securityGroup) => securityGroup.id),
      },
    }
    const { success } = await createFn({ body })
    return { success }
  }

  const submitForm = useCallback(async () => {
    const { success } = await createPortFn(params, networkId)
    if (success) {
      reload(true, true)
      handleClose()
    }
  }, [params, networkId])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(
      parentRoute.path({
        id: networkId,
        tab: INetworkDetailsPageTabs.Ports,
      }),
    )
  }

  return (
    <ModalForm
      route={addRoute}
      title={`Create Port`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      submitTitle={`Create Port`}
    >
      <FormFieldSection title="Port Configuration">
        <TextField
          id="name"
          label="Name"
          onChange={getParamsUpdater('name')}
          value={params.name}
          required
        />
        <AdminStatePicklist value={params.adminState} onChange={getParamsUpdater('adminState')} />
        <TextField
          id="macAddress"
          label="MAC Address"
          onChange={getParamsUpdater('macAddress')}
          value={params.macAddress}
        />
      </FormFieldSection>
      <FormFieldSection title="Assign Private IP Addresses">
        {params.fixedIps.map((ip, idx) => (
          <div key={idx}>
            <div className={classes.operationLabel} onClick={() => removeFixedIp(idx)}>
              <FontAwesomeIcon className={classes.icon} size="lg" solid>
                circle-minus
              </FontAwesomeIcon>
              <Text variant="subtitle2">Private IP {idx + 1}</Text>
            </div>
            <div className={classes.pairFields}>
              <PicklistField
                DropdownComponent={SubnetPicklist}
                name="subnet"
                id={`fixedIps.${idx}.subnet`}
                label="Subnet"
                compact={false}
                onChange={(value) => {
                  updateFixedIp({ idx, ip, values: { subnet: value } })
                }}
                networkId={networkId}
              />
              <TextField
                id={`fixedIps.${idx}.fixedIp`}
                label="Private IP"
                onChange={(value) => {
                  updateFixedIp({ idx, ip, values: { fixedIp: value } })
                }}
                value={ip?.fixedIp}
                info="IP Address must be within the subnet CIDR"
                validations={[
                  getFixedIpValidator(ip.subnet),
                  getSubnetRequiredValidator(ip.subnet),
                ]}
              />
            </div>
          </div>
        ))}
        <div className={classes.operationLabel} onClick={addNewFixedIp}>
          <FontAwesomeIcon className={classes.icon} size="lg" solid>
            circle-plus
          </FontAwesomeIcon>
          <Text variant="body2">Add Private IP Address</Text>
        </div>
      </FormFieldSection>
      <FormFieldSection title="Security Groups">
        <CheckboxField
          id="applyPortSecurity"
          label="Apply Port Security Groups"
          value={params.applyPortSecurity}
          onChange={getParamsUpdater('applyPortSecurity')}
          info="If checked, enable security groups for this port."
        />
        {params.applyPortSecurity && (
          <ListTableField
            id="securityGroups"
            data={securityGroupsList}
            loading={loadingSecurityGroups}
            columns={securityGroupColumns}
            onChange={getParamsUpdater('securityGroups')}
            value={params.securityGroups}
            uniqueIdentifier="id"
            searchTargets={['name']}
            multiSelection
            required
          />
        )}
      </FormFieldSection>
      <FormFieldSection title="Allowed Address Pairs">
        <Text variant="body2">
          A server connected to this port can send a packet with source address which matches one of
          the specified allowed address pairs.
        </Text>
        {params.allowedAddressPairs.map((pair, idx) => (
          <div key={idx}>
            <div className={classes.operationLabel} onClick={() => removePair(idx)}>
              <FontAwesomeIcon className={classes.icon} size="lg" solid>
                circle-minus
              </FontAwesomeIcon>
              <Text variant="subtitle2">Pair {idx + 1}</Text>
            </div>
            <div className={classes.pairFields} key={idx}>
              <TextField
                id={`allowedAddressPairs.${idx}.ipAddress`}
                label="IP Address or CIDR"
                onChange={(value) => {
                  updatePair({ idx, pair, values: { ipAddress: value } })
                }}
                value={pair.ipAddress}
                placeholder="eg. 12.12.11.12"
                required
                validations={[ipAddressValidator]}
              />
              <TextField
                id={`allowedAddressPairs.${idx}.macAddress`}
                label="MAC Address"
                onChange={(value) => {
                  updatePair({ idx, pair, values: { macAddress: value } })
                }}
                value={pair.macAddress}
                placeholder="eg. fa:14:2a:b3:cb:f0"
                info="MAC address will be taken from the port if not specified"
                validations={[macAddressValidator]}
              />
            </div>
          </div>
        ))}
        <div className={classes.operationLabel} onClick={addNewPair}>
          <FontAwesomeIcon className={classes.icon} size="lg" solid>
            circle-plus
          </FontAwesomeIcon>
          <Text variant="body2">Add Pair</Text>
        </div>
      </FormFieldSection>
    </ModalForm>
  )
}
