import React, { useCallback, useMemo } from 'react'
import useParams from 'core/hooks/useParams'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { updateVolumeType } from './actions'
import ModalForm from 'core/elements/modal/ModalForm'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import KeyValuesField from 'core/components/validatedForm/KeyValuesField'

const useStyles = makeStyles<Theme>((theme) => ({
  sameLine: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
  },
}))

interface Props {
  rows: any[]
  onClose: () => void
}

export default function EditVolumeTypeModal({ rows: [volumeType], onClose }: Props) {
  const classes = useStyles()

  const defaultParams = useMemo(
    () => ({
      metadata: Object.entries(volumeType.extra_specs).map(([key, value]) => {
        return { key, value }
      }),
    }),
    [volumeType.extra_specs],
  )

  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(updateVolumeType)

  const submitForm = useCallback(async () => {
    // calc new/update keys
    const originalMetadata = volumeType?.extra_specs

    // Calc delete keys
    const originalMetadataKeys = Object.keys(originalMetadata)
    const newMetadataObj = params.metadata.reduce((accum, pair) => {
      return {
        ...accum,
        [pair.key]: pair.value,
      }
    }, {})
    const deleteKeys = originalMetadataKeys.filter((key) => {
      return !newMetadataObj[key]
    })

    const body = {
      extra_specs: newMetadataObj,
    }
    const { success } = await update({ id: volumeType.id, body, keysToDelete: deleteKeys })
    if (!success) return
    handleClose()
  }, [volumeType?.id, volumeType?.extra_specs, params])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    onClose()
  }

  return (
    <ModalForm
      title={`Edit Volume Type`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      submitTitle={`Update Volume Type`}
      open
    >
      <>
        <FormFieldSection title="Volume Type Metadata">
          <KeyValuesField
            id="metadata"
            label="Metadata"
            value={params.metadata}
            onChange={getParamsUpdater('metadata')}
            addLabel="Add Metadata"
            allowMultipleValues
          />
        </FormFieldSection>
      </>
    </ModalForm>
  )
}
