import React, { useMemo } from 'react'
import Dropdown from 'core/elements/dropdown'
import withProgress from 'core/components/progress/withProgress'
import useListAction from 'core/hooks/useListAction'
import { listPorts } from 'openstack/components/networks/actions'
import { listFloatingIps } from 'openstack/components/networks/floating-ips/actions'
import { useSelector } from 'react-redux'
import { portsSelector } from 'openstack/components/networks/selectors'
import { floatingIpsSelector } from 'openstack/components/networks/floating-ips/selectors'
import { virtualMachinesByIdSelector } from 'openstack/components/vms/selectors'

const AsyncDropdown = withProgress(Dropdown, { inline: true })

export default function AvailableVmPortsPicklist({
  disabled = false,
  name = 'vmPort',
  label = 'VM Port',
  vmId,
  ...rest
}) {
  const { loading } = useListAction(listPorts)
  const ports = useSelector(portsSelector)
  const { loading: loadingFloatingIps } = useListAction(listFloatingIps)
  const floatingIps = useSelector(floatingIpsSelector)
  const vmsById = useSelector(virtualMachinesByIdSelector)

  const options = useMemo(() => {
    // will need to filter out taken private IPs
    const vm = vmsById[vmId]
    const vmPorts = ports.filter(
      (port) => port.device_owner.includes('compute:') && port.device_id === vmId,
    )
    const fixedIpsWithFloatingIp = floatingIps.map((floatingIp) => floatingIp.fixed_ip_address)
    const freeVmPorts = vmPorts.filter((port) => {
      const fixedIp = port?.fixed_ips?.[0]?.ip_address
      return !fixedIpsWithFloatingIp.includes(fixedIp)
    })
    return freeVmPorts.map((port) => ({
      label: `${vm?.name || vm?.id}: ${port?.fixed_ips?.[0]?.ip_address}`,
      value: port.id,
    }))
  }, [ports, vmId, vmsById, floatingIps])

  return (
    <AsyncDropdown
      {...rest}
      name={name}
      label={label}
      compact={false}
      disabled={disabled}
      loading={loading || loadingFloatingIps}
      items={options}
    />
  )
}
