import React, { Fragment, useMemo } from 'react'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import Text from 'core/elements/Text'

export default function VmInfoCards({ vms }) {
  const classes = useStyles()

  const { states, flavors, images } = useMemo(() => {
    const counts: Record<string, number> = vms.reduce(
      (accum, vm) => {
        return {
          states: {
            ...accum.states,
            [vm.state]: accum.states[vm.state] ? accum.states[vm.state] + 1 : 1,
          },
          flavors: {
            ...accum.flavors,
            [vm.flavorName]: accum.flavors[vm.flavorName] ? accum.flavors[vm.flavorName] + 1 : 1,
          },
          images: {
            ...accum.images,
            [vm.imageName]: accum.images[vm.imageName] ? accum.images[vm.imageName] + 1 : 1,
          },
        }
      },
      { states: {}, flavors: {}, images: {} },
    )
    const sorted = {
      states:
        Object.entries(counts.states)
          .map(([state, count]) => ({ name: state, count }))
          .sort((a, b) => b.count - a.count) || [],
      flavors:
        Object.entries(counts.flavors)
          .map(([flavor, count]) => ({ name: flavor, count }))
          .sort((a, b) => b.count - a.count) || [],
      images:
        Object.entries(counts.images)
          .map(([image, count]) => ({ name: image, count }))
          .sort((a, b) => b.count - a.count) || [],
    }
    return {
      states: sorted.states.length > 3 ? sorted.states.slice(0, 3) : sorted.states,
      flavors: sorted.flavors.length > 3 ? sorted.flavors.slice(0, 3) : sorted.flavors,
      images: sorted.images.length > 3 ? sorted.images.slice(0, 3) : sorted.images,
    }
  }, [vms])

  return (
    <div className={classes.vmCards}>
      <div className={classes.vmCard}>
        <Text variant="subtitle1">VM Instances</Text>
        <hr className={classes.divider} />
        <div className={classes.entry}>
          <Text variant="body2">{vms?.length}</Text>
          <Text variant="body2">VM Instances</Text>
        </div>
      </div>
      <div className={classes.vmCard}>
        <Text variant="subtitle1">States</Text>
        <hr className={classes.divider} />
        <div className={classes.entries}>
          {states.map((state) => (
            <Fragment key={state.name}>
              <Text variant="body2">{state.count}</Text>
              <Text variant="body2">{state.name}</Text>
            </Fragment>
          ))}
        </div>
      </div>
      <div className={classes.vmCard}>
        <Text variant="subtitle1">Flavors</Text>
        <hr className={classes.divider} />
        <div className={classes.entries}>
          {flavors.map((flavor) => (
            <Fragment key={flavor.name}>
              <Text variant="body2">{flavor.count}</Text>
              <Text variant="body2">{flavor.name}</Text>
            </Fragment>
          ))}
        </div>
      </div>
      <div className={classes.vmCard}>
        <Text variant="subtitle1">Images</Text>
        <hr className={classes.divider} />
        <div className={classes.entries}>
          {images.map((image) => (
            <Fragment key={image.name}>
              <Text variant="body2">{image.count}</Text>
              <Text variant="body2">{image.name}</Text>
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  vmCards: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: 12,
    marginBottom: 12,
  },
  vmCard: {
    // background: theme.components.card.background,
    background: theme.palette.skyBlue[200],
    padding: '12px 24px 24px',
  },
  divider: {
    height: 1,
    // background: theme.components.card.border,
    background: theme.palette.skyBlue[400],
    border: 0,
  },
  entry: {
    display: 'grid',
    gridTemplateColumns: 'max-content auto',
    gap: 8,
  },
  entries: {
    display: 'grid',
    gridTemplateColumns: 'max-content auto',
    gap: '4px 12px',
  },
}))
