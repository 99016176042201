import React, { useCallback, useMemo, useState } from 'react'
import useParams from 'core/hooks/useParams'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createFloatingIp } from './actions'
import { listVirtualMachines } from 'openstack/components/vms/actions'
import ModalForm from 'core/elements/modal/ModalForm'
import { Route } from 'core/plugins/route'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import ExternalNetworkPicklist from '../routers/ExternalNetworkPicklist'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import PicklistField from 'core/components/validatedForm/DropdownField'
import VmPicklist from './VmPicklist'
import AvailableVmPortsPicklist from './AvailableVmPortsPicklist'
import useListAction from 'core/hooks/useListAction'

const useStyles = makeStyles<Theme>((theme) => ({}))

interface Props {
  addRoute: Route
}

export default function CreateFloatingIpModal({ addRoute }: Props) {
  const { history } = useReactRouter()
  const classes = useStyles()
  const defaultParams = {
    externalNetwork: '',
    assignFloatingIp: false,
    virtualMachine: '',
    port: '',
  }
  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  const { loading } = useListAction(listVirtualMachines, {})
  const { update, updating, error, reset } = useUpdateAction(createFloatingIp)

  const submitForm = useCallback(async () => {
    const body = {
      floatingip: {
        floating_network_id: params.externalNetwork,
        port_id: params.assignFloatingIp ? params.port : undefined,
      },
    }
    const { success, response } = await update({ body })
    if (success) {
      handleClose()
    }
  }, [params])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(routes.openstack.floatingIps.path())
  }

  return (
    <ModalForm
      route={addRoute}
      loading={loading}
      title={`Create Public IP`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      submitTitle={`Create Public IP`}
    >
      <>
        <FormFieldSection title="Public IP Configuration">
          <PicklistField
            DropdownComponent={ExternalNetworkPicklist}
            id="externalNetwork"
            label="External Network"
            tooltip="The external network to create a public IP on"
            onChange={getParamsUpdater('externalNetwork')}
            value={params.externalNetwork}
            required
          />
          <CheckboxField
            id="assignFloatingIp"
            label="Assign Public IP Address"
            info="Assign the public IP to a VM. You may also do this at a later time."
            value={params.assignFloatingIp}
            onChange={getParamsUpdater('assignFloatingIp')}
          />
          {!!params.assignFloatingIp && (
            <>
              <PicklistField
                DropdownComponent={VmPicklist}
                id="virtualMachine"
                label="VM"
                tooltip="The VM to attach the public IP to"
                onChange={(val) => {
                  updateParams({
                    virtualMachine: val,
                    port: '',
                  })
                }}
                value={params.virtualMachine}
                required
              />
              <PicklistField
                DropdownComponent={AvailableVmPortsPicklist}
                id="port"
                label="VM Port"
                tooltip="List of available ports on the selected VM"
                onChange={getParamsUpdater('port')}
                value={params.port}
                vmId={params.virtualMachine}
                required
              />
            </>
          )}
        </FormFieldSection>
      </>
    </ModalForm>
  )
}
