import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { virtualMachinesByIdSelector } from 'openstack/components/vms/selectors'

export const volumesSelector = createSharedSelector(
  getDataSelector<DataKeys.OpenstackVolumes>(DataKeys.OpenstackVolumes),
  virtualMachinesByIdSelector,
  (volumes, vmsById) => {
    return volumes.map((volume) => {
      // any model changes go here
      const vmAttachment = volume?.attachments?.length
        ? volume.attachments.find((attachment) => !!attachment.server_id)
        : null
      const vm = vmsById[vmAttachment?.server_id]
      return {
        ...volume,
        created_at: `${volume.created_at}Z`,
        attachedVm: vm,
        device: vmAttachment?.device,
      }
    })
  },
)

export const allVolumesSelector = createSharedSelector(
  getDataSelector<DataKeys.AllOpenstackVolumes>(DataKeys.AllOpenstackVolumes),
  virtualMachinesByIdSelector,
  (volumes, vmsById) => {
    return volumes.map((volume) => {
      // any model changes go here
      const vmAttachment = volume?.attachments?.length
        ? volume.attachments.find((attachment) => !!attachment.server_id)
        : null
      const vm = vmsById[vmAttachment?.server_id]
      return {
        ...volume,
        created_at: `${volume.created_at}Z`,
        attachedVm: vm,
        device: vmAttachment?.device,
      }
    })
  },
)
