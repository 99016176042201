import React, { useCallback, useEffect } from 'react'
import { styled } from '@material-ui/styles'
import Text from 'core/elements/Text'
import { extendVolume } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import useParams from 'core/hooks/useParams'
import TextField from 'core/components/validatedForm/TextField'
import DropdownField from 'core/components/validatedForm/DropdownField'
import Dropdown from 'core/elements/dropdown/Dropdown'

const Container = styled('div')({
  gap: 16,
  display: 'grid',
})

const Fields = styled('div')({
  display: 'grid',
  gridTemplateColumns: '2fr 1fr 1fr',
  gap: 16,
  alignItems: 'end',
})

export default function ExtendVolumeDialog({ rows: [volume], onClose }) {
  const { params, getParamsUpdater } = useParams({
    size: volume?.size,
    unit: 'GB',
  })
  const { update, updating, error, reset } = useUpdateAction(extendVolume)

  const handleSubmit = useCallback(async () => {
    const { success } = await update({
      volume,
      size: Number(params.size),
    })
    if (success) handleClose()
  }, [volume, params])

  const handleClose = () => {
    reset()
    onClose()
  }

  return (
    <ModalForm
      title="Extend Volume"
      onSubmit={handleSubmit}
      onClose={onClose}
      submitting={updating}
      error={error}
      submitTitle={`Extend Volume`}
      panel="dialog"
      open
    >
      <Container>
        <Text variant="body2">
          You can extend this volume to increase its capacity. This volume will retain all its data
        </Text>
        <Fields>
          <TextField
            id="Extend"
            label="Extend"
            onChange={getParamsUpdater('size')}
            value={params.size}
          />
          <DropdownField
            id={`sizeUnit`}
            DropdownComponent={Dropdown}
            items={[
              {
                value: 'GB',
                label: 'GB',
              },
            ]}
            value={params.unit}
            onChange={getParamsUpdater('unit')}
          />
        </Fields>
      </Container>
    </ModalForm>
  )
}
