import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

const options = [
  { label: 'None', value: '' },
  { label: 'bare', value: 'bare' },
  { label: 'ovf', value: 'ovf' },
  { label: 'ova', value: 'ova' },
]

interface Props {}

export default function ContainerFormatPicklist({
  name = 'containerFormat',
  label = 'Container Format',
  compact = false,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  return <AsyncDropdown compact={compact} name={name} label={label} items={options} {...rest} />
}
