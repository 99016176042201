import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import CreateAction from 'core/actions/CreateAction'
import DeleteAction from 'core/actions/DeleteAction'
import store from 'app/store'
import { cacheActions } from 'core/caching/cacheReducers'

const { dispatch } = store
const { heat } = ApiClient.getInstance()

export const stackActions = ActionsSet.make<DataKeys.Stacks>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.Stacks,
  cacheKey: DataKeys.Stacks,
})

export const listStacks = stackActions.add(
  new ListAction<DataKeys.Stacks>(async () => {
    return heat.getStacks()
  }),
)

export const createStack = stackActions.add(
  new CreateAction<DataKeys.Stacks, { body }>(async ({ body }) => {
    const created = await heat.createStack(body)
    const stackId = created.id
    const stackName = body.stack_name
    const newStack = await heat.getStack({ id: stackId, name: stackName })
    return newStack
  }),
)

export const deleteStack = stackActions.add(
  new DeleteAction<DataKeys.Stacks, { id: string; stack_name: string }>(
    async ({ id, stack_name }) => {
      await heat.deleteStack({ id, name: stack_name })
    },
  ),
)

export const getStack = async ({ id, name }) => {
  const stack = await heat.getStack({ id, name })
  return stack
}

// Todo: consider making an actions set for these and index by stack id
export const getStackResources = async ({ id, name }) => {
  const resources = await heat.getStackResources({ id, name })
  return resources
}
