import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useParams from 'core/hooks/useParams'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createFlavor, updateMetadata, addFlavorTenant } from './actions'
import ModalForm from 'core/elements/modal/ModalForm'
import { Route } from 'core/plugins/route'
import TextField from 'core/components/validatedForm/TextField'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import UnitPicklist from './UnitPicklist'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import KeyValuesField from 'core/components/validatedForm/KeyValuesField'
import Grid from 'core/elements/grid'
import { listResmgrHosts, listHostAggregates } from 'openstack/components/infrastructure/actions'
import { hostAggregatesSelector } from 'openstack/components/infrastructure/selectors'
import StatsCell from 'app/plugins/infrastructure/components/common/cells/StatsCell'
import { HostsCellComponent } from 'openstack/components/infrastructure/host-aggregates/HostAggregatesListPage'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { ArrayElement } from 'core/actions/Action'
import { createResourceLabelsCell } from 'k8s/components/common/entity/labels-and-annotations/helpers'
import Button from 'core/elements/button/Button'
import ToggleSwitch from 'core/elements/ToggleSwitch'
import { listTenants } from 'account/components/userManagement/tenants/new-actions'
import { tenantsSelector } from 'account/components/userManagement/tenants/selectors'
import ListTableField from 'core/components/validatedForm/ListTableField'

const useStyles = makeStyles<Theme>((theme) => ({
  withUnits: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  unitsDropdown: {
    position: 'relative',
    bottom: 1,
  },
  sameLine: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
  },
}))

const tenantColumns = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'description',
    label: 'Description',
  },
]

type SelectorModel = ArrayElement<ReturnType<typeof hostAggregatesSelector>>

interface Props {
  addRoute: Route
}

export default function CreateFlavorModal({ addRoute }: Props) {
  const { history } = useReactRouter()
  const classes = useStyles()
  const defaultParams = {
    name: '',
    // description: '',
    vcpus: 2,
    disk: 20,
    ram: 4,
    ramUnit: 'GiB',
    diskUnit: 'GiB',
    public: true,
    metadata: [],
    tenants: [],
  }
  const { params, getParamsUpdater, updateParams, setParams } = useParams(defaultParams)
  const [showMetadata, setShowMetadata] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [showAllHostAggregates, setShowAllHostAggregates] = useState(false)

  const { loading: loadingTenants } = useListAction(listTenants)
  const tenantsList = useSelector(tenantsSelector)

  useListAction(listResmgrHosts, { params })
  const { message, loading: loadingHostAggregates, reload } = useListAction(listHostAggregates, {
    params,
  })
  const hostAggregates = useSelector(hostAggregatesSelector)
  const filteredHostAggregates = useMemo(() => {
    if (!params.metadata.length || !params.metadata[0]?.key || !params.metadata[0]?.value) {
      return hostAggregates
    }
    return hostAggregates.filter((hostAggregate) => {
      return params.metadata.every((pair) => hostAggregate.metadata[pair.key] === pair.value)
    })
  }, [hostAggregates, params.metadata])

  const { update, updating, error, reset } = useUpdateAction(createFlavor)
  const { update: updateMetadataFn, reset: resetUpdateMetadata } = useUpdateAction(updateMetadata)
  const { update: addTenantFn, reset: resetAddTenant } = useUpdateAction(addFlavorTenant)

  const submitForm = useCallback(async () => {
    setSubmitting(true)
    const disk = params.diskUnit === 'TiB' ? params.disk * 1024 : params.disk
    const ram = params.ramUnit === 'GiB' ? params.ram * 1024 : params.ram
    const body = {
      flavor: {
        name: params.name,
        vcpus: params.vcpus,
        disk: disk,
        ram: ram,
        'os-flavor-access:is_public': params.public,
        // description: params.description,
      },
    }
    const { success, response } = await update({ body })
    if (!success) {
      setSubmitting(false)
      return
    }
    const flavorId = response.id
    if (!params.public) {
      const promises = params.tenants.map((tenant) => {
        const tenantBody = {
          addTenantAccess: {
            tenant: tenant.id,
          },
        }
        return addTenantFn({ id: flavorId, body: tenantBody })
      })
      await Promise.allSettled(promises)
    }
    if (params.metadata.length) {
      const metadata = params.metadata.reduce((accum, pair) => {
        return {
          ...accum,
          [pair.key]: pair.value,
        }
      }, {})
      const metadataBody = {
        addBody: {
          extra_specs: metadata,
        },
      }
      await updateMetadataFn({ id: flavorId, body: metadataBody })
    }
    handleClose()
  }, [params])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    resetUpdateMetadata()
    resetAddTenant()
    history.push(routes.openstack.flavors.path())
  }

  const handleMetadataChange = (cb) => {
    setShowMetadata(false)
    cb()
    // setShowMetadata(true)
  }

  useEffect(() => {
    if (!showMetadata) {
      setShowMetadata(true)
    }
  }, [showMetadata])

  const hostAggregateColumns: GridViewColumn<SelectorModel>[] = useMemo(
    () => [
      {
        key: 'name',
        label: 'Name',
        width: 'medium',
      },
      // {
      //   key: 'id',
      //   label: 'ID',
      //   display: false,
      // },
      // {
      //   key: 'availability_zone',
      //   label: 'Availability Zone',
      // },
      {
        key: 'hostInfo',
        label: 'Hosts',
        CellComponent: HostsCellComponent,
      },
      // {
      //   key: 'usage',
      //   label: 'Resource Utilization',
      //   CellComponent: StatsCell,
      // },
      {
        key: 'metadata',
        label: 'Metadata',
        disableSorting: true,
        CellComponent: createResourceLabelsCell({ type: 'table', separator: '=' }),
      },
      {
        key: 'id',
        label: '',
        CellComponent: ({ value, item }) => {
          const metadataKeys = Object.keys(item.metadata)
          const metadata = metadataKeys.map((key) => {
            return {
              key,
              value: item.metadata[key],
            }
          })
          return (
            <Button
              onClick={() => {
                handleMetadataChange(() => updateParams({ metadata }))
              }}
            >
              Assign Metadata
            </Button>
          )
        },
      },
    ],
    [params.metadata],
  )

  return (
    <ModalForm
      route={addRoute}
      title={`Create Flavor`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={submitting}
      error={error}
      submitTitle={`Create Flavor`}
      maxWidth={1200}
    >
      <>
        <FormFieldSection title="Flavor Configuration">
          <TextField
            id="name"
            label="Name"
            onChange={getParamsUpdater('name')}
            value={params.name}
            // info="Choose a name for your flavor"
            required
          />
          {/* Part of nova v2.55+ */}
          {/* <TextField
            id="description"
            label="Description"
            onChange={getParamsUpdater('description')}
            value={params.description}
          /> */}
          <TextField
            id="vcpus"
            label="vCPUs"
            // @ts-ignore complaint about string vs number
            onChange={getParamsUpdater('vcpus')}
            value={params.vcpus}
            type="number"
            min={1}
            // info="Number of vCPUs the flavor should have"
            required
          />
          <div className={classes.withUnits}>
            <TextField
              id="ram"
              label="RAM"
              // @ts-ignore complaint about string vs number
              onChange={getParamsUpdater('ram')}
              value={params.ram}
              type="number"
              min={1}
              // info="Amount of RAM the flavor should have"
              required
            />
            <UnitPicklist
              name="ramUnit"
              type="ram"
              value={params.ramUnit}
              onChange={getParamsUpdater('ramUnit')}
              className={classes.unitsDropdown}
            />
          </div>
          <div className={classes.withUnits}>
            <TextField
              id="disk"
              label="Disk"
              // @ts-ignore complaint about string vs number
              onChange={getParamsUpdater('disk')}
              value={params.disk}
              type="number"
              min={1}
              // info="Amount of disk storage the flavor should have"
              required
            />
            <UnitPicklist
              name="diskUnit"
              type="disk"
              value={params.diskUnit}
              onChange={getParamsUpdater('diskUnit')}
              className={classes.unitsDropdown}
            />
          </div>
        </FormFieldSection>
        <FormFieldSection title="Flavor Metadata">
          <Text variant="body2">
            You can restrict placement of VM instances created with this flavor to certain hosts.
          </Text>
          <Text variant="body2">
            Virtual machines created using this flavor will only be assigned to hosts whose metadata
            matches all of the flavor's metadata.
          </Text>
          {showMetadata && (
            <KeyValuesField
              id="metadata"
              label="Metadata"
              value={params.metadata}
              onChange={getParamsUpdater('metadata')}
              addLabel="Add Metadata"
              allowMultipleValues
            />
          )}
          <div className={classes.sameLine}>
            <Text variant="caption1">Matching Host Aggregates</Text>
            <ToggleSwitch
              active={showAllHostAggregates}
              onClick={(val) => setShowAllHostAggregates(val)}
              label="Show All"
            />
          </div>
          <Grid
            uniqueIdentifier="id"
            data={showAllHostAggregates ? hostAggregates : filteredHostAggregates}
            columns={hostAggregateColumns}
            loading={loadingHostAggregates}
            compact
            disableToolbar
          />
        </FormFieldSection>
        <FormFieldSection title="Tenant Access">
          <CheckboxField
            id="public"
            label="Make Public"
            value={params.public}
            onChange={getParamsUpdater('public')}
            info="If checked, flavor will be available for every tenant."
          />
          {!params.public && (
            <>
              <Text variant="body2">
                Select the tenants that should have access to this flavor.
              </Text>
              <ListTableField
                id="tenants"
                data={tenantsList}
                loading={loadingTenants}
                columns={tenantColumns}
                onChange={getParamsUpdater('tenants')}
                value={params.tenants}
                uniqueIdentifier="id"
                searchTargets={['name']}
                multiSelection
              />
            </>
          )}
        </FormFieldSection>
      </>
    </ModalForm>
  )
}
