import { makeStyles } from '@material-ui/styles'
import { listTablePrefs } from 'app/constants'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import Theme from 'core/themes/model'
import React, { useMemo } from 'react'
import { GridViewColumn } from 'core/elements/grid/Grid'
import ListContainer from 'core/containers/ListContainer'
import { pick, prop } from 'ramda'
import { listPorts, deletePort } from '../actions'
import { portsSelector, portsByNetworkId } from '../selectors'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import CreatePortModal from './CreatePortModal'
import EditPortModal from './EditPortModal'
import { routes } from 'core/utils/routes'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { RootState } from 'app/store'
import Text from 'core/elements/Text'

type ModelDataKey = DataKeys.OpenstackPorts
type SelectorModel = ArrayElement<ReturnType<typeof portsSelector>>

const useStyles = makeStyles<Theme>((theme) => ({
  ports: {
    marginTop: '16px',
  },
  addressPairs: {
    display: 'grid',
    gap: 8,
  },
  addressPair: {
    whiteSpace: 'nowrap',
  },
}))

const AddressPairsCell = ({ value }) => {
  const classes = useStyles()
  return (
    <div className={classes.addressPairs}>
      {value?.map((pair) => (
        <div className={classes.addressPair}>
          <Text variant="body2">IP Address: {pair?.ip_address}</Text>
          <Text variant="body2">MAC Address: {pair?.mac_address}</Text>
        </div>
      ))}
    </div>
  )
}

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
  },
  {
    key: 'id',
    label: 'UUID',
    display: false,
  },
  {
    key: 'fixed_ips',
    label: 'Private IPs',
    render: (value) => value.map((ip) => ip?.ip_address).join(', '),
  },
  {
    key: 'mac_address',
    label: 'MAC Address',
  },
  {
    key: 'binding:vif_type',
    label: 'VIF Type',
  },
  {
    key: 'device_owner',
    label: 'Attachment Type',
  },
  // {
  //   key: 'device',
  //   label: 'Attached Device'
  // },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'port_security_enabled',
    label: 'Port Security',
    render: (value) => (value ? 'Enabled' : 'Disabled'),
  },
  {
    key: 'securityGroups',
    label: 'Security Groups',
    render: (value) => value?.map((group) => group?.name || group?.id)?.join(', '),
  },
  {
    key: 'allowed_address_pairs',
    label: 'Allowed Address Pairs',
    CellComponent: AddressPairsCell,
  },
  {
    key: 'admin_state_up',
    label: 'Admin State',
    render: (value) => (value ? 'Up' : 'Down'),
  },
]

const usePrefParams = createUsePrefParamsHook('Ports', listTablePrefs)

export default function PortsPage({ network, loading, allowCrud = true }) {
  const classes = useStyles()
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const { features } = session
  const isVmware = features?.experimental?.pmov2_du_type === 'vmware'
  const { params, getParamsUpdater } = usePrefParams({})
  const isVirtualNetwork = network?.type === 'virtual'

  const { message, loading: loadingPorts, reload: reloadPorts } = useListAction(listPorts, {
    params,
  })
  const portsData = useSelector(portsByNetworkId)

  const networkPorts = useMemo(() => {
    return portsData[network.id] || []
  }, [portsData, network.id])

  const addRoute = isVmware
    ? routes.openstack.createVmwPort
    : isVirtualNetwork
    ? routes.openstack.createVirtualNetworkPort
    : routes.openstack.createPhysicalNetworkPort

  return (
    <article className={classes.ports}>
      <CreatePortModal addRoute={addRoute} isVirtualNetwork={isVirtualNetwork} />
      <ListContainer<ModelDataKey, SelectorModel>
        showBreadcrumbs={false}
        dataKey={DataKeys.OpenstackPorts}
        searchTargets={['name']}
        uniqueIdentifier="id"
        loading={loading || loadingPorts}
        loadingMessage={message}
        onRefresh={() => {
          reloadPorts(true, true)
        }}
        data={networkPorts}
        columns={columns}
        addUrl={allowCrud ? addRoute.path({ id: network?.id }) : undefined}
        addText={allowCrud ? 'Create Port' : undefined}
        EditDialogComponent={allowCrud ? EditPortModal : undefined}
        deleteAction={deletePort}
        getParamsUpdater={getParamsUpdater}
        {...pick(listTablePrefs, params)}
      />
    </article>
  )
}
