import React, { useCallback, useMemo } from 'react'
import useParams from 'core/hooks/useParams'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createSshKey } from './actions'
import ModalForm from 'core/elements/modal/ModalForm'
import { Route } from 'core/plugins/route'
import TextField from 'core/components/validatedForm/TextField'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

const useStyles = makeStyles<Theme>((theme) => ({
  info: {
    marginBottom: '12px',
  },
}))

interface Props {
  addRoute: Route
}

export default function ImportSshKeyModal({ addRoute }: Props) {
  const { history } = useReactRouter()
  const classes = useStyles()
  const defaultParams = {
    name: '',
    publicKey: '',
  }
  const { params, getParamsUpdater, setParams } = useParams(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(createSshKey)

  const submitForm = useCallback(async () => {
    const body = {
      keypair: {
        name: params.name,
        public_key: params.publicKey,
      },
    }
    const { success } = await update({ body })
    if (success) handleClose()
  }, [params])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(routes.openstack.sshKeys.path())
  }

  return (
    <ModalForm
      route={addRoute}
      title={`Import SSH Key`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      submitTitle={`Import SSH Key`}
    >
      <>
        {/* Perhaps need a tutorial on how to create an SSH key somewhere */}
        <div className={classes.info}>
          <Text variant="body2">
            A key pair allows you to use SSH to log into an instance after it is launched.
          </Text>
        </div>
        <FormFieldSection title="SSH Key Info">
          <TextField
            id="name"
            label="Name"
            onChange={getParamsUpdater('name')}
            value={params.name}
            required
          />
          <TextField
            id="publicKey"
            label="Public Key Content"
            onChange={getParamsUpdater('publicKey')}
            value={params.publicKey}
            info={'Public SSH keys are normally located at ~/.ssh/id_rsa.pub.'}
            required
          />
        </FormFieldSection>
      </>
    </ModalForm>
  )
}
