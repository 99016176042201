import React, { useMemo } from 'react'
import Dropdown from 'core/elements/dropdown'
import withProgress from 'core/components/progress/withProgress'
import useListAction from 'core/hooks/useListAction'
import { listVirtualMachines } from 'openstack/components/vms/actions'
import { useSelector } from 'react-redux'
import { virtualMachinesSelector } from 'openstack/components/vms/selectors'

const AsyncDropdown = withProgress(Dropdown, { inline: true })

export default function VmPicklist({
  disabled = false,
  name = 'virtualMachine',
  label = 'VM',
  ...rest
}) {
  const { loading } = useListAction(listVirtualMachines)
  const vms = useSelector(virtualMachinesSelector)

  const options = useMemo(() => {
    return vms.map((vm) => ({
      label: vm.name || vm.id,
      value: vm.id,
    }))
  }, [vms])

  return (
    <AsyncDropdown
      {...rest}
      name={name}
      label={label}
      compact={false}
      disabled={disabled}
      loading={loading}
      items={options}
    />
  )
}
