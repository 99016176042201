import React, { useCallback, useState } from 'react'
import Text from 'core/elements/Text'
import { removeRouterInterface } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import useListAction from 'core/hooks/useListAction'
import { listPorts } from '../actions'
import useReactRouter from 'use-react-router'

export default function DeleteRouterInterfaceDialog({ rows: [portInterface], onClose }) {
  const { match } = useReactRouter()
  const { id } = match.params
  const { update, updating, error, reset } = useUpdateAction(removeRouterInterface)
  const { reload } = useListAction(listPorts, {})

  const handleSubmit = useCallback(() => {
    const asyncCalls = async () => {
      const body = {
        subnet_id: portInterface?.fixed_ips?.[0]?.subnet_id,
      }
      const { success } = await update({ id, body })
      if (success) {
        handleClose()
      }
    }
    asyncCalls()
  }, [portInterface, id])

  const handleClose = () => {
    reset()
    reload(true, true)
    onClose()
  }

  return (
    <ModalForm
      title="Delete Router Interface"
      onSubmit={handleSubmit}
      onClose={onClose}
      loading={false}
      submitting={updating}
      error={error}
      submitTitle={`Delete Router Interface`}
      panel="dialog"
      // maxWidth={1200}
      open
    >
      <Text variant="body2">
        You are about to remove the selected router interface from the router.
      </Text>
    </ModalForm>
  )
}
