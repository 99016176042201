import React, { useMemo } from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

interface Props {
  switches: string[]
}

export default function ImageLibraryAccessPicklist({
  name = 'dvs',
  label = 'Distributed Virtual Switch',
  compact = false,
  switches,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const options = useMemo(() => {
    return switches.map((ip) => {
      return { label: ip, value: ip }
    })
  }, [switches])
  return <AsyncDropdown compact={compact} name={name} label={label} items={options} {...rest} />
}
