export const humanReadableSize = (bytes) => {
  if (bytes === 0) {
    return '0.00 B'
  }
  if (!bytes) {
    return 'N/A'
  }

  const e = Math.floor(Math.log(bytes) / Math.log(1024))
  return (bytes / Math.pow(1024, e)).toFixed(2) + ' ' + ' KMGTP'.charAt(e) + 'iB'
}
