import React from 'react'
import DropdownField from 'core/components/validatedForm/DropdownField'
import Dropdown from 'core/elements/dropdown/Dropdown'
import { IPVersions } from 'app/constants'

export enum IPV6_ADDRESS_CONFIG_MODES {
  SLAAC_OPENSTACK_ROUTER = 'slaac-openstack-router',
  SLAAC_EXT_ROUTER = 'slaac-ext-router',
  DHCPV6_STATEFUL = 'dhcpv6-stateful',
  DHCPV6_STATELESS = 'dhcpv6-stateless',
}

export const IPV6_ADDRESS_CONFIG_OPTIONS = [
  { label: 'No options specified (Default)', value: '' },
  {
    label: 'SLAAC: Address discovered from OpenStack Router',
    value: IPV6_ADDRESS_CONFIG_MODES.SLAAC_OPENSTACK_ROUTER,
  },
  {
    label: 'SLAAC: Address discovered from an external router',
    value: IPV6_ADDRESS_CONFIG_MODES.SLAAC_EXT_ROUTER,
  },
  {
    label: 'DHCPv6 Stateful: Address discovered from OpenStack DHCP',
    value: IPV6_ADDRESS_CONFIG_MODES.DHCPV6_STATEFUL,
  },
  {
    label:
      'DHCPv6 Stateless: Address discovered from OpenStack Router and additional information from OpenStack DHCP',
    value: IPV6_ADDRESS_CONFIG_MODES.DHCPV6_STATELESS,
  },
]

export const getIPv6AdditionalFields = ({ ipVersion, ipv6AddressConfigurationMode }) => {
  if (ipVersion === IPVersions.IPv4) {
    return {}
  }

  switch (ipv6AddressConfigurationMode) {
    case IPV6_ADDRESS_CONFIG_MODES.SLAAC_OPENSTACK_ROUTER:
      return {
        ipv6_ra_mode: 'slaac',
        ipv6_address_mode: 'slaac',
      }
    case IPV6_ADDRESS_CONFIG_MODES.SLAAC_EXT_ROUTER:
      return {
        ipv6_address_mode: 'slaac',
      }
    case IPV6_ADDRESS_CONFIG_MODES.DHCPV6_STATEFUL:
      return {
        ipv6_ra_mode: 'dhcpv6-stateful',
        ipv6_address_mode: 'dhcpv6-stateful',
      }
    case IPV6_ADDRESS_CONFIG_MODES.DHCPV6_STATELESS:
      return {
        ipv6_ra_mode: 'dhcpv6-stateless',
        ipv6_address_mode: 'dhcpv6-stateless',
      }
    default:
      return {}
  }
}

export const getIPv6AddressConfigurationMode = (subnet) => {
  if (subnet?.ipVersion === IPVersions.IPv4) {
    return ''
  }

  if (subnet?.ipv6_ra_mode === 'slaac' && subnet?.ipv6_address_mode === 'slaac') {
    return IPV6_ADDRESS_CONFIG_MODES.SLAAC_OPENSTACK_ROUTER
  }

  if (subnet?.ipv6_address_mode === 'slaac') {
    return IPV6_ADDRESS_CONFIG_MODES.SLAAC_EXT_ROUTER
  }

  if (
    subnet?.ipv6_address_mode === 'dhcpv6-stateful' &&
    subnet?.ipv6_ra_mode === 'dhcpv6-stateful'
  ) {
    return IPV6_ADDRESS_CONFIG_MODES.DHCPV6_STATEFUL
  }

  if (
    subnet?.ipv6_address_mode === 'dhcpv6-stateless' &&
    subnet?.ipv6_ra_mode === 'dhcpv6-stateless'
  ) {
    return IPV6_ADDRESS_CONFIG_MODES.DHCPV6_STATELESS
  }

  return ''
}

export default function IPv6AddressConfigurationModePicklist({ params, getParamsUpdater }) {
  if (params.ipVersion === IPVersions.IPv4) return null

  return (
    <DropdownField
      id="ipVersion"
      label="IPv6 Address Configuration Mode"
      DropdownComponent={Dropdown}
      items={IPV6_ADDRESS_CONFIG_OPTIONS}
      onChange={getParamsUpdater('ipv6AddressConfigurationMode')}
      value={params.ipv6AddressConfigurationMode}
      info="Specifies how IPv6 addresses and additional information are configured. We can specify SLAAC/ DHCPv6 stateful/ DHCPv6 stateless provided by OpenStack, or specify no option. 'No options specified' means addresses are configured manually or configured by a non-OpenStack system."
    />
  )
}
