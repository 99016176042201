import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useParams from 'core/hooks/useParams'
import ModalForm from 'core/elements/modal/ModalForm'
import { updateSecurityGroup, createSecurityGroupRules, deleteSecurityGroupRules } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import TextField from 'core/components/validatedForm/TextField'
import SecurityGroupRulesParamFields from './SecurityGroupRulesParamFields'
import ExistingRulesParamFields from './ExistingRulesParamFields'
import { getRuleJson } from './helpers'
import { customValidator } from 'core/utils/fieldValidators'

export default function EditSecurityGroupModal({ rows: [securityGroup], onClose }) {
  const [loaded, setLoaded] = useState(false)
  const defaultParams = {}

  const { params, getParamsUpdater, updateParams, setParams } = useParams<{
    name?: string
    description?: string
    currentRules?: any[]
    rulesToDelete?: string[]
    outboundSecurityGroupRules?: any[]
    inboundSecurityGroupRules?: any[]
  }>(defaultParams)

  const nameValidator = useMemo(() => {
    return customValidator((value) => {
      if (!value) {
        return true
      }
      return !(securityGroup.name !== 'default' && value === 'default')
    }, 'You may not rename a securiy group to default. Please use another name.')
  }, [securityGroup])

  const { update, updating, error, reset } = useUpdateAction(updateSecurityGroup)
  const {
    update: createRules,
    updating: updatingRules,
    error: createRulesError,
    reset: createRulesReset,
  } = useUpdateAction(createSecurityGroupRules)
  const {
    update: deleteRules,
    updating: deletingRules,
    error: deleteRulesError,
    reset: deleteRulesReset,
  } = useUpdateAction(deleteSecurityGroupRules)

  useEffect(() => {
    // Prevent resetting of form from background reloads
    if (loaded) {
      return
    }
    updateParams({
      name: securityGroup.name,
      description: securityGroup.description,
      currentRules: securityGroup.security_group_rules,
      rulesToDelete: [],
      outboundSecurityGroupRules: [],
      inboundSecurityGroupRules: [],
    })
    setLoaded(true)
  }, [securityGroup, loaded])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    createRulesReset()
    deleteRulesReset()
    onClose()
  }

  const submitForm = useCallback(async () => {
    const body = {
      security_group: {
        name: params.name,
        description: params.description,
      },
    }
    const { success, response } =
      securityGroup?.name === 'default'
        ? { success: true, response: { id: securityGroup?.id } }
        : await update({ id: securityGroup.id, body })
    if (success) {
      const securityGroupId = response?.id
      if (params.outboundSecurityGroupRules.length || params.inboundSecurityGroupRules.length) {
        const outboundRuleJsons = params.outboundSecurityGroupRules.map((rule) =>
          getRuleJson({ rule, direction: 'egress', securityGroupId }),
        )
        const inboundRuleJsons = params.inboundSecurityGroupRules.map((rule) =>
          getRuleJson({ rule, direction: 'ingress', securityGroupId }),
        )
        const rulesBody = {
          security_group_rules: [...outboundRuleJsons, ...inboundRuleJsons],
        }
        await createRules({ id: securityGroupId, body: rulesBody })
      }
      if (params.rulesToDelete.length) {
        await deleteRules({ id: securityGroupId, ruleIds: params.rulesToDelete })
      }
      handleClose()
    }
  }, [securityGroup, params])

  return (
    <ModalForm
      open
      title={`Edit Security Group`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating || updatingRules || deletingRules}
      error={error || createRulesError || deleteRulesError}
      submitTitle={`Update Security Group`}
      loading={!loaded}
      maxWidth={1100}
    >
      <FormFieldSection title="Basic Fields">
        <TextField
          id="name"
          label="Name"
          onChange={getParamsUpdater('name')}
          value={params.name}
          validations={[nameValidator]}
          disabled={securityGroup?.name === 'default'}
          required
        />
        <TextField
          id="description"
          label="Description"
          onChange={getParamsUpdater('description')}
          disabled={securityGroup?.name === 'default'}
          value={params.description}
        />
      </FormFieldSection>
      <FormFieldSection title="Existing Security Group Rules">
        <ExistingRulesParamFields
          params={params}
          getParamsUpdater={getParamsUpdater}
          updateParams={updateParams}
        />
      </FormFieldSection>
      <FormFieldSection title="Add Outbound Security Group Rules">
        <SecurityGroupRulesParamFields
          params={params}
          getParamsUpdater={getParamsUpdater}
          updateParams={updateParams}
          direction="outbound"
        />
      </FormFieldSection>
      <FormFieldSection title="Add Inbound Security Group Rules">
        <SecurityGroupRulesParamFields
          params={params}
          getParamsUpdater={getParamsUpdater}
          updateParams={updateParams}
          direction="inbound"
        />
      </FormFieldSection>
    </ModalForm>
  )
}
