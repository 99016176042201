import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

const ramOptions = [
  { label: 'MiB', value: 'MiB' },
  { label: 'GiB', value: 'GiB' },
]

const diskOptions = [
  { label: 'GiB', value: 'GiB' },
  { label: 'TiB', value: 'TiB' },
]

interface Props {
  type: string
}

export default function UnitPicklist({
  name = 'unit',
  label = 'Unit',
  compact = false,
  type,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const options = type === 'ram' ? ramOptions : diskOptions
  return <AsyncDropdown compact={compact} name={name} label={label} items={options} {...rest} />
}
