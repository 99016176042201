import React from 'react'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import TextField from 'core/components/validatedForm/TextField'
import DropdownField from 'core/components/validatedForm/DropdownField'
import IpVersionPicklist from './IpVersionPicklist'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import { customValidator } from 'core/utils/fieldValidators'
import {
  isValidIpv4Address,
  isValidIpv6Address,
  isValidIpv4Cidr,
  isValidIpv6Cidr,
} from 'app/plugins/infrastructure/components/clusters/form-components/validators'
import * as IpAddress from 'ip-address'
import AllocationPoolsField from './AllocationPoolsField'
import DnsNameServersField from './DnsNameServersField'
import HostRoutesField from './HostRoutesField'
import IPv6AddressConfigurationModePicklist from './IPv6AddressConfigurationModePicklist'
import { IPVersions } from 'app/constants'

export const cidrValidator = customValidator((value, formValues) => {
  if (!value) {
    return false
  }
  return formValues.ipVersion === IPVersions.IPv4 ? isValidIpv4Cidr(value) : isValidIpv6Cidr(value)
}, 'Invalid CIDR provided')

export const gatewayInCidrValidator = customValidator((value, formValues) => {
  if (!value) {
    return true
  }
  try {
    const testCidr =
      formValues.ipVersion === IPVersions.IPv4
        ? new IpAddress.Address4(formValues.cidr)
        : new IpAddress.Address6(formValues.cidr)
    return formValues.ipVersion === IPVersions.IPv4
      ? new IpAddress.Address4(value).isInSubnet(testCidr)
      : new IpAddress.Address6(value).isInSubnet(testCidr)
  } catch (err) {
    return false
  }
}, 'Gateway IP address is not inside of designated CIDR')

export const gatewayIpValid = customValidator((value, formValues) => {
  if (!value) {
    return true
  }
  return formValues.ipVersion === IPVersions.IPv4
    ? isValidIpv4Address(value)
    : isValidIpv6Address(value)
}, 'Gateway IP address is an invalid IP address')

export function BasicSubnetFields({
  inNetworkCreation = false,
  inSubnetEdit = false,
  params,
  getParamsUpdater,
}) {
  return (
    <FormFieldSection title="Subnet Configuration">
      <TextField
        id={inNetworkCreation ? 'subnetName' : 'name'}
        label="Name"
        onChange={getParamsUpdater(inNetworkCreation ? 'subnetName' : 'name')}
        value={inNetworkCreation ? params.subnetName : params.name}
        required
      />
      {!inSubnetEdit && (
        <DropdownField
          DropdownComponent={IpVersionPicklist}
          id="ipVersion"
          label="IP Version"
          onChange={getParamsUpdater('ipVersion')}
          value={params.ipVersion}
          required
        />
      )}
      <TextField
        id="cidr"
        label="Network Address (CIDR)"
        onChange={getParamsUpdater('cidr')}
        value={params.cidr}
        info="Specify your network CIDR used to designate your IP address pool (e.g., 192.0.2.0/24)."
        required
        validations={[cidrValidator]}
        disabled={inSubnetEdit}
      />
      <TextField
        id="gatewayIp"
        label="Gateway IP"
        onChange={getParamsUpdater('gatewayIp')}
        info="Specify the IP address used for the network gateway. If left blank, the first available IP address within your network CIDR will be automatically chosen."
        value={params.gatewayIp}
        validations={[gatewayIpValid, gatewayInCidrValidator]}
      />
      <CheckboxField
        id="disableGateway"
        label="Disable Gateway"
        value={params.disableGateway}
        onChange={getParamsUpdater('disableGateway')}
        info="By checking this option, no network gateway will be created. You may choose to add one later in the subnet edit form."
      />
      <CheckboxField
        id="enableDhcp"
        label="Enable DHCP"
        value={params.enableDhcp}
        onChange={getParamsUpdater('enableDhcp')}
        info="Check to enable DHCP on this network."
      />
      <IPv6AddressConfigurationModePicklist params={params} getParamsUpdater={getParamsUpdater} />
    </FormFieldSection>
  )
}

export function AdvancedSubnetFields({ params, updateParams }) {
  return (
    <FormFieldSection title="Subnet Details">
      <AllocationPoolsField params={params} updateParams={updateParams} />
      <DnsNameServersField params={params} updateParams={updateParams} />
      <HostRoutesField params={params} updateParams={updateParams} />
    </FormFieldSection>
  )
}
