import React from 'react'
import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import clsx from 'clsx'
import Tooltip from 'core/elements/tooltip'
import { IVirtualMachinesSelector } from './model'

const getIconOrBubbleColor = (status, theme: Theme) =>
  ({
    active: theme.palette.green.main,
    paused: theme.palette.yellow.main,
    stopped: theme.palette.red.main,
    error: theme.palette.red.main,
    suspended: theme.palette.yellow.main,
    building: theme.palette.green.main,
  }[status] || theme.palette.red.main)

interface VmStateCellComponentProps extends GridCellProps<IVirtualMachinesSelector> {
  state: string
  setVMError?: (value: string) => void
}

const useStyles = makeStyles<Theme, VmStateCellComponentProps>((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    cursor: ({ state }) => (state && state.toLowerCase() === 'error' ? 'pointer' : 'default'),
  },
  icon: {
    marginRight: 5,
  },
  circle: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'minmax(22px, max-content) 1fr',
    whiteSpace: 'nowrap',
    gridGap: 5,
    justifyItems: 'center',
    '&:before': {
      content: '""',
      display: 'inherit',
      height: 12,
      width: 12,
      borderRadius: '50%',
      backgroundColor: ({ state }) => getIconOrBubbleColor(state, theme),
    },
  },
  error: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
}))

// const iconMap = new Map<
//   IClusterStatus | ApiServerHealthStatus | 'degraded',
//   { icon: string; classes: string }
// >([
//   ['fail', { icon: 'times', classes: '' }],
//   ['ok', { icon: 'check', classes: '' }],
//   ['pause', { icon: 'pause-circle', classes: '' }],
//   ['unknown', { icon: 'question-circle', classes: '' }],
//   ['error', { icon: 'exclamation-triangle', classes: '' }],
//   ['loading', { icon: 'sync', classes: 'fa-spin' }],
//   ['upgrade', { icon: 'arrow-circle-up', classes: '' }],
//   ['degraded', { icon: 'check', classes: '' }],
//   ['pending', { icon: 'pause-circle', classes: '' }],
// ])

const noCircleStates = ['resized']

export default function VmStateCellComponent({
  item: { state, taskState, pausedPowerState, fault },
  setVMError,
}: VmStateCellComponentProps) {
  const classes = useStyles({ state })
  const isErrorState = state && state.toLowerCase() === 'error'

  return (
    <div className={classes.root}>
      {taskState && (
        <FontAwesomeIcon className={classes.icon} spin>
          sync
        </FontAwesomeIcon>
      )}
      {!taskState && state === 'resized' && (
        <Tooltip message="Resize confirmation action required">
          <FontAwesomeIcon className={classes.icon}>circle-exclamation</FontAwesomeIcon>
        </Tooltip>
      )}
      <Text
        className={clsx({
          [classes.circle]: !taskState && !noCircleStates.includes(state),
          [classes.error]: isErrorState,
        })}
        variant={'body2'}
        component="div"
        onClick={() => {
          if (isErrorState) setVMError(fault?.message)
        }}
      >
        {state}
        {pausedPowerState && ' (paused)'}
        {taskState && ` (${taskState})`}
      </Text>
    </div>
  )
}
