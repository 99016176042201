import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { externalNetworksSelector } from '../selectors'

export const routersSelector = createSharedSelector(
  getDataSelector<DataKeys.OpenstackRouters>(DataKeys.OpenstackRouters),
  externalNetworksSelector,
  (routers, externalNetworks) => {
    return routers.map((router) => {
      const externalNetwork = externalNetworks.find(
        (network) => router?.external_gateway_info?.network_id === network.id,
      )
      // any model changes go here
      return {
        ...router,
        externalNetwork: externalNetwork?.name || externalNetwork?.id,
      }
    })
  },
)
