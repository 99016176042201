import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import { detachVolume } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'

const useStyles = makeStyles<Theme>((theme) => ({
  fields: {
    gap: 16,
    display: 'grid',
  },
}))

export default function DetachVolumeDialog({ rows: [volume], onClose }) {
  const classes = useStyles()

  const { update, updating, error, reset } = useUpdateAction(detachVolume)

  const handleSubmit = useCallback(async () => {
    const { success } = await update({ volumeId: volume.id, vmId: volume.attachedVm?.id })
    if (success) handleClose()
  }, [volume])

  const handleClose = () => {
    reset()
    onClose()
  }

  return (
    <ModalForm
      title="Detach Volume"
      onSubmit={handleSubmit}
      onClose={onClose}
      submitting={updating}
      error={error}
      submitTitle={`Detach Volume`}
      panel="dialog"
      // maxWidth={1200}
      open
    >
      <div className={classes.fields}>
        <Text variant="body2">
          You are about to detach the volume <b>{volume.name || volume.id}</b> from the VM{' '}
          <b>{volume.attachedVm?.name || volume.attachedVm?.id}</b>.
        </Text>
        {volume.attachedVm?.['OS-EXT-STS:vm_state'] === 'active' && (
          <Text variant="body2">
            <b>WARNING</b>: The virtual machine instance this volume is attached to is currently
            active. To avoid risks, make sure that the guest operating system is not making any
            active changes to this volume before detaching it.
          </Text>
        )}
      </div>
    </ModalForm>
  )
}
