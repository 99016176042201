import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import { uploadVolumeAsImage } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import TextField from 'core/components/validatedForm/TextField'
import useParams from 'core/hooks/useParams'
import CheckboxField from 'core/components/validatedForm/CheckboxField'

const useStyles = makeStyles<Theme>((theme) => ({
  fields: {
    gap: 16,
    display: 'grid',
  },
}))

export default function UploadVolumeAsImageDialog({ rows: [volume], onClose }) {
  const classes = useStyles()

  const defaultParams = {
    name: '',
    force: false,
  }
  const { params, getParamsUpdater, setParams } = useParams(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(uploadVolumeAsImage)

  const handleSubmit = useCallback(async () => {
    if (volume.status === 'in-use' && !params.force) {
      return
    }
    const body = {
      'os-volume_upload_image': {
        image_name: params.name,
        force: params.force,
        disk_format: 'raw',
        container_format: 'bare',
      },
    }
    const { success, response } = await update({ id: volume.id, body })
    // Todo
    // Need to refresh images list maybe as well as volumes list?
    if (success) handleClose()
  }, [params, volume])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    onClose()
  }

  return (
    <ModalForm
      title="Upload Volume as Image"
      onSubmit={handleSubmit}
      onClose={onClose}
      submitting={updating}
      error={error}
      submitTitle={`Upload Volume`}
      panel="dialog"
      disableSubmit={volume.status === 'in-use' && !params.force}
      // maxWidth={1200}
      open
    >
      <div className={classes.fields}>
        <Text variant="body2">
          Create a new VM image using volume <b>{volume.name || volume.id}</b> as its source.
        </Text>
        <TextField
          id="name"
          label="Name"
          onChange={getParamsUpdater('name')}
          value={params.name}
          required
        />
        {volume.status === 'in-use' && (
          <Text variant="body2">
            To upload an in-use volume as an image, you must check the force option. Uploading this
            volume while it is in use may cause unforeseen consequences.
          </Text>
        )}
        <CheckboxField
          id="force"
          label="Force Upload"
          value={params.force}
          onChange={getParamsUpdater('force')}
        />
      </div>
    </ModalForm>
  )
}
