import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import {
  updateHypervisorRole,
  updateNeutronBaseRole,
  updateNeutronRoles,
  getResmgrServiceConfig,
  updateCeilometerRole,
} from '../actions'
import ModalForm from 'core/elements/modal/ModalForm'

const useStyles = makeStyles<Theme>((theme) => ({
  fields: {
    gap: 16,
    display: 'grid',
  },
}))

export default function FixMissingRolesDialog({ host, handleClose }) {
  const classes = useStyles()
  const [loaded, setLoaded] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [settings, setSettings] = useState({ neutronServer: {}, blueprint: {} })

  useEffect(() => {
    const querySettings = async () => {
      const neutronServerSettings = await getResmgrServiceConfig('neutron-server')
      const blueprintSettings = await getResmgrServiceConfig('blueprint')
      setSettings({
        neutronServer: neutronServerSettings,
        blueprint: blueprintSettings,
      })
      setLoaded(true)
    }
    querySettings()
  }, [])

  const handleSubmit = useCallback(async () => {
    setSubmitting(true)
    await updateNeutronBaseRole({ host })
    await updateHypervisorRole({ host, settings })
    await updateNeutronRoles({ host, settings })
    await updateCeilometerRole({ host, settings })
    setSubmitting(false)
    handleClose()
  }, [host, settings])

  return (
    <ModalForm
      title="Missing Packages"
      onSubmit={handleSubmit}
      onClose={handleClose}
      loading={!loaded}
      submitting={submitting}
      submitTitle={`Install Missing Packages`}
      panel="dialog"
      // maxWidth={1200}
      open
    >
      <div className={classes.fields}>
        <Text variant="body2">
          The Hypervisor role was not installed properly on host <b>{host.name}</b>. Confirm to
          attempt to install the missing packages.
        </Text>
      </div>
    </ModalForm>
  )
}
