import React, { useCallback, useState } from 'react'
import Text from 'core/elements/Text'
import { updateFloatingIp } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import { waitSeconds } from 'openstack/components/infrastructure/blueprint/helpers'
import { refreshVirtualMachine } from 'openstack/components/vms/actions'

export default function DetachFloatingIpDialog({ rows: [floatingIp], onClose }) {
  const [submitting, setSubmitting] = useState(false)
  const { update, updating, error, reset } = useUpdateAction(updateFloatingIp)
  const { update: refreshVm } = useUpdateAction(refreshVirtualMachine)

  const handleSubmit = useCallback(() => {
    setSubmitting(true)
    const asyncCalls = async () => {
      const body = {
        floatingip: {
          port_id: null,
        },
      }
      const { success } = await update({ id: floatingIp.id, body })
      await waitSeconds(2)
      await refreshVm({ id: floatingIp?.allocation?.id })
      setSubmitting(false)
      if (success) handleClose()
    }
    asyncCalls()
  }, [floatingIp])

  const handleClose = () => {
    reset()
    onClose()
  }

  return (
    <ModalForm
      title="Detach Public IP"
      onSubmit={handleSubmit}
      onClose={onClose}
      loading={submitting}
      submitting={submitting}
      error={error}
      submitTitle={`Detach public IP`}
      panel="dialog"
      // maxWidth={1200}
      open
    >
      <Text variant="body2">
        Detach public IP {floatingIp.floating_ip_address} from{' '}
        {floatingIp?.allocation?.name || 'VM'}.
      </Text>
    </ModalForm>
  )
}
