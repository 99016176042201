import React, { useMemo } from 'react'
import Dropdown from 'core/elements/dropdown'
import withProgress from 'core/components/progress/withProgress'
import useListAction from 'core/hooks/useListAction'
import { listFloatingIps } from './actions'
import { useSelector } from 'react-redux'
import { floatingIpsSelector } from './selectors'

const AsyncDropdown = withProgress(Dropdown, { inline: true })

export default function AvailableFloatingIpsPicklist({
  disabled = false,
  name = 'floatingIp',
  label = 'Public IP',
  ...rest
}) {
  const { loading } = useListAction(listFloatingIps)
  const floatingIps = useSelector(floatingIpsSelector)

  const availableFloatingIps = useMemo(() => {
    return floatingIps.filter((ip) => !ip.port_id)
  }, [floatingIps])

  const options = useMemo(() => {
    return availableFloatingIps.map((ip) => ({
      label: `${ip.network?.name || ip.floating_network_id}: ${ip.floating_ip_address}`,
      value: ip.id,
    }))
  }, [availableFloatingIps])

  return (
    <AsyncDropdown
      {...rest}
      name={name}
      label={label}
      compact={false}
      disabled={disabled}
      loading={loading}
      items={options}
    />
  )
}
