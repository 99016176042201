import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

const options = [
  { label: 'Up', value: 'Up' },
  { label: 'Down', value: 'Down' },
]

interface Props {}

export default function AdminStatePicklist({
  name = 'adminState',
  label = 'Admin State',
  compact = false,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  return <AsyncDropdown compact={compact} name={name} label={label} items={options} {...rest} />
}
