import React, { useCallback } from 'react'
import useReactRouter from 'use-react-router'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import { routes } from 'core/utils/routes'
import { deleteVirtualMachine } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import Modal from 'core/elements/modal'

export default function DeleteVirtualMachineDialog({ rows, onClose }) {
  const { history } = useReactRouter()
  const { update: deleteFn, updating: deletingVm } = useUpdateAction(deleteVirtualMachine)
  const vm = rows[0]
  const title = `Delete "${vm?.name}"?`
  const handleDelete = useCallback(async () => {
    await deleteFn(vm)
    onClose(true)
  }, [vm])

  return (
    <Modal
      panel="dialog"
      open
      onClose={onClose}
      title={title}
      maxWidth={600}
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={deletingVm}>
            Cancel
          </Button>
          <Button onClick={handleDelete} loading={deletingVm}>
            Yes, delete
          </Button>
        </>
      }
    >
      <Text variant="body2">You are about to delete this VM instance.</Text>
    </Modal>
  )
}
