import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

const options = [
  { label: 'Image', value: 'image' },
  { label: 'New Volume', value: 'newVolume' },
  { label: 'Existing Volume', value: 'existingVolume' },
]

interface Props {}

export default function SourceTypesPicklist({
  name = 'vmSourceType',
  label = 'Boot VM From',
  compact = false,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  return <AsyncDropdown compact={compact} name={name} label={label} items={options} {...rest} />
}
