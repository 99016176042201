import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'

export const volumeSnapshotsSelector = createSharedSelector(
  getDataSelector<DataKeys.OpenstackVolumeSnapshots>(DataKeys.OpenstackVolumeSnapshots),
  (volumeSnapshots) => {
    return volumeSnapshots.map((volumeSnapshot) => {
      // any model changes go here
      return {
        ...volumeSnapshot,
        created_at: `${volumeSnapshot.created_at}Z`,
      }
    })
  },
)
