export const getHostUsageTotals = (hosts = []) => {
  const usageTotals = hosts.reduce(
    (accum, host) => {
      return {
        compute: {
          current: accum.compute.current + (host.usage?.compute.current || 0),
          max: accum.compute.max + (host.usage?.compute.max || 0),
          units: 'GHz',
        },
        disk: {
          current: accum.disk.current + (host.usage?.disk.current || 0),
          max: accum.disk.max + (host.usage?.disk.max || 0),
          units: 'GiB',
        },
        memory: {
          current: accum.memory.current + (host.usage?.memory.current || 0),
          max: accum.memory.max + (host.usage?.memory.max || 0),
          units: 'GiB',
        },
      }
    },
    {
      compute: {
        current: 0,
        max: 0,
        units: 'GHz',
      },
      disk: {
        current: 0,
        max: 0,
        units: 'GiB',
      },
      memory: {
        current: 0,
        max: 0,
        units: 'GiB',
      },
    },
  )
  return {
    compute: {
      ...usageTotals.compute,
      percent: (usageTotals.compute.current / usageTotals.compute.max) * 100,
    },
    disk: {
      ...usageTotals.disk,
      percent: (usageTotals.disk.current / usageTotals.disk.max) * 100,
    },
    memory: {
      ...usageTotals.memory,
      percent: (usageTotals.memory.current / usageTotals.memory.max) * 100,
    },
  }
}
