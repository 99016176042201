import React, { useMemo } from 'react'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { listRouters, deleteRouter } from './actions'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { routersSelector } from './selectors'
import { SortingState } from 'core/elements/grid/hooks/useGridSorting'
import InferActionParams from 'core/actions/InferActionParams'
import { useAppSelector } from 'app/store'
import useReactRouter from 'use-react-router'
import { durationBetweenDates } from 'utils/misc'
import Text from 'core/elements/Text'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import { routes } from 'core/utils/routes'
import CreateRouterModal from './CreateRouterModal'
import EditRouterModal from './EditRouterModal'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { IRouterDetailsPageTabs } from './model'
import { GridBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import DeleteRouterDialog from './DeleteRouterDialog'

type ModelDataKey = DataKeys.OpenstackRouters
type SelectorModel = ArrayElement<ReturnType<typeof routersSelector>>
type ActionParams = InferActionParams<typeof listRouters>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {}

const defaultParams: Params = {
  orderBy: 'name',
  orderDirection: 'asc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'OpenstackRouters',
  listTablePrefs,
)

const searchTargets = ['name', 'id']

const routerColumns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: createGridLinkCell({
      routeToFn: ({ id }) =>
        routes.openstack.routerDetails.path({ id, tab: IRouterDetailsPageTabs.Overview }),
    }),
  },
  {
    key: 'id',
    label: 'UUID',
    display: false,
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'admin_state_up',
    label: 'Admin State',
    render: (value) => (value ? 'Up' : 'Down'),
  },
  {
    key: 'externalNetwork',
    label: 'External network',
  },
]

export default function RoutersListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { history } = useReactRouter()

  const { prefs } = useScopedPreferences()
  const { currentTenant } = prefs

  const { message, loading, reload } = useListAction(listRouters, {
    params,
  })
  const data = useAppSelector(routersSelector)

  const tenantRouters = useMemo(() => {
    return data.filter((router) => {
      return router.project_id === currentTenant
    })
  }, [data, currentTenant])

  const batchActions: GridBatchActionSpec<SelectorModel>[] = [
    {
      cond: (router) => {
        return !!router
      },
      label: 'Manage Interfaces',
      icon: 'network-wired',
      handleAction: ([router]) => {
        history.push(
          routes.openstack.routerDetails.path({
            id: router.id,
            tab: IRouterDetailsPageTabs.Interfaces,
          }),
        )
      },
      refreshAfterSuccess: true,
    },
  ]

  return (
    <>
      <DocumentMeta title="Routers" />
      <CreateRouterModal addRoute={routes.openstack.createRouter} />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.OpenstackRouters}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={tenantRouters}
        columns={routerColumns}
        addUrl={routes.openstack.createRouter.path()}
        addText="Create Router"
        getParamsUpdater={getParamsUpdater}
        deleteAction={deleteRouter}
        DeleteDialogComponent={DeleteRouterDialog}
        batchActions={batchActions}
        EditDialogComponent={EditRouterModal}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
