import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Text from 'core/elements/Text'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import useParams from 'core/hooks/useParams'
import useListAction from 'core/hooks/useListAction'
import {
  listResmgrHosts,
  getResmgrRoleConfig,
  updateRemoteSupportRole,
  removeRemoteSupportRole,
  getBlueprints,
  assignHypervisorRole,
  assignImageLibraryRole,
  assignPersistentStorageRole,
  deauthorizeHypervisorRole,
  deauthorizeImageLibraryRole,
  deauthorizePersistentStorageRole,
  assignHostConfig,
  unassignHostConfig,
  getVmsForHost,
} from '../actions'
import { resmgrHostsSelector } from '../selectors'
import {
  hasHypervisorRole,
  hasImageLibraryRole,
  hasCinderRole,
  hasRemoteSupportRole,
  getHostRoleConfig,
} from '../helpers'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import MultiDropdownField from 'core/components/validatedForm/MultiDropdownField'
import { useSelector } from 'react-redux'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { waitSeconds } from '../blueprint/helpers'
import PicklistField from 'core/components/validatedForm/DropdownField'
import BlockStorageTypePicklist from './BlockStorageTypePicklist'
import HostNetworkConfigPicklist from './HostNetworkConfigPicklist'
import { keys } from 'ramda'

const defaultParams = { valuesByHost: {} }

const ErrorText = ({ children }) => {
  const classes = useStyles({})
  return (
    <Text variant="body1" className={classes.errorText}>
      {children}
    </Text>
  )
}

export default function EditServerRolesModal({ rows: hosts, onClose }) {
  const classes = useStyles()
  const [submitting, setSubmitting] = useState(false)
  const [originalHostRoles, setOriginalHostRoles] = useState({ valuesByHost: {} })
  const [blueprintSettings, setBlueprintSettings] = useState({ storageBackends: {} })
  const [loaded, setLoaded] = useState(false)
  const [showRetryMessage, setShowRetryMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [vmsByHost, setVmsByHost] = useState({})

  useEffect(() => {
    const makeCalls = async () => {
      const hypervisorHosts = hosts.filter((host) => host.roles.includes('pf9-ostackhost-neutron'))
      const results: any = await Promise.allSettled(
        hypervisorHosts.map((host) => getVmsForHost(host?.id)),
      )
      const values = results?.map((result) => result?.value)
      const valuesByHost = hypervisorHosts.reduce(
        (accum, host, idx) => ({
          ...accum,
          [host.id]: values[idx],
        }),
        {},
      )
      setVmsByHost(valuesByHost)
    }
    makeCalls()
  }, [hosts])

  const volumeBackendConfigs = useMemo(() => {
    const volumeTypes = keys(blueprintSettings.storageBackends)
    const configurations = volumeTypes.reduce((accum, type) => {
      const configNames = keys(blueprintSettings.storageBackends[type])
      const configsWithVolumeType = configNames.map((name) => {
        return {
          // @ts-ignore
          ...blueprintSettings.storageBackends[type][name],
          name: name,
          volumeType: type,
        }
      })
      return [...accum, ...configsWithVolumeType]
    }, [])
    return configurations
  }, [blueprintSettings])

  const volumeBackendConfigOptions = useMemo(() => {
    return volumeBackendConfigs.map((config) => {
      return {
        label: `${config.name} (${config.volumeType}, ${config.driver})`,
        value: config.name,
      }
    })
  }, [volumeBackendConfigs])

  const allHosts = useSelector(resmgrHostsSelector)
  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  const { reload } = useListAction(listResmgrHosts, {})

  const enableImageLibraryCheckbox = useCallback(
    (host) => {
      // Should be allowed if no existing image libraries & no other checkbox is checked, or it is currently checked
      if (params.valuesByHost?.[host.id]?.imageLibrary) {
        return true
      }
      const imageLibraryHost = allHosts.find((h) => hasImageLibraryRole(h))
      const imageLibraryChecked = hosts.some((h) => !!params.valuesByHost?.[h.id]?.imageLibrary)
      if (imageLibraryHost) {
        if (imageLibraryHost.id === host.id) {
          return true
        }
        return false
      } else {
        if (imageLibraryChecked) {
          return false
        }
        return true
      }
    },
    [allHosts, params],
  )

  const populateHostData = (hosts, storageHostBackends) => {
    return hosts.reduce(
      (accum, host) => ({
        valuesByHost: {
          ...accum.valuesByHost,
          [host.id]: {
            hostNetworkConfig: host?.hostconfig_id,
            hypervisor: hasHypervisorRole(host),
            imageLibrary: hasImageLibraryRole(host),
            remoteSupport: hasRemoteSupportRole(host),
            volumeBackends:
              keys(storageHostBackends.find((h) => h.host.id === host.id)?.backends) || [],
          },
        },
      }),
      { valuesByHost: {} },
    )
  }

  useEffect(() => {
    if (loaded) {
      return
    }
    const querySettings = async () => {
      const blueprints = await getBlueprints()
      const blueprint = blueprints?.[0]
      // @ts-ignore
      setBlueprintSettings(blueprint)
      setLoaded(true)

      const storageHosts = hosts.filter((host) => host.roles.includes('pf9-cindervolume-config'))
      const results = await Promise.allSettled(
        storageHosts.map((host) =>
          getResmgrRoleConfig({ role: 'pf9-cindervolume-config', host: host }),
        ),
      )
      const storageHostBackends = storageHosts.map((host, idx) => {
        return {
          host,
          // @ts-ignore
          backends: results[idx]?.value?.backends,
        }
      })
      const hostData = populateHostData(hosts, storageHostBackends)
      setOriginalHostRoles(hostData)
      setParams(hostData)
    }
    querySettings()
  }, [hosts])

  const submitForm = useCallback(async () => {
    const rolesToAdd = []
    const rolesToRemove = []
    setSubmitting(true)

    for (const host of hosts) {
      if (
        originalHostRoles?.valuesByHost?.[host.id]?.hypervisor === false &&
        params?.valuesByHost?.[host.id]?.hypervisor === true
      ) {
        rolesToAdd.push({
          role: 'hypervisor',
          host,
        })
      }
      if (
        originalHostRoles?.valuesByHost?.[host.id]?.imageLibrary === false &&
        params?.valuesByHost?.[host.id]?.imageLibrary === true
      ) {
        rolesToAdd.push({ role: 'imageLibrary', host })
      }
      if (
        params?.valuesByHost?.[host.id]?.volumeBackends?.length &&
        originalHostRoles?.valuesByHost?.[host.id]?.volumeBackends?.sort() !==
          params?.valuesByHost?.[host.id]?.volumeBackends?.sort()
      ) {
        rolesToAdd.push({
          role: 'persistentStorage',
          host,
          volumeBackends: params.valuesByHost[host.id].volumeBackends,
        })
      }
      if (
        originalHostRoles?.valuesByHost?.[host.id]?.remoteSupport === false &&
        params?.valuesByHost?.[host.id]?.remoteSupport === true
      ) {
        rolesToAdd.push({ role: 'remoteSupport', host })
      }
      if (
        originalHostRoles?.valuesByHost?.[host.id]?.hypervisor === true &&
        params?.valuesByHost?.[host.id]?.hypervisor === false
      ) {
        rolesToRemove.push({ role: 'hypervisor', host })
      }
      if (
        originalHostRoles?.valuesByHost?.[host.id]?.imageLibrary === true &&
        params?.valuesByHost?.[host.id]?.imageLibrary === false
      ) {
        rolesToRemove.push({ role: 'imageLibrary', host })
      }
      if (
        originalHostRoles?.valuesByHost?.[host.id]?.volumeBackends.length &&
        !params?.valuesByHost?.[host.id]?.volumeBackends.length
      ) {
        rolesToRemove.push({ role: 'persistentStorage', host })
      }
      if (
        originalHostRoles?.valuesByHost?.[host.id]?.remoteSupport === true &&
        params?.valuesByHost?.[host.id]?.remoteSupport === false
      ) {
        rolesToRemove.push({ role: 'remoteSupport', host })
      }
    }

    // Need to unassign host configs that we are changing
    const addHostConfigHosts = hosts.filter((host) => {
      return host.hostconfig_id === null
    })

    const updateHostConfigHosts = hosts.filter((host) => {
      return (
        host.hostconfig_id &&
        host.hostconfig_id !== params?.valuesByHost?.[host.id]?.hostNetworkConfig
      )
    })

    const hostConfigUnassignmentResults = await Promise.allSettled(
      updateHostConfigHosts.map((host) => {
        return unassignHostConfig({
          hostId: host.id,
          configId: host.hostconfig_id,
        })
      }),
    )
    const hostConfigAssignmentResults = await Promise.allSettled(
      [...addHostConfigHosts, ...updateHostConfigHosts].map((host) => {
        return assignHostConfig({
          hostId: host.id,
          configId: params?.valuesByHost?.[host.id]?.hostNetworkConfig,
        })
      }),
    )

    if (
      // @ts-ignore
      [...hostConfigAssignmentResults, ...hostConfigUnassignmentResults].some(
        (result) => result.status === 'rejected',
      )
    ) {
      // @ts-ignore
      const errIndex = hostConfigAssignmentResults.findIndex(
        (result) => result.status === 'rejected',
      )
      const errHost = hosts[errIndex]
      // @ts-ignore
      const err = `${errHost.info.hostname} - ${hostConfigAssignmentResults[errIndex]?.reason?.err}`
      setSubmitting(false)
      setErrorMessage(err)
      setShowRetryMessage(true)
      return
    }
    const results = await Promise.allSettled(
      rolesToAdd.map((role) => {
        if (role.role === 'hypervisor') {
          return assignHypervisorRole({ host: role.host })
        } else if (role.role === 'imageLibrary') {
          return assignImageLibraryRole({ host: role.host })
        } else if (role.role === 'persistentStorage') {
          return assignPersistentStorageRole({
            host: role.host,
            body: {
              backends: role.volumeBackends,
            },
          })
        } else if (role.role === 'remoteSupport') {
          return updateRemoteSupportRole({ host: role.host })
        } else {
          return null
        }
      }),
    )
    if (results.some((result) => result.status === 'rejected')) {
      const errIndex = results.findIndex((result) => result.status === 'rejected')
      const errHost = rolesToAdd[errIndex]?.host
      // @ts-ignore
      const err = `${errHost.info.hostname} - ${results[errIndex]?.reason?.err}`

      setSubmitting(false)
      setErrorMessage(err)
      setShowRetryMessage(true)
      return
    }
    // if (rolesToAdd.some((role) => role.role === 'hypervisor')) {
    //   await waitSeconds(5)
    // }
    await Promise.allSettled(
      rolesToRemove.map((role) => {
        if (role.role === 'imageLibrary') {
          return deauthorizeImageLibraryRole({ host: role.host })
        } else if (role.role === 'hypervisor') {
          return deauthorizeHypervisorRole({ host: role.host })
        } else if (role.role === 'persistentStorage') {
          return deauthorizePersistentStorageRole({ host: role.host })
        } else if (role.role === 'remoteSupport') {
          return removeRemoteSupportRole({ hostId: role.host.id })
        }
        return null
      }),
    )
    reload(true, true)
    handleClose()
  }, [params, hosts])

  const handleClose = () => {
    setParams(defaultParams)
    onClose()
  }

  return (
    <ModalForm
      open
      title={`Assign Cluster Roles`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={submitting}
      submitTitle={`Update Role Assignment`}
      maxWidth={1050}
      loading={!loaded || submitting}
      loadingMessage={
        submitting ? 'Updating host roles, please do not close this dialog' : 'Loading'
      }
      panel="dialog"
      customErrorComponent={showRetryMessage ? <ErrorText>{errorMessage}</ErrorText> : null}
    >
      <div className={classes.planContainer}>
        <>
          <div className={classes.headerLabel}>
            <Text variant="caption1">Server</Text>
          </div>
          <div className={classes.headerLabel}>
            <Text variant="caption1">Host Network Config</Text>
          </div>
          <div className={classes.headerLabel}>
            <Text variant="caption1">Hypervisor</Text>
          </div>
          <div className={classes.headerLabel}>
            <Text variant="caption1">VM Image Library</Text>
          </div>
          <div className={classes.headerLabel}>
            <Text variant="caption1">Persistent Storage</Text>
          </div>
          <div className={classes.headerLabel}>
            <Text variant="caption1">Advanced Remote Support</Text>
          </div>
        </>
        {hosts.map((host) => {
          const configPicklistDisabled = host.roles?.filter((role) => role !== 'pf9-support')
            ?.length
          return (
            <>
              <div>
                <Text variant="body2" className={classes.name}>
                  {host.info.hostname}
                </Text>
              </div>
              <div>
                <PicklistField
                  DropdownComponent={HostNetworkConfigPicklist}
                  name="hostNetworkConfig"
                  id={`valuesByHost.${host.id}.hostNetworkConfig`}
                  label=""
                  compact={false}
                  value={params.valuesByHost?.[host.id]?.hostNetworkConfig}
                  tooltip={
                    configPicklistDisabled ? (
                      <Text variant="body2" className={classes.tooltip}>
                        You may only change the network configuration when no roles are installed on
                        the host.
                      </Text>
                    ) : null
                  }
                  disabled={configPicklistDisabled}
                  disabledMessage
                  onChange={(value) =>
                    updateParams({
                      valuesByHost: {
                        ...params.valuesByHost,
                        [host.id]: {
                          ...params.valuesByHost[host.id],
                          hostNetworkConfig: value,
                        },
                      },
                    })
                  }
                  required
                />
              </div>
              <div className={classes.checkboxCell}>
                <CheckboxField
                  id={`valuesByHost.${host.id}.hypervisor`}
                  label=""
                  value={params.valuesByHost?.[host.id]?.hypervisor}
                  disabled={vmsByHost?.[host.id]?.length > 0}
                  info={
                    vmsByHost?.[host.id]?.length > 0
                      ? 'Cannot remove hypervisor role while VMs are present on this host'
                      : ''
                  }
                  onChange={(value) =>
                    updateParams({
                      valuesByHost: {
                        ...params.valuesByHost,
                        [host.id]: {
                          ...params.valuesByHost[host.id],
                          hypervisor: value,
                        },
                      },
                    })
                  }
                />
              </div>
              <div className={classes.checkboxCell}>
                <CheckboxField
                  id={`valuesByHost.${host.id}.imageLibrary`}
                  label=""
                  value={params.valuesByHost?.[host.id]?.imageLibrary}
                  disabled={!enableImageLibraryCheckbox(host)}
                  info={
                    !enableImageLibraryCheckbox(host)
                      ? 'Only one cluster server may be an image library. Please deauthorize your existing image library if you would like to assign a new image library.'
                      : ''
                  }
                  onChange={(value) =>
                    updateParams({
                      valuesByHost: {
                        ...params.valuesByHost,
                        [host.id]: {
                          ...params.valuesByHost[host.id],
                          imageLibrary: value,
                        },
                      },
                    })
                  }
                />
              </div>
              <div className={classes.checkboxCell}>
                <MultiDropdownField
                  id={`valuesByHost.${host.id}.volumeBackends`}
                  label=""
                  options={volumeBackendConfigOptions}
                  value={params.valuesByHost?.[host.id]?.volumeBackends}
                  onChange={(value) =>
                    updateParams({
                      valuesByHost: {
                        ...params.valuesByHost,
                        [host.id]: {
                          ...params.valuesByHost[host.id],
                          volumeBackends: value,
                        },
                      },
                    })
                  }
                  fullWidth
                />
                {/* <CheckboxField
                id={`valuesByHost.${host.id}.blockStorage`}
                label=""
                value={params.valuesByHost?.[host.id]?.blockStorage}
                info={
                  <Text variant="body2" className={classes.tooltip}>
                    <FontAwesomeIcon className={classes.tooltipIcon}>
                      exclamation-triangle
                    </FontAwesomeIcon>
                    Before making this host a block storage node, please ensure that the cinder
                    volume group has been created on this host.
                  </Text>
                }
                onChange={(value) =>
                  updateParams({
                    valuesByHost: {
                      ...params.valuesByHost,
                      [host.id]: {
                        ...params.valuesByHost[host.id],
                        blockStorage: value,
                      },
                    },
                  })
                }
              /> */}
              </div>
              <div className={classes.checkboxCell}>
                <CheckboxField
                  id={`valuesByHost.${host.id}.remoteSupport`}
                  label=""
                  value={params.valuesByHost?.[host.id]?.remoteSupport}
                  onChange={(value) =>
                    updateParams({
                      valuesByHost: {
                        ...params.valuesByHost,
                        [host.id]: {
                          ...params.valuesByHost[host.id],
                          remoteSupport: value,
                        },
                      },
                    })
                  }
                />
              </div>
            </>
          )
        })}
      </div>
    </ModalForm>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  formContainer: {
    padding: '24px',
    background: 'white',
    width: 'max-content',
  },
  planContainer: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content max-content max-content auto max-content',
    alignItems: 'center',
    gap: '12px 36px',
    overflowY: 'scroll',
    maxHeight: '360px',
    paddingBottom: 100,
  },
  headerLabel: {
    marginBottom: '12px',
  },
  checkboxCell: {
    // display: 'flex',
    // justifyContent: 'center',
  },
  tooltip: {
    color: theme.components.tooltip.text,
  },
  tooltipIcon: {
    color: theme.components.tooltip.text,
    marginRight: 8,
  },
  errorText: {
    color: theme.components.graph.error,
  },
  name: {
    maxWidth: 220,
    wordBreak: 'break-word',
  },
}))
