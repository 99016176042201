import React, { useCallback, useEffect, useState } from 'react'
import useParams from 'core/hooks/useParams'
import ModalForm from 'core/elements/modal/ModalForm'
import { updateRouter } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import TextField from 'core/components/validatedForm/TextField'
import AdminStatePicklist from '../AdminStatePicklist'

export default function EditRouterModal({ rows: [router], onClose }) {
  const [loaded, setLoaded] = useState(false)
  const defaultParams = {}

  const { params, getParamsUpdater, updateParams, setParams } = useParams<{
    name?: string
    adminState?: string
  }>(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(updateRouter)

  useEffect(() => {
    // Prevent resetting of form from background reloads
    if (loaded) {
      return
    }
    updateParams({
      name: router.name,
      adminState: router.admin_state_up ? 'Up' : 'Down',
    })
    setLoaded(true)
  }, [router, loaded])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    onClose()
  }

  const submitForm = useCallback(async () => {
    const body = {
      router: {
        name: params.name,
        admin_state_up: params.adminState !== 'Down',
      },
    }
    const { success } = await update({
      id: router.id,
      body,
    })
    if (success) {
      handleClose()
    }
  }, [router, params, handleClose])

  return (
    <ModalForm
      open
      title={`Edit Router`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      submitTitle={`Update Router`}
      loading={!loaded}
    >
      <FormFieldSection title="Router Configuration">
        <TextField
          id="name"
          label="Name"
          onChange={getParamsUpdater('name')}
          value={params.name}
          required
        />
        <AdminStatePicklist value={params.adminState} onChange={getParamsUpdater('adminState')} />
      </FormFieldSection>
    </ModalForm>
  )
}
