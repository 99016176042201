import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import CreateAction from 'core/actions/CreateAction'
import CustomAction from 'core/actions/CustomAction'
import DeleteAction from 'core/actions/DeleteAction'
import store from 'app/store'
import { cacheActions } from 'core/caching/cacheReducers'
import { noop } from 'utils/fp'

const { dispatch } = store
const { nova } = ApiClient.getInstance()

export const flavorActions = ActionsSet.make<DataKeys.Flavors>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.Flavors,
  cacheKey: DataKeys.Flavors,
})

export const listFlavors = flavorActions.add(
  new ListAction<DataKeys.Flavors>(async () => {
    return nova.getFlavors()
  }),
)

export const createFlavor = flavorActions.add(
  new CreateAction<DataKeys.Flavors, { body }>(async ({ body }) => {
    const created = await nova.createFlavor(body)
    const flavorId = created.id
    const newFlavor = await nova.getFlavor(flavorId)
    return newFlavor
  }),
)

export const deleteFlavor = flavorActions.add(
  new DeleteAction<DataKeys.Flavors, { id: string }>(async ({ id }) => {
    await nova.deleteFlavor(id)
  }),
)

export const updateMetadata = flavorActions.add(
  new CustomAction<DataKeys.Flavors, { id: string; body?: any }>(
    'updateMetadata',
    async ({ id, body }) => {
      const { addBody, updateInfo, deleteInfo } = body
      if (addBody) {
        await nova.createFlavorMetadata({ id, body: addBody })
      }
      if (updateInfo?.length) {
        const promises = updateInfo.map((pair) =>
          nova.updateFlavorMetadata({ id, body: { [pair.key]: pair.value }, key: pair.key }),
        )
        await Promise.allSettled(promises)
      }
      if (deleteInfo?.length) {
        const promises = deleteInfo.map((key) => nova.deleteFlavorMetadata({ id, key }))
        await Promise.allSettled(promises)
      }
      const flavor = await nova.getFlavor(id)
      return flavor
    },
    (result, { id }) => {
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.Flavors,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
    },
  ),
)

export const addFlavorTenant = flavorActions.add(
  new CustomAction<DataKeys.Flavors, { id: string; body?: any }>(
    'addFlavorTenant',
    async ({ id, body }) => {
      return nova.addFlavorTenant({ id, body })
    },
    (result, { id }) => {
      noop()
    },
  ),
)

export const removeFlavorTenant = flavorActions.add(
  new CustomAction<DataKeys.Flavors, { id: string; body?: any }>(
    'removeFlavorTenant',
    async ({ id, body }) => {
      return nova.removeFlavorTenant({ id, body })
    },
    (result, { id }) => {
      noop()
    },
  ),
)

export const getFlavorTenants = async ({ id }) => {
  return nova.getFlavorTenants({ id })
}
