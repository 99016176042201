import React, { useCallback, useState } from 'react'
import useParams from 'core/hooks/useParams'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createNetwork, createSubnet, listNetworks } from './actions'
import ModalForm from 'core/elements/modal/ModalForm'
import { Route } from 'core/plugins/route'
import TextField from 'core/components/validatedForm/TextField'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import { BasicSubnetFields, AdvancedSubnetFields } from './SubnetFormFields'
import { getIPv6AdditionalFields } from './IPv6AddressConfigurationModePicklist'
import useListAction from 'core/hooks/useListAction'
import PicklistField from 'core/components/validatedForm/DropdownField'
import AdminStatePicklist from './AdminStatePicklist'
import { getPhysicalNetworkRequestBody } from './helpers'
import ProviderNetworkTypePicklist from './ProviderNetworkTypePicklist'
import PhysicalNetworkLabelPicklist from './PhysicalNetworkLabelPicklist'

const useStyles = makeStyles<Theme>((theme) => ({}))

interface Props {
  addRoute: Route
}

export default function CreatePhysicalNetworkModal({ addRoute }: Props) {
  const { history } = useReactRouter()
  const classes = useStyles()
  const [createdNetworkId, setCreatedNetworkId] = useState(null)
  const defaultParams = {
    networkType: 'tenant',
    externalNetworkType: 'flat',
    segmentationId: '',
    name: '',
    description: '',
    shared: false,
    adminState: 'Up',
    createSubnet: true,
    ipVersion: 4,
    cidr: '',
    gatewayIp: '',
    disableGateway: false,
    allocationPools: [],
    dnsNameServers: [],
    hostRoutes: [],
    enableDhcp: true,
    ipv6AddressConfigurationMode: '',
    external: false,
    networkLabel: '',
  }
  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  const { reload } = useListAction(listNetworks, {})
  const { update, updating, error, reset } = useUpdateAction(createNetwork)
  const {
    update: updateSubnet,
    updating: updatingSubnet,
    error: subnetError,
    reset: resetSubnet,
  } = useUpdateAction(createSubnet)

  const createNetworkFn = async (params) => {
    const body = getPhysicalNetworkRequestBody(params)
    const { success, response } = await update({ body })
    return { success, response }
  }

  const createSubnetFn = async (params, networkId) => {
    const allocationPools = params.allocationPools.map((entry) => {
      const [start, end] = entry.replace(/\s/g, '').split('-')
      return {
        start,
        end,
      }
    })
    const hostRoutes = params.hostRoutes.map((entry) => {
      const [cidr, nexthop] = entry.replace(/\s/g, '').split(',')
      return {
        destination: cidr,
        nexthop,
      }
    })
    const body = {
      subnet: {
        name: params.subnetName,
        network_id: networkId,
        ip_version: params.ipVersion,
        cidr: params.cidr,
        gateway_ip: params.disableGateway ? null : params.gatewayIp ? params.gatewayIp : undefined,
        allocation_pools: allocationPools.length ? allocationPools : undefined,
        dns_nameservers: params.dnsNameServers.length ? params.dnsNameServers : undefined,
        host_routes: hostRoutes.length ? hostRoutes : undefined,
        enable_dhcp: params.enableDhcp,
        ...getIPv6AdditionalFields(params),
      },
    }
    const { success } = await updateSubnet({ body })
    return { success }
  }

  const submitForm = useCallback(async () => {
    if (createdNetworkId && params.createSubnet) {
      const { success } = await createSubnetFn(params, createdNetworkId)
      if (success) {
        reload(true, true)
        handleClose()
      }
    } else if (createdNetworkId && !params.createSubnet) {
      handleClose()
    } else {
      const { success, response } = await createNetworkFn(params)
      if (params.createSubnet) {
        const networkId = response?.id
        setCreatedNetworkId(networkId)
        const { success: subnetSuccess } = await createSubnetFn(params, networkId)
        if (subnetSuccess) {
          reload(true, true)
          handleClose()
        } else {
          return
        }
      }
      if (success) {
        handleClose()
      }
    }
  }, [params])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    resetSubnet()
    setCreatedNetworkId(null)
    history.push(routes.openstack.networks.path())
  }

  return (
    <ModalForm
      route={addRoute}
      title={`Create Network`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating || updatingSubnet}
      error={error || subnetError}
      submitTitle={`Create Network`}
    >
      <>
        <FormFieldSection title="Network Configuration">
          <TextField
            id="name"
            label="Name"
            onChange={getParamsUpdater('name')}
            value={params.name}
            required
          />
          <TextField
            id="description"
            label="Description"
            onChange={getParamsUpdater('description')}
            value={params.description}
          />
          <PicklistField
            DropdownComponent={PhysicalNetworkLabelPicklist}
            id="networkLabel"
            label="Network Label"
            value={params.networkLabel}
            onChange={getParamsUpdater('networkLabel')}
            info="Select the network label that maps this network to the corresponding physical network on your hypervisors"
            required
          />
          <CheckboxField
            id="external"
            label="Allow use of Public IPs (External Network)"
            value={params.external}
            onChange={getParamsUpdater('external')}
            info="Set checked if the network should be able to assign public IPs (external network)"
          />
          <PicklistField
            DropdownComponent={ProviderNetworkTypePicklist}
            id="networkType"
            label="Network Type"
            value={params.networkType}
            onChange={getParamsUpdater('networkType')}
            required
          />
          {['vlan', 'vxlan'].includes(params.networkType) && (
            <TextField
              id="segmentationId"
              label={params?.networkType === 'vxlan' ? 'VXLAN ID' : 'VLAN ID'}
              onChange={getParamsUpdater('segmentationId')}
              value={params.segmentationId}
              required
            />
          )}
          <AdminStatePicklist value={params.adminState} onChange={getParamsUpdater('adminState')} />
          <CheckboxField
            id="shared"
            label="Make Shared"
            value={params.shared}
            onChange={getParamsUpdater('shared')}
            info="If checked, network will be available for every project."
          />
          <CheckboxField
            id="createSubnet"
            label="Create Subnet"
            value={params.createSubnet}
            onChange={getParamsUpdater('createSubnet')}
            info="Create a subnet for this network now."
          />
        </FormFieldSection>
        {params.createSubnet && (
          <>
            <BasicSubnetFields
              params={params}
              getParamsUpdater={getParamsUpdater}
              inNetworkCreation
            />
            <AdvancedSubnetFields params={params} updateParams={updateParams} />
          </>
        )}
      </>
    </ModalForm>
  )
}
