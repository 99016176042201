import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'

export const sshKeysSelector = createSharedSelector(
  getDataSelector<DataKeys.SshKeys>(DataKeys.SshKeys),
  (keys) => {
    return keys
  },
)
