import { AppPlugins } from 'app/constants'
import Plugin from 'core/plugins/plugin'

import NotificationsPage from 'core/components/notifications/NotificationsPage'

import AddUserGroupPage from 'account/components/userManagement/groups/AddUserGroupPage'
import UserGroupsListPage from 'account/components/userManagement/groups/UserGroupsListPage'
import UserRolesListPage from 'account/components/userManagement/roles/UserRolesListPage'
import TenantDetailsPage from 'account/components/userManagement/tenants/TenantDetailsPage'
import TenantsListPage from 'account/components/userManagement/tenants/TenantsListPage'
import AddUserPage from 'account/components/userManagement/users/AddUserPage'
import UsersListPage from 'account/components/userManagement/users/UsersListPage'
import ClusterBlueprintPage from 'app/plugins/openstack/components/infrastructure/blueprint/ClusterBlueprintPage'
import ApiServicesPage from 'k8s/components/apiAccess/ApiServicesPage'
import Dashboard from 'openstack/components/dashboard/Dashboard'
import FlavorsListPage from 'openstack/components/flavors/FlavorsListPage'
import ImagesListPage from 'openstack/components/images/ImagesListPage'
import InfrastructurePlanPage from 'openstack/components/infrastructure/InfrastructurePlanPage'
import HostAggregatesListPage from 'openstack/components/infrastructure/host-aggregates/HostAggregatesListPage'
import AddServersPage from 'openstack/components/infrastructure/hosts/AddServersPage'
import AddVmwareGatewayPage from 'openstack/components/infrastructure/hosts/AddVmwareGatewayPage'
import HostsListPage from 'openstack/components/infrastructure/hosts/HostsListPage'
import HostDetailsPage from 'openstack/components/infrastructure/hosts/host-details/HostDetailsPage'
import NetworksListPage from 'openstack/components/networks/NetworksListPage'
import FloatingIpsListPage from 'openstack/components/networks/floating-ips/FloatingIpsListPage'
import NetworkDetailsPage from 'openstack/components/networks/network-details/NetworkDetailsPage'
import RouterDetailsPage from 'openstack/components/networks/routers/RouterDetailsPage'
import RoutersListPage from 'openstack/components/networks/routers/RoutersListPage'
import SecurityGroupsListPage from 'openstack/components/networks/security-groups/SecurityGroupsListPage'
import VirtualNetworkDetailsPage from 'openstack/components/networks/virtual-networks/VirtualNetworkDetailsPage'
import VirtualNetworksListPage from 'openstack/components/networks/virtual-networks/VirtualNetworksListPage'
import VmwareNetworkDetailsPage from 'openstack/components/networks/vmware-networks/VmwareNetworkDetailsPage'
import VmwareNetworksListPage from 'openstack/components/networks/vmware-networks/VmwareNetworksListPage'
import StackDetailsPage from 'openstack/components/orchestration/StackDetailsPage'
import StacksListPage from 'openstack/components/orchestration/StacksListPage'
import SshKeysListPage from 'openstack/components/security/keys/SshKeysListPage'
import VolumeSnapshotsListPage from 'openstack/components/storage/snapshots/VolumeSnapshotsListPage'
import VolumeTypesListPage from 'openstack/components/storage/volume-types/VolumeTypesListPage'
import VolumesListPage from 'openstack/components/storage/volumes/VolumesListPage'
import VirtualMachinesListPage from 'openstack/components/vms/VirtualMachinesListPage'
import ServerGroupsListPage from 'openstack/components/vms/server-groups/ServerGroupsListPage'
import VmDetailsPage from 'openstack/components/vms/vm-details/VmDetailsPage'
import { kubevirtLogo } from '../kubevirt/logo'
import { isAdmin } from 'app/plugins/infrastructure/components/common/helpers'

const openstackPlugin = new Plugin(
  AppPlugins.OpenStack,
  'Virtualized Clusters',
  '/ui/openstack',
  kubevirtLogo,
  'Virtualized clusters for traditional workloads',
)

openstackPlugin.registerRoutes([
  {
    name: 'Virtual Machines',
    link: {
      path: '/compute/vms/(deploy)?',
      navPath: '/compute/vms',
      exact: true,
      default: true,
    },
    component: VirtualMachinesListPage,
  },
  {
    name: 'Virtual Machine Details',
    link: { path: '/compute/vms/:id/:tab?', exact: true },
    component: VmDetailsPage,
  },
  {
    name: 'Server Groups',
    link: { path: '/compute/server-groups/(create)?', exact: true },
    component: ServerGroupsListPage,
  },
  {
    name: 'Notifications',
    link: { path: '/notifications/:notificationType/:id?', exact: true },
    component: NotificationsPage,
  },
  {
    name: 'Dashboard',
    link: { path: 'dashboard', exact: true },
    component: Dashboard,
  },
  {
    name: 'Images',
    link: { path: '/images/(add||import)?', exact: true },
    component: ImagesListPage,
  },
  {
    name: 'Flavors',
    link: { path: '/flavors/(create)?', exact: true },
    component: FlavorsListPage,
  },
  {
    name: 'Volumes',
    link: { path: '/storage/volumes/(create)?', exact: true },
    component: VolumesListPage,
  },
  {
    name: 'Volume Types',
    link: { path: '/storage/volume-types/(create)?', exact: true },
    component: VolumeTypesListPage,
  },
  {
    name: 'Volume Snapshots',
    link: { path: '/storage/snapshots', exact: true },
    component: VolumeSnapshotsListPage,
  },
  {
    name: 'Networks',
    link: { path: '/networking/networks/(create)?', exact: true },
    component: NetworksListPage,
  },
  {
    name: 'Network Details',
    link: { path: '/networking/networks/:id/:tab?/(create)?', exact: true },
    component: NetworkDetailsPage,
  },
  {
    name: 'Virtual Networks',
    link: { path: '/networking/virtual-networks/(create)?', exact: true },
    component: VirtualNetworksListPage,
  },
  {
    name: 'Virtual Network Details',
    link: { path: '/networking/virtual-networks/:id/:tab?/(create)?', exact: true },
    component: VirtualNetworkDetailsPage,
  },
  {
    name: 'Networks',
    link: { path: '/networking/vmw-networks/(create)?', exact: true },
    component: VmwareNetworksListPage,
  },
  {
    name: 'Network Details',
    link: { path: '/networking/vmw-networks/:id/:tab?/(create)?', exact: true },
    component: VmwareNetworkDetailsPage,
  },
  {
    name: 'Routers',
    link: { path: '/networking/routers/(create)?', exact: true },
    component: RoutersListPage,
  },
  {
    name: 'Router Details',
    link: { path: '/networking/routers/:id/:tab?/(create)?', exact: true },
    component: RouterDetailsPage,
  },
  {
    name: 'Public IPs',
    link: { path: '/networking/public-ips/(create)?', exact: true },
    component: FloatingIpsListPage,
  },
  {
    name: 'Security Groups',
    link: { path: '/networking/security-groups/(create)?', exact: true },
    component: SecurityGroupsListPage,
  },
  {
    name: 'Cluster Blueprint',
    link: { path: '/infrastructure/blueprint', exact: true },
    component: ClusterBlueprintPage,
  },
  {
    name: 'Cluster Host Roles',
    link: { path: '/infrastructure/host-roles', exact: true },
    component: InfrastructurePlanPage,
  },
  {
    name: 'Cluster Hosts',
    link: { path: '/infrastructure/hosts', exact: true },
    component: HostsListPage,
  },
  {
    name: 'Add Cluster Hosts',
    link: { path: '/infrastructure/hosts/add', exact: true },
    component: AddServersPage,
  },
  {
    name: 'Add VMware Gateway',
    link: { path: '/infrastructure/hosts/add-gateway', exact: true },
    component: AddVmwareGatewayPage,
  },
  {
    name: 'Cluster Host Details',
    link: { path: '/infrastructure/hosts/:id/:tab?', exact: true },
    component: HostDetailsPage,
  },
  {
    name: 'Host Aggregates',
    link: { path: '/infrastructure/host-aggregates/(create)?', exact: true },
    component: HostAggregatesListPage,
  },
  {
    name: 'Ssh Keys',
    link: { path: '/security/keys/(import)?', exact: true },
    component: SshKeysListPage,
  },
  {
    name: 'API Access',
    link: { path: '/security/api-access', exact: true },
    component: ApiServicesPage,
  },
  {
    name: 'Stacks',
    link: { path: '/orchestration/stacks/(create)?', exact: true },
    component: StacksListPage,
  },
  {
    name: 'Stack Details',
    link: { path: '/orchestration/stacks/:name/:id/:tab?', exact: true },
    component: StackDetailsPage,
  },
  {
    name: 'Tenants',
    requiredRoles: 'admin',
    link: {
      path: '/access/tenants/(add)?',
      exact: true,
    },
    component: TenantsListPage,
  },
  {
    name: 'Tenant Details',
    link: { path: '/access/tenants/:id/:tab?', exact: true },
    component: TenantDetailsPage,
  },
  {
    name: 'Users',
    requiredRoles: 'admin',
    link: {
      path: '/access/users/(add)?',
      exact: true,
    },
    component: UsersListPage,
  },
  {
    name: 'Add User',
    requiredRoles: 'admin',
    link: { path: '/access/users/add', exact: true },
    component: AddUserPage,
  },
  {
    name: 'Roles',
    requiredRoles: 'admin',
    link: {
      path: '/access/roles/(add)?',
      exact: true,
    },
    component: UserRolesListPage,
  },
  {
    name: 'Groups',
    requiredRoles: 'admin',
    link: {
      path: '/access/groups/(add)?',
      exact: true,
    },
    component: UserGroupsListPage,
  },
  {
    name: 'Add User Group',
    requiredRoles: 'admin',
    link: { path: '/access/groups/add', exact: true },
    component: AddUserGroupPage,
  },
  // {
  //   name: 'Enterprise SSO',
  //   requiredRoles: 'admin',
  //   link: {
  //     path: '/sso/:tab?/(edit||add)?/:id?',
  //     exact: true,
  //   },
  //   component: SsoManagementPage,
  // },
])

// These nav items are in active development but not shown in production.
const navItems = [
  {
    name: 'Dashboard',
    link: { path: '/dashboard', definition: '/dashboard' },
    icon: 'gauge-circle-bolt',
    nestedLinks: [
      {
        name: 'Overview',
        link: {
          path: '/dashboard',
          definition: '/dashboard',
        },
      },
    ],
  },
  {
    name: 'Infrastructure',
    icon: 'buildings',
    requiredRoles: 'admin',
    link: { path: '/infrastructure/hosts', definition: '/infrastructure/:tab' },
    nestedLinks: [
      {
        name: 'Cluster Blueprint',
        link: {
          path: '/infrastructure/blueprint',
          definition: '/infrastructure/blueprint',
        },
        requiredFeatures: (features) => {
          return !(features?.experimental?.pmov2_du_type === 'vmware')
        },
      },
      {
        name: 'Cluster Hosts',
        link: {
          path: '/infrastructure/hosts',
          definition: '/infrastructure/hosts/:id?',
        },
      },
      {
        name: 'Host Aggregates',
        link: {
          path: '/infrastructure/host-aggregates',
          definition: '/infrastructure/host-aggregates/:id?',
        },
      },
    ],
  },
  {
    name: 'Virtual Machines',
    icon: 'desktop',
    link: { path: '/compute/vms', definition: '/compute/:tab' },
    nestedLinks: [
      {
        name: 'Virtual Machines',
        link: {
          path: '/compute/vms',
          definition: '/compute/vms/:id?',
        },
      },
      {
        name: 'Server Groups',
        link: {
          path: '/compute/server-groups',
          definition: '/compute/server-groups',
        },
      },
    ],
  },
  {
    name: 'Images',
    icon: 'images',
    link: { path: '/images', definition: '/images' },
    nestedLinks: [
      {
        name: 'Images',
        link: {
          path: '/images',
          definition: '/images',
        },
      },
    ],
  },
  {
    name: 'Flavors',
    icon: 'utensils',
    link: { path: '/flavors', definition: '/flavors' },
    nestedLinks: [
      {
        name: 'Flavors',
        link: {
          path: '/flavors',
          definition: '/flavors',
        },
      },
    ],
  },
  {
    name: 'Storage',
    icon: 'coins',
    link: { path: '/storage/volumes', definition: '/storage/:tab' },
    nestedLinks: [
      {
        name: 'Volumes',
        link: {
          path: '/storage/volumes',
          definition: '/storage/volumes',
        },
      },
      {
        name: 'Volume Types',
        link: {
          path: '/storage/volume-types',
          definition: '/storage/volume-types',
        },
      },
      {
        name: 'Volume Snapshots',
        link: {
          path: '/storage/snapshots',
          definition: '/storage/snapshots',
        },
      },
    ],
  },
  {
    name: 'Networking',
    icon: 'network-wired',
    link: { path: '/networking/networks', definition: '/networking/:tab' },
    nestedLinks: [
      {
        name: 'Physical Networks',
        link: {
          path: '/networking/networks',
          definition: '/networking/networks/:id?',
        },
        requiredFeatures: (features) => {
          return !(features?.experimental?.pmov2_du_type === 'vmware')
        },
      },
      {
        name: 'Virtual Networks',
        link: {
          path: '/networking/virtual-networks',
          definition: '/networking/virtual-networks/:id?',
        },
        requiredFeatures: (features) => {
          return !(features?.experimental?.pmov2_du_type === 'vmware')
        },
        isHidden: (props) => {
          return !props?.virtualNetworking || !isAdmin()
        },
      },
      {
        name: 'Networks',
        link: {
          path: '/networking/vmw-networks',
          definition: '/networking/vmw-networks/:id?',
        },
        requiredFeatures: (features) => features?.experimental?.pmov2_du_type === 'vmware',
      },
      {
        name: 'Routers',
        link: {
          path: '/networking/routers',
          definition: '/networking/routers/:id?',
        },
        requiredFeatures: (features) => {
          return !(features?.experimental?.pmov2_du_type === 'vmware')
        },
        isHidden: (props) => {
          return !props?.virtualNetworking
        },
      },
      {
        name: 'Public IPs',
        link: {
          path: '/networking/public-ips',
          definition: '/networking/public-ips/:id?',
        },
        requiredFeatures: (features) => {
          return !(features?.experimental?.pmov2_du_type === 'vmware')
        },
        isHidden: (props) => {
          return !props?.virtualNetworking
        },
      },
      {
        name: 'Security Groups',
        link: {
          path: '/networking/security-groups',
          definition: '/networking/security-groups/:id?',
        },
        requiredFeatures: (features) => {
          return !(features?.experimental?.pmov2_du_type === 'vmware')
        },
        isHidden: (props) => {
          return !props?.virtualNetworking
        },
      },
    ],
  },
  {
    name: 'Orchestration',
    icon: 'cubes',
    link: { path: '/orchestration/stacks', definition: '/orchestration/:tab' },
    nestedLinks: [
      {
        name: 'Stacks',
        link: {
          path: '/orchestration/stacks',
          definition: '/orchestration/stack/:name?/:id?',
        },
      },
    ],
  },
  {
    name: 'Access & Security',
    icon: 'key',
    link: { path: '/security/keys', definition: '/security/:tab' },
    nestedLinks: [
      {
        name: 'SSH Keys',
        link: {
          path: '/security/keys',
          definition: '/security/keys',
        },
      },
      {
        name: 'API Access',
        link: {
          path: '/security/api-access',
          definition: '/security/api-access',
        },
      },
    ],
  },
  {
    name: 'Tenants & Users',
    link: { path: '/access/tenants', definition: '/access/:tab' },
    icon: 'users',
    requiredRoles: 'admin',
    nestedLinks: [
      {
        name: 'Tenants',
        link: { path: '/access/tenants' },
      },
      {
        name: 'Users',
        link: { path: '/access/users' },
      },
      { name: 'Groups', link: { path: '/access/groups' } },
      { name: 'Roles', link: { path: '/access/roles' } },
    ],
  },
]

openstackPlugin.registerNavItems(navItems)

export default openstackPlugin
