import React, { useMemo } from 'react'
import Dropdown from 'core/elements/dropdown'
import { MultiDropdownProps } from 'core/elements/dropdown/MultiDropdown'
import withProgress from 'core/components/progress/withProgress'
import useListAction from 'core/hooks/useListAction'
import { listSshKeys } from '../security/keys/actions'
import { useSelector } from 'react-redux'
import { sshKeysSelector } from '../security/keys/selectors'

interface SshKeyPicklistProps<V> extends Omit<MultiDropdownProps<V>, 'items'> {}

const AsyncDropdown = withProgress(Dropdown, { inline: true })

export default function SshKeyPicklist<V>({
  disabled,
  name = 'sshKey',
  label = 'SSH Key',
  ...rest
}: SshKeyPicklistProps<V>) {
  const { loading } = useListAction(listSshKeys)
  const sshKeys = useSelector(sshKeysSelector)

  const options = useMemo(
    () => [
      { label: 'None', value: 'None' },
      ...sshKeys.map((key) => ({
        label: key.name,
        value: key.name,
      })),
    ],
    [sshKeys],
  )

  return (
    <AsyncDropdown
      {...rest}
      name={name}
      label={label}
      compact={false}
      disabled={disabled}
      loading={loading}
      items={options}
    />
  )
}
