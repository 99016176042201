import React, { useMemo } from 'react'
import ListTable from 'core/components/listTable/ListTable'
import { useSelector } from 'react-redux'
import { securityGroupsByIdSelector } from './selectors'
import Button from 'core/elements/button/Button'
import { transformSecurityGroupRuleForGrid } from './helpers'

interface Props {
  params: any
  getParamsUpdater: any
  updateParams: any
}

export default function SecurityGroupRulesParamFields({
  params,
  getParamsUpdater,
  updateParams,
}: Props) {
  const securityGroupsById = useSelector(securityGroupsByIdSelector)

  const filteredTransformedRules = useMemo(() => {
    const filteredRules = params.currentRules.filter(
      (rule) => !params.rulesToDelete.includes(rule.id),
    )
    return filteredRules.map((rule) =>
      transformSecurityGroupRuleForGrid({ rule, securityGroupsById }),
    )
  }, [params.currentRules, params.rulesToDelete, securityGroupsById])

  const columns = useMemo(
    () => [
      {
        id: 'directionDisplay',
        label: 'Direction',
      },
      {
        id: 'typeDisplay',
        label: 'Type',
      },
      {
        id: 'protocolDisplay',
        label: 'Protocol',
      },
      {
        id: 'ethertype',
        label: 'IP Version',
      },
      {
        id: 'portOrCodeDisplay',
        label: 'Port Range or Code',
      },
      {
        id: 'destination',
        label: 'Destination',
      },
      {
        id: 'cidrOrSecurityGroupDisplay',
        label: 'CIDR or Security Group',
      },
      {
        id: 'id',
        label: '',
        render: (id) => {
          return (
            <Button onClick={() => updateParams({ rulesToDelete: [...params.rulesToDelete, id] })}>
              Delete
            </Button>
          )
        },
      },
    ],
    [params.rulesToDelete],
  )

  return (
    <ListTable
      title="Existing Rules"
      columns={columns}
      data={filteredTransformedRules}
      loading={false}
      uniqueIdentifier="id"
      searchTargets={[
        'directionDisplay',
        'typeDisplay',
        'protocolDisplay',
        'ethertype',
        'portOrCodeDisplay',
        'destination',
        'cidrOrSecurityGroupDisplay',
      ]}
      orderBy="directionDisplay"
      showCheckboxes={false}
    />
  )
}
