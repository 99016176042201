import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import DeleteAction from 'core/actions/DeleteAction'
import CustomAction from 'core/actions/CustomAction'
import CreateAction from 'core/actions/CreateAction'
import UpdateAction from 'core/actions/UpdateAction'
import store from 'app/store'
import { cacheActions } from 'core/caching/cacheReducers'

const { dispatch } = store
const { neutron } = ApiClient.getInstance()

export const securityGroupActions = ActionsSet.make<DataKeys.SecurityGroups>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.SecurityGroups,
  cacheKey: DataKeys.SecurityGroups,
})

export const listSecurityGroups = securityGroupActions.add(
  new ListAction<DataKeys.SecurityGroups>(async () => {
    return neutron.getSecurityGroups()
  }),
)

export const createSecurityGroup = securityGroupActions.add(
  new CreateAction<DataKeys.SecurityGroups, { body }>(async ({ body }) => {
    const created = await neutron.createSecurityGroup(body)
    const securityGroupId = created.id
    const newSecurityGroup = await neutron.getSecurityGroup(securityGroupId)
    return newSecurityGroup
  }),
)

export const updateSecurityGroup = securityGroupActions.add(
  new UpdateAction<
    DataKeys.SecurityGroups,
    {
      id: string
      body: unknown
    }
  >(async ({ id, body }) => {
    const updatedSecurityGroup = await neutron.updateSecurityGroup({ id, body })
    return updatedSecurityGroup
  }),
)

export const deleteSecurityGroup = securityGroupActions.add(
  new DeleteAction<DataKeys.SecurityGroups, { id: string }>(async ({ id }) => {
    await neutron.deleteSecurityGroup(id)
  }),
)

export const createSecurityGroupRules = securityGroupActions.add(
  new CustomAction<DataKeys.SecurityGroups, { id: string; body: any }>(
    'createSecurityGroupRules',
    async ({ id, body }) => {
      await neutron.createSecurityGroupRules(body)
      const securityGroup = await neutron.getSecurityGroup(id)
      return securityGroup
    },
    (result, { id }) => {
      // Update the cluster in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.SecurityGroups,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
    },
  ),
)

export const deleteSecurityGroupRules = securityGroupActions.add(
  new CustomAction<DataKeys.SecurityGroups, { id: string; ruleIds: string[] }>(
    'deleteSecurityGroupRules',
    async ({ id, ruleIds }) => {
      await Promise.allSettled(ruleIds.map((id) => neutron.deleteSecurityGroupRule(id)))
      const securityGroup = await neutron.getSecurityGroup(id)
      return securityGroup
    },
    (result, { id }) => {
      // Update the cluster in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.SecurityGroups,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
    },
  ),
)
