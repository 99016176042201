import React, { useMemo } from 'react'
import UsageWidget from 'core/components/widgets/UsageWidget'
import { getHostUsageTotals } from './helpers'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

const useStyles = makeStyles<Theme>((theme) => ({
  hostCards: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: 12,
    marginBottom: 12,
  },
}))

export default function HostsListPageHeader({ hosts }) {
  const classes = useStyles()
  const totals = getHostUsageTotals(hosts)

  return (
    <div className={classes.hostCards}>
      <UsageWidget title="CPU" stats={totals.compute} units="GHz" />
      <UsageWidget title="Memory" stats={totals.memory} units="GiB" />
      <UsageWidget title="Storage" stats={totals.disk} units="GiB" />
    </div>
  )
}
