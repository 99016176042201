import React, { useMemo } from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

interface Props {
  host: any
}

export default function IpAddressPicklist({
  name = '',
  label = '',
  compact = false,
  host,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const options = useMemo(() => {
    return host?.interfaces?.map((iface) => ({
      label: `${iface.ip} (${iface.name})`,
      value: iface.ip,
    }))
  }, [host])
  return <AsyncDropdown compact={compact} name={name} label={label} items={options} {...rest} />
}
