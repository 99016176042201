import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

const options = [
  { label: 'VLAN Underlay', value: 'vlan' },
  { label: 'IP Underlay for VXLAN Overlay', value: 'vxlan' },
  { label: 'IP Underlay for GENEVE Overlay', value: 'geneve' },
]

const ovsOptions = [
  { label: 'VLAN Underlay', value: 'vlan' },
  { label: 'IP Underlay for VXLAN Overlay', value: 'vxlan' },
]

interface Props {
  networkingBackend: string
}

export default function UnderlayNetworkTypePicklist({
  name = 'underlayNetworkType',
  label = 'Underlay Technology',
  compact = false,
  networkingBackend = 'ovn',
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  return (
    <AsyncDropdown
      compact={compact}
      name={name}
      label={label}
      items={networkingBackend === 'ovs' ? ovsOptions : options}
      {...rest}
    />
  )
}
