import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useParams from 'core/hooks/useParams'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { updateMetadata } from './actions'
import ModalForm from 'core/elements/modal/ModalForm'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { useSelector } from 'react-redux'
import KeyValuesField from 'core/components/validatedForm/KeyValuesField'
import Text from 'core/elements/Text'
import Grid from 'core/elements/grid'
import { listResmgrHosts, listHostAggregates } from 'openstack/components/infrastructure/actions'
import { hostAggregatesSelector } from 'openstack/components/infrastructure/selectors'
import { HostsCellComponent } from 'openstack/components/infrastructure/host-aggregates/HostAggregatesListPage'
import useListAction from 'core/hooks/useListAction'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { ArrayElement } from 'core/actions/Action'
import { createResourceLabelsCell } from 'k8s/components/common/entity/labels-and-annotations/helpers'
import Button from 'core/elements/button/Button'
import ToggleSwitch from 'core/elements/ToggleSwitch'

const useStyles = makeStyles<Theme>((theme) => ({
  sameLine: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
  },
}))

type SelectorModel = ArrayElement<ReturnType<typeof hostAggregatesSelector>>

interface Props {
  rows: any[]
  onClose: () => void
}

export default function EditFlavorMetadataModal({ rows: [flavor], onClose }: Props) {
  const classes = useStyles()

  const defaultParams = useMemo(
    () => ({
      metadata: Object.entries(flavor.extra_specs).map(([key, value]) => {
        return { key, value }
      }),
    }),
    [flavor.extra_specs],
  )

  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)
  const [showAllHostAggregates, setShowAllHostAggregates] = useState(false)
  const [showMetadata, setShowMetadata] = useState(true)

  useListAction(listResmgrHosts, { params })
  const { message, loading: loadingHostAggregates, reload } = useListAction(listHostAggregates, {
    params,
  })
  const hostAggregates = useSelector(hostAggregatesSelector)
  const filteredHostAggregates = useMemo(() => {
    if (!params.metadata.length || !params.metadata[0]?.key || !params.metadata[0]?.value) {
      return hostAggregates
    }
    return hostAggregates.filter((hostAggregate) => {
      return params.metadata.every((pair) => hostAggregate.metadata[pair.key] === pair.value)
    })
  }, [hostAggregates, params.metadata])

  const { update, updating, error, reset } = useUpdateAction(updateMetadata)

  const submitForm = useCallback(async () => {
    // calc new/update keys
    const originalMetadata = flavor?.extra_specs
    const newMetadata = params.metadata
    const metadataInfo = newMetadata.reduce(
      (accum, pair) => {
        const key = pair.key
        if (originalMetadata[key]) {
          return originalMetadata[key] === pair.value
            ? accum
            : {
                ...accum,
                updateMetadata: [...accum.updateMetadata, pair],
              }
        } else {
          return {
            ...accum,
            addMetadata: {
              ...accum.addMetadata,
              [key]: pair.value,
            },
          }
        }
      },
      {
        addMetadata: {},
        updateMetadata: [],
      },
    )

    // Calc delete keys
    const originalMetadataKeys = Object.keys(originalMetadata)
    const newMetadataObj = params.metadata.reduce((accum, pair) => {
      return {
        ...accum,
        [pair.key]: pair.value,
      }
    }, {})
    const deleteKeys = originalMetadataKeys.filter((key) => {
      return !newMetadataObj[key]
    })

    const body = {
      addBody: {
        extra_specs: metadataInfo.addMetadata,
      },
      updateInfo: metadataInfo.updateMetadata,
      deleteInfo: deleteKeys,
    }
    const { success } = await update({ id: flavor.id, body })
    if (!success) return
    handleClose()
  }, [flavor?.id, flavor?.extra_specs, params])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    onClose()
  }

  const handleMetadataChange = (cb) => {
    setShowMetadata(false)
    cb()
    // setShowMetadata(true)
  }

  useEffect(() => {
    if (!showMetadata) {
      setShowMetadata(true)
    }
  }, [showMetadata])

  const hostAggregateColumns: GridViewColumn<SelectorModel>[] = useMemo(
    () => [
      {
        key: 'name',
        label: 'Name',
        width: 'medium',
      },
      {
        key: 'hostInfo',
        label: 'Hosts',
        CellComponent: HostsCellComponent,
      },
      {
        key: 'metadata',
        label: 'Metadata',
        disableSorting: true,
        CellComponent: createResourceLabelsCell({ type: 'table', separator: '=' }),
      },
      {
        key: 'id',
        label: '',
        CellComponent: ({ value, item }) => {
          const metadataKeys = Object.keys(item.metadata)
          const metadata = metadataKeys.map((key) => {
            return {
              key,
              value: item.metadata[key],
            }
          })
          return (
            <Button
              onClick={() => {
                handleMetadataChange(() => updateParams({ metadata }))
              }}
            >
              Assign Metadata
            </Button>
          )
        },
      },
    ],
    [params.metadata],
  )

  return (
    <ModalForm
      title={`Edit Flavor`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      submitTitle={`Update Flavor`}
      open
    >
      <>
        <FormFieldSection title="Flavor Metadata">
          <Text variant="body2">
            You can restrict placement of VM instances created with this flavor to certain hosts.
          </Text>
          <Text variant="body2">
            Virtual machines created using this flavor will only be assigned to hosts whose metadata
            matches all of the flavor's metadata.
          </Text>
          {showMetadata && (
            <KeyValuesField
              id="metadata"
              label="Metadata"
              value={params.metadata}
              onChange={getParamsUpdater('metadata')}
              addLabel="Add Metadata"
              allowMultipleValues
            />
          )}
          <div className={classes.sameLine}>
            <Text variant="caption1">Matching Host Aggregates</Text>
            <ToggleSwitch
              active={showAllHostAggregates}
              onClick={(val) => setShowAllHostAggregates(val)}
              label="Show All"
            />
          </div>
          <Grid
            uniqueIdentifier="id"
            data={showAllHostAggregates ? hostAggregates : filteredHostAggregates}
            columns={hostAggregateColumns}
            loading={loadingHostAggregates}
            compact
            disableToolbar
          />
        </FormFieldSection>
      </>
    </ModalForm>
  )
}
