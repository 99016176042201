import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import DeleteAction from 'core/actions/DeleteAction'
import CustomAction from 'core/actions/CustomAction'
import CreateAction from 'core/actions/CreateAction'
import UpdateAction from 'core/actions/UpdateAction'
import store from 'app/store'
import { isValidIpv4Address } from 'app/plugins/infrastructure/components/clusters/form-components/validators'
import { cacheActions } from 'core/caching/cacheReducers'

const { dispatch } = store
const { haMgr, resMgr, nova } = ApiClient.getInstance()

export const getSingleResmgrHost = (id) => {
  return resMgr.getResmgrHost(id)
}

export const resmgrActions = ActionsSet.make<DataKeys.ResmgrHosts>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.ResmgrHosts,
  cacheKey: DataKeys.ResmgrHosts,
})

export const listResmgrHosts = resmgrActions.add(
  new ListAction<DataKeys.ResmgrHosts>(async () => {
    return resMgr.getHostsv2()
  }).addDependency(DataKeys.OpenstackVirtualMachines),
)

const getIpForInterface = (host, interfaceName, settings) => {
  // try bridge name too, in case ip already moved to bridge
  const activePhysicalNetworksInterfaces = settings.blueprint?.config?.physicalNetworksInterfaceMap?.filter(
    (network) => network.isActive,
  )
  const bridgeMap = activePhysicalNetworksInterfaces?.reduce((accum, phyNetInterface) => {
    return {
      ...accum,
      [phyNetInterface.interface]: phyNetInterface.bridgeName,
    }
  }, {})
  const ifaces = host.interfaces
  return ifaces.find((iface) => {
    const ifaceMatch = iface.name === interfaceName && isValidIpv4Address(iface.ip)
    const bridgeMatch = iface.name === bridgeMap[interfaceName] && isValidIpv4Address(iface.ip)
    return ifaceMatch || bridgeMatch
  })?.ip
}

const keyForInterface = {
  vmNetwork: 'vmnet_interface',
  managementNetwork: 'mgmt_interface',
}

export const assignHypervisorRole = async ({ host }) => {
  return resMgr.assignHypervisorRole({ id: host.id })
}

export const assignImageLibraryRole = async ({ host }) => {
  return resMgr.assignImageLibraryRole({ id: host.id })
}

export const assignPersistentStorageRole = async ({ host, body }) => {
  return resMgr.assignPersistentStorageRole({ id: host.id, body })
}

export const deauthorizeHypervisorRole = async ({ host }) => {
  return resMgr.deauthorizeHypervisorRole({ id: host.id })
}

export const deauthorizeImageLibraryRole = async ({ host }) => {
  return resMgr.deauthorizeImageLibraryRole({ id: host.id })
}

export const deauthorizePersistentStorageRole = async ({ host }) => {
  return resMgr.deauthorizePersistentStorageRole({ id: host.id })
}

export const assignHostConfig = async ({ hostId, configId }) => {
  return resMgr.assignHostConfig({ hostId, configId })
}

export const unassignHostConfig = async ({ hostId, configId }) => {
  return resMgr.unassignHostConfig({ hostId, configId })
}

export const updateHypervisorRole = async ({ host, settings, storageType = null }) => {
  const hostLivenessIp = getIpForInterface(
    host,
    settings?.blueprint?.config?.[
      keyForInterface[settings.blueprint.config.host_liveness_interface]
    ],
    settings,
  )
  const hostIp = getIpForInterface(
    host,
    settings?.blueprint?.config?.[keyForInterface[settings.blueprint.config.console_interface]],
    settings,
  )
  const ostackhostBody =
    storageType === 'ceph'
      ? {
          cluster_ip: hostLivenessIp,
          consul_ip: '',
          instances_path: settings?.blueprint?.config?.vm_storage_path,
          novncproxy_base_url: `http://${hostIp}:6080/vnc_auto.html`,
          live_migration_inbound_addr: hostLivenessIp,
          // additional ceph properties
          rbd_secret_uuid: settings?.blueprint?.config?.cephBackendConf?.rbd_secret_uuid,
          ceph_client_cinder_secret:
            settings?.blueprint?.config?.cephBackendConf?.ceph_client_cinder_secret,
        }
      : {
          cluster_ip: hostLivenessIp,
          // consul_ip: hostIp,
          // cluster_ip: '',
          consul_ip: '',
          instances_path: settings?.blueprint?.config?.vm_storage_path,
          novncproxy_base_url: `http://${hostIp}:6080/vnc_auto.html`,
          // live_migration_inbound_addr: liveMigrationIp,
          live_migration_inbound_addr: hostLivenessIp,
        }
  return updateHostRole({ hostId: host.id, role: 'pf9-ostackhost-neutron', body: ostackhostBody })
}

export const updateNeutronRoles = async ({ host, settings }) => {
  // const hostIp = getIpForInterface(host, settings?.blueprint?.config?.tunneling_interface)
  const networkingBackend = settings?.blueprint?.config?.networkingType || 'ovn'
  const hasVlanUnderlay = settings?.neutronServer?.network_vlan_ranges?.includes(':')
  const underlayLabel = hasVlanUnderlay
    ? settings?.neutronServer?.network_vlan_ranges
        ?.split(',')
        ?.find((range) => range.includes(':'))
        .split(':')?.[0]
    : null
  const hostIp =
    getIpForInterface(host, settings?.blueprint?.config?.tunneling_interface, settings) ||
    getIpForInterface(host, settings?.blueprint?.config?.vmnet_interface, settings) ||
    getIpForInterface(host, settings?.blueprint?.config?.mgmt_interface, settings)

  // Active PhysicalInterfaces mean they are being used by VM Network or underlay Networks
  const physicalNetworksInterfaceMap =
    settings?.blueprint?.config?.physicalNetworksInterfaceMap || []
  const activePhysicalNetworksInterfaces = physicalNetworksInterfaceMap?.filter(
    (network) => network.isActive,
  )
  const bridgeMappings = activePhysicalNetworksInterfaces.map(
    (network) => `${network.label}:${network.bridgeName}`,
  )
  const interfaceMappings = activePhysicalNetworksInterfaces?.map(
    (network) => `${network.bridgeName}:${network.interface}`,
  )
  const ovnControllerBody = {
    // bridge_mappings: 'vm-net:b-vm-net',
    bridge_mappings: bridgeMappings.join(',') || 'unset',
    ovn_encap_ip: hostIp || 'unset',
    ovn_encap_type: settings?.blueprint?.config?.underlayType === 'vxlan' ? 'vxlan' : 'geneve',
    // interface_mappings: `b-vm-net:${settings?.blueprint?.config?.vmnet_interface}`,
    interface_mappings: interfaceMappings.join(',') || 'unset',
  }
  const ovsAgentBody = {
    bridge_mappings: bridgeMappings.join(','),
    // If underlay type is vlan and there is no vlan underlay, false, otherwise it will be true
    enable_tunneling:
      settings?.blueprint?.config?.underlayType === 'vlan' && !hasVlanUnderlay ? 'False' : 'True',
    tunnel_types: settings?.blueprint?.config?.underlayType,
    local_ip: hostIp,
    net_type: 'vlan,vxlan',
    enable_distributed_routing: settings?.neutronServer?.router_distributed,
    interface_mappings: interfaceMappings.join(','),
  }
  const l3AgentBody = {
    agent_mode: 'dvr_snat',
  }
  const dhcpAgentBody = {
    dnsmasq_dns_servers: '8.8.8.8',
    dns_domain: settings?.neutronServer?.dns_domain,
  }

  // if ovs then use ovs-agent & l3-agent & dhcp-agent & pf9-neutron-metadata-agent
  return networkingBackend === 'ovs'
    ? Promise.allSettled([
        updateHostRole({
          hostId: host.id,
          role: 'pf9-neutron-ovs-agent',
          body: ovsAgentBody,
        }),
        updateHostRole({
          hostId: host.id,
          role: 'pf9-neutron-l3-agent',
          body: l3AgentBody,
        }),
        updateHostRole({
          hostId: host.id,
          role: 'pf9-neutron-dhcp-agent',
          body: dhcpAgentBody,
        }),
        updateHostRole({
          hostId: host.id,
          role: 'pf9-neutron-metadata-agent',
          body: {},
        }),
      ])
    : Promise.allSettled([
        updateHostRole({
          hostId: host.id,
          role: 'pf9-neutron-ovn-controller',
          body: ovnControllerBody,
        }),
        updateHostRole({ hostId: host.id, role: 'pf9-neutron-ovn-metadata-agent', body: {} }),
      ])
}

export const updateImageLibraryRole = async ({ host, settings }) => {
  const body = {
    // if hypervisor role is present, use the bridge name
    // for the associated interface instead of interface
    // from interface_mappings property from ovs-agent or ovn-controller roles
    endpoint_address: getIpForInterface(
      host,
      settings?.blueprint?.config?.[keyForInterface[settings.blueprint.config.imagelib_interface]],
      settings,
    ),
    filesystem_store_datadir: settings?.blueprint?.config?.imagelib_path,
    update_public_glance_endpoint: 'true',
    // create_glance_cluster_endpoint: 'false',
  }
  return updateHostRole({ hostId: host.id, role: 'pf9-glance-role', body })
}

export const updateCinderRoleLvm = async ({ host, settings }) => {
  await updateHostRole({ hostId: host.id, role: 'pf9-cindervolume-base', body: {} })
  const body = {
    // target_ip_address: getIpForInterface(host, settings?.blueprint?.config?.iscsi_interface),
    target_ip_address: getIpForInterface(
      host,
      settings?.blueprint?.config?.iscsi_interface,
      settings,
    ),
    volume_driver: 'cinder.volume.drivers.lvm.LVMVolumeDriver',
    volume_backend_name: 'lvm-backend',
  }
  return updateHostRole({ hostId: host.id, role: 'pf9-cindervolume-lvm', body })
}

export const updateCinderRoleNfs = async ({ host, settings }) => {
  await updateHostRole({ hostId: host.id, role: 'pf9-cindervolume-base', body: {} })
  const body = {
    nfs_mount_points: settings?.blueprint?.config?.nfsBackendConf?.nfs_mount_points,
    volume_backend_name: 'nfs-backend',
    nfs_shares_config: '/opt/pf9/etc/pf9-cindervolume-base/conf.d/nfs_shares',
  }
  return updateHostRole({ hostId: host.id, role: 'pf9-cindervolume-nfs', body })
}

export const updateCinderRoleCeph = async ({ host, settings }) => {
  await updateHostRole({ hostId: host.id, role: 'pf9-cindervolume-base', body: {} })
  const body = {
    volume_backend_name: 'ceph-backend',
    rbd_pool: 'volumes',
    rbd_user: 'cinder',
    rbd_secret_uuid: settings?.blueprint?.config?.cephBackendConf?.rbd_secret_uuid,
  }
  return updateHostRole({ hostId: host.id, role: 'pf9-cindervolume-ceph', body })
}

export const updateCinderRoleOther = async ({ host, settings }) => {
  await updateHostRole({ hostId: host.id, role: 'pf9-cindervolume-base', body: {} })
  const body = {
    volume_backend_name: 'custom',
    volume_driver: settings?.blueprint?.config?.customBackendConf?.volume_driver,
  }
  return updateHostRole({ hostId: host.id, role: 'pf9-cindervolume-other', body })
}

export const updateCinderRoleNetApp = async ({ host, settings }) => {
  await updateHostRole({ hostId: host.id, role: 'pf9-cindervolume-base', body: {} })
  const body = {
    volume_backend_name: 'netapp_backend',
    netapp_server_hostname: settings?.blueprint?.config?.netAppBackendConf?.netapp_server_hostname,
    netapp_login: settings?.blueprint?.config?.netAppBackendConf?.netapp_login,
    netapp_password: settings?.blueprint?.config?.netAppBackendConf?.netapp_password,
    netapp_vserver: settings?.blueprint?.config?.netAppBackendConf?.netapp_vserver,
    netapp_server_port: settings?.blueprint?.config?.netAppBackendConf?.netapp_server_port,
    netapp_storage_protocol:
      settings?.blueprint?.config?.netAppBackendConf?.netapp_storage_protocol,
    ...(settings?.blueprint?.config?.netAppBackendConf?.netapp_lun_space_reservation && {
      netapp_lun_space_reservation: 'enabled',
    }),
    ...(settings?.blueprint?.config?.netAppBackendConf?.netapp_pool_name_search_pattern && {
      netapp_pool_name_search_pattern:
        settings.blueprint.config.netAppBackendConf.netapp_pool_name_search_pattern,
    }),
    ...(settings?.blueprint?.config?.netAppBackendConf?.reserved_percentage && {
      reserved_percentage: settings.blueprint.config.netAppBackendConf.reserved_percentage,
    }),
    ...(settings?.blueprint?.config?.netAppBackendConf?.max_over_subscription_ratio && {
      max_over_subscription_ratio:
        settings.blueprint.config.netAppBackendConf.max_over_subscription_ratio,
    }),
    ...(settings?.blueprint?.config?.netAppBackendConf?.use_multipath_for_image_xfer && {
      use_multipath_for_image_xfer: 'True',
    }),
    ...(settings?.blueprint?.config?.netAppBackendConf?.enforce_multipath_for_image_xfer && {
      enforce_multipath_for_image_xfer: 'True',
    }),
  }
  return updateHostRole({ hostId: host.id, role: 'pf9-cindervolume-netapp', body })
}

export const updateNeutronBaseRole = async ({ host }) => {
  try {
    return updateHostRole({ hostId: host.id, role: 'pf9-neutron-base', body: {} })
  } catch (err) {
    throw new Error(err)
  }
}

export const updateRemoteSupportRole = async ({ host }) => {
  updateHostRole({ hostId: host.id, role: 'pf9-support', body: {} })
}

export const updateCeilometerRole = async ({ host, settings }) => {
  updateHostRole({
    hostId: host.id,
    role: 'pf9-ceilometer',
    body: {},
  })
}

export const updateVmwHypervisorRole = async ({ host, body }) => {
  return updateHostRole({ hostId: host.id, role: 'pf9-ostackhost-neutron-vmw', body })
}

export const updateVmwImageLibraryRole = async ({ host, body }) => {
  return updateHostRole({ hostId: host.id, role: 'pf9-glance-role-vmw', body })
}

export const updateDiscoveryRole = async ({ host, body }) => {
  return updateHostRole({ hostId: host.id, role: 'pf9-discovery-role', body })
}

export const removeHypervisorRole = async ({ hostId, settings }) => {
  const networkingBackend = settings?.blueprint?.config?.networkingType || 'ovn'

  // Must remove in order
  if (networkingBackend === 'ovs') {
    await Promise.allSettled([
      removeHostRole({ hostId, role: 'pf9-ceilometer' }),
      removeHostRole({ hostId, role: 'pf9-neutron-ovs-agent' }),
      removeHostRole({ hostId, role: 'pf9-neutron-l3-agent' }),
      removeHostRole({ hostId, role: 'pf9-neutron-dhcp-agent' }),
      removeHostRole({ hostId, role: 'pf9-neutron-metadata-agent' }),
    ])
  } else {
    await Promise.allSettled([
      removeHostRole({ hostId, role: 'pf9-ceilometer' }),
      removeHostRole({ hostId, role: 'pf9-neutron-ovn-controller' }),
      removeHostRole({ hostId, role: 'pf9-neutron-ovn-metadata-agent' }),
    ])
  }
  await removeHostRole({ hostId, role: 'pf9-ostackhost-neutron' })
  return removeHostRole({ hostId, role: 'pf9-neutron-base' })
}

export const removeImageLibraryRole = async ({ hostId }) => {
  return removeHostRole({ hostId, role: 'pf9-glance-role' })
}

export const removeCinderRoleLvm = async ({ hostId }) => {
  await removeHostRole({ hostId, role: 'pf9-cindervolume-lvm' })
  return removeHostRole({ hostId, role: 'pf9-cindervolume-base' })
}

export const removeCinderRoleNfs = async ({ hostId }) => {
  await removeHostRole({ hostId, role: 'pf9-cindervolume-nfs' })
  return removeHostRole({ hostId, role: 'pf9-cindervolume-base' })
}

export const removeCinderRoleCeph = async ({ hostId }) => {
  await removeHostRole({ hostId, role: 'pf9-cindervolume-ceph' })
  return removeHostRole({ hostId, role: 'pf9-cindervolume-base' })
}

export const removeCinderRoleOther = async ({ hostId }) => {
  await removeHostRole({ hostId, role: 'pf9-cindervolume-other' })
  return removeHostRole({ hostId, role: 'pf9-cindervolume-base' })
}

export const removeCinderRoleNetApp = async ({ hostId }) => {
  await removeHostRole({ hostId, role: 'pf9-cindervolume-netapp' })
  return removeHostRole({ hostId, role: 'pf9-cindervolume-base' })
}

export const removeRemoteSupportRole = async ({ hostId }) => {
  return removeHostRole({ hostId, role: 'pf9-support' })
}

export const getResmgrRoleConfig = async ({ role, host }) => {
  return resMgr.getRole(host.id, role)
}

export const updateHostRole = async ({ hostId, role, body }) => {
  return resMgr.addRole(hostId, role, body)
}

export const removeHostRole = async ({ hostId, role }) => {
  return resMgr.removeRole(hostId, role)
}

export const getBlueprints = async () => {
  return resMgr.getBlueprints()
}

export const getBlueprint = async ({ name }) => {
  return resMgr.getBlueprint({ name })
}

export const createBlueprint = async ({ name, body }) => {
  return resMgr.createBlueprint({ body })
}

export const updateBlueprint = async ({ name, body }) => {
  return resMgr.updateBlueprint({ name, body })
}

// Todo: Convert this into a action set
export const getHostNetworkConfigs = async () => {
  return resMgr.getHostNetworkConfigs()
}

export const createHostNetworkConfigs = async (body) => {
  return resMgr.createHostNetworkConfigs(body)
}

export const updateHostNetworkConfig = async ({ id, body }) => {
  return resMgr.updateHostNetworkConfig({ id, body })
}

export const deleteHostNetworkConfig = async ({ id }) => {
  return resMgr.deleteHostNetworkConfig(id)
}

export const getResmgrServiceConfig: any = async (service) => {
  return resMgr.getService(service)
}

export const updateResmgrServiceConfig = async (service, body) => {
  try {
    const response = await resMgr.updateService(service, body)
    return response
  } catch (e) {
    return new Error(e?.err?.message)
  }
}

export const getHostAggregateConfig: any = async (aggregateId) => {
  return haMgr.getHaConfig(aggregateId)
}

export const enableHighAvailability: any = async (aggregateId) => {
  return haMgr.enableHa(aggregateId)
}

export const disableHighAvailability: any = async (aggregateId) => {
  return haMgr.disableHa(aggregateId)
}

export const hypervisorActions = ActionsSet.make<DataKeys.Hypervisors>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.Hypervisors,
  cacheKey: DataKeys.Hypervisors,
})

export const listHypervisors = hypervisorActions.add(
  new ListAction<DataKeys.Hypervisors>(async () => {
    return nova.getHypervisors()
  }),
)

export const enableHypervisorScheduling = hypervisorActions.add(
  new CustomAction<DataKeys.Hypervisors, { id: string; body?: any }>(
    'enableHypervisorScheduling',
    async ({ id, body }) => {
      await nova.enableHypervisorScheduling(body)
      const hypervisor = await nova.getHypervisor(id)
      return hypervisor
    },
    (result, { id }) => {
      // Update the cluster in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.Hypervisors,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
    },
  ),
)

export const disableHypervisorScheduling = hypervisorActions.add(
  new CustomAction<DataKeys.Hypervisors, { id: string; body?: any }>(
    'disableHypervisorScheduling',
    async ({ id, body }) => {
      await nova.disableHypervisorScheduling(body)
      const hypervisor = await nova.getHypervisor(id)
      return hypervisor
    },
    (result, { id }) => {
      // Update the cluster in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.Hypervisors,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
    },
  ),
)

export const hostAggregateActions = ActionsSet.make<DataKeys.HostAggregates>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.HostAggregates,
  cacheKey: DataKeys.HostAggregates,
})

export const listHostAggregates = hostAggregateActions.add(
  new ListAction<DataKeys.HostAggregates>(async () => {
    return nova.getHostAggregates()
  })
    .addDependency(DataKeys.ResmgrHosts)
    .addDependency(DataKeys.Hypervisors),
)

export const createHostAggregate = hostAggregateActions.add(
  new CreateAction<DataKeys.HostAggregates, { body }>(async ({ body }) => {
    const created = await nova.createHostAggregate(body)
    const aggregateId = created.id
    const newAggregate = await nova.getHostAggregate(aggregateId)
    return newAggregate
  }),
)

export const updateHostAggregate = hostAggregateActions.add(
  new UpdateAction<
    DataKeys.HostAggregates,
    {
      id: string
      body: unknown
    }
  >(async ({ id, body }) => {
    const updatedHostAggregate = await nova.updateHostAggregate({ id, body })
    return updatedHostAggregate
  }),
)

export const addHostToAggregate = hostAggregateActions.add(
  new CustomAction<DataKeys.HostAggregates, { id: string; body?: any }>(
    'addHostToAggregate',
    async ({ id, body }) => {
      await nova.hostAggregateAction({ id, body })
      const aggregate = await nova.getHostAggregate(id)
      return aggregate
    },
    (result, { id }) => {
      // Update the cluster in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.HostAggregates,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
    },
  ),
)

export const removeHostFromAggregate = hostAggregateActions.add(
  new CustomAction<DataKeys.HostAggregates, { id: string; body?: any }>(
    'removeHostFromAggregate',
    async ({ id, body }) => {
      await nova.hostAggregateAction({ id, body })
      const aggregate = await nova.getHostAggregate(id)
      return aggregate
    },
    (result, { id }) => {
      // Update the cluster in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.HostAggregates,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
    },
  ),
)

export const updateHostAggregateMetadata = hostAggregateActions.add(
  new CustomAction<DataKeys.HostAggregates, { id: string; body?: any }>(
    'updateHostAggregateMetadata',
    async ({ id, body }) => {
      await nova.hostAggregateAction({ id, body })
      const aggregate = await nova.getHostAggregate(id)
      return aggregate
    },
    (result, { id }) => {
      // Update the cluster in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.HostAggregates,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
    },
  ),
)

export const deleteHostAggregate = hostAggregateActions.add(
  new DeleteAction<DataKeys.HostAggregates, { id: string }>(async ({ id }) => {
    await nova.deleteHostAggregate(id)
  }),
)

export const getVmsForHost = (hostId) => {
  return nova.getInstancesForHost(hostId)
}
