import React, { useCallback, useMemo, useState } from 'react'
import useParams from 'core/hooks/useParams'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createStack } from './actions'
import ModalForm from 'core/elements/modal/ModalForm'
import { Route } from 'core/plugins/route'
import TextField from 'core/components/validatedForm/TextField'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import TextFileDrop from 'core/elements/grid/TextFileDrop'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import { requiredValidator, jsonValidator, yamlValidator } from 'core/utils/fieldValidators'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'

const codeMirrorOptions = {
  mode: 'json',
}

const templateValidations = [requiredValidator, yamlValidator]
const codeMirrorValidations = [requiredValidator, jsonValidator]

const useStyles = makeStyles<Theme>((theme) => ({
  withUnits: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  unitsDropdown: {
    position: 'relative',
    bottom: 1,
  },
  error: {
    marginLeft: 4,
    color: theme.components.input.error,
    lineHeight: '16px',
  },
}))

interface Props {
  addRoute: Route
}

export default function CreateStackModal({ addRoute }: Props) {
  const { history } = useReactRouter()
  const classes = useStyles()
  const [showFileError, setShowFileError] = useState(false)
  const defaultParams = {
    name: '',
    creationTimeout: 60,
    template: '',
    dependentTemplates: {},
    parameters: '{}',
    showTemplate: false,
    templateYaml: '',
  }
  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)
  console.log(params, 'params')

  const { update, updating, error, reset } = useUpdateAction(createStack)

  const submitForm = useCallback(async () => {
    if (!params.template) {
      setShowFileError(true)
      return
    }
    setShowFileError(false)
    const body = {
      stack_name: params.name,
      timeout_mins: params.creationTimeout,
      template: params.templateYaml,
      files: params.dependentTemplates,
      parameters: JSON.parse(params.parameters),
    }
    const { success } = await update({ body })
    if (success) handleClose()
  }, [params])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(routes.openstack.stacks.path())
  }

  return (
    <ModalForm
      route={addRoute}
      title={`Create Stack`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      submitTitle={`Create Stack`}
    >
      <>
        <FormFieldSection title="Stack Configuration">
          <TextField
            id="name"
            label="Name"
            onChange={getParamsUpdater('name')}
            value={params.name}
            info="Choose a name for this stack"
            required
          />
          <TextField
            id="creationTimeout"
            label="Creation Timeout (minutes)"
            // @ts-ignore complaint about string vs number
            onChange={getParamsUpdater('creationTimeout')}
            value={params.creationTimeout}
            type="number"
            min={1}
            info="Stack creation will time out after this duration (specified in minutes)"
          />
        </FormFieldSection>
        <FormFieldSection title="Stack Template">
          <Text variant="body2">Select your main template file.</Text>
          <TextFileDrop
            onChange={(value) => updateParams({ template: value, templateYaml: value })}
            fileTypes={['.yaml', '.yml', '.template']}
          />
          <ToggleSwitchField
            id="showTemplate"
            info="Revise the uploaded template or write your own."
            label="Show Template YAML"
            value={params.showTemplate}
            onChange={getParamsUpdater('showTemplate')}
          />
          {params.showTemplate && (
            <CodeMirror
              variant="light"
              id="templateYaml"
              validations={templateValidations}
              onChange={getParamsUpdater('templateYaml')}
              value={params.templateYaml}
            />
          )}
          {showFileError && (
            <Text variant="body2" className={classes.error}>
              Having a main template file is required.
            </Text>
          )}
        </FormFieldSection>
        <FormFieldSection title="Dependent Templates">
          <Text variant="body2">
            Select your dependent templates, if applicable. The template file names must match those
            defined in your main template.
          </Text>
          <TextFileDrop
            onChange={(value) => updateParams({ dependentTemplates: value })}
            fileTypes={['.yaml', '.yml']}
            maxFiles={0}
          />
        </FormFieldSection>
        <FormFieldSection title="Stack Parameters">
          <Text variant="body2">
            Please paste the stack parameters in JSON format. These parameters, if any, are defined
            in the main yaml template. E.g. {`{"param1": 123, "param2": "value"}`}
          </Text>
          <CodeMirror
            variant="light"
            id="parameters"
            validations={codeMirrorValidations}
            onChange={getParamsUpdater('parameters')}
            value={params.parameters}
            options={codeMirrorOptions}
          />
        </FormFieldSection>
      </>
    </ModalForm>
  )
}
