import React, { useMemo } from 'react'
import Dropdown from 'core/elements/dropdown'
import withProgress from 'core/components/progress/withProgress'
import useListAction from 'core/hooks/useListAction'
import { listFloatingIps } from 'openstack/components/networks/floating-ips/actions'
import { useSelector } from 'react-redux'
import { floatingIpsByDeviceIdSelector } from 'openstack/components/networks/floating-ips/selectors'

const AsyncDropdown = withProgress(Dropdown, { inline: true })

export default function UsedFloatingIpsPicklist({
  disabled = false,
  name = 'floatingIp',
  label = 'Public IP',
  vmId,
  ...rest
}) {
  const { loading } = useListAction(listFloatingIps)
  const floatingIpsByDeviceId = useSelector(floatingIpsByDeviceIdSelector)

  const vmFloatingIps = useMemo(() => {
    return floatingIpsByDeviceId[vmId] || []
  }, [floatingIpsByDeviceId])

  const options = useMemo(() => {
    return vmFloatingIps.map((floatingIp) => ({
      label: `${floatingIp.floating_ip_address} (Attached to private IP ${floatingIp.fixed_ip_address})`,
      value: floatingIp.id,
    }))
  }, [vmFloatingIps])

  return (
    <AsyncDropdown
      {...rest}
      name={name}
      label={label}
      compact={false}
      disabled={disabled}
      loading={loading}
      items={options}
    />
  )
}
