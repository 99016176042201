// libs
import React, { useEffect, useMemo, useState } from 'react'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { prop } from 'ramda'
import { useSelector } from 'react-redux'

// Components
import Text from 'core/elements/Text'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { RootState } from 'app/store'
import DocumentMeta from 'core/components/DocumentMeta'
import { HeaderTitlePortal } from 'core/elements/header/portals'
import {
  getCinderQuotaUsage,
  getNetworkQuotaUsage,
  getComputeQuotaUsage,
} from 'account/components/userManagement/tenants/new-actions'
import { PieChart, Pie, Cell } from 'recharts'
import { makeStyles, useTheme } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Card from 'core/elements/card'

const PieGraph = ({
  used = 0,
  capacity = 0,
  sideLength = 100,
  arcWidth = 12,
  startAngle = 90,
  endAngle = -270,
  width = sideLength + 10,
  height = sideLength + 10,
}) => {
  const theme: Theme = useTheme()
  const radius = Math.floor(sideLength / 2)
  const emptyData = useMemo(
    () => [{ name: 'unknown', value: 1, color: theme.components.graph.tray }],
    [theme.components.graph.tray],
  )
  const unlimitedData = useMemo(
    () => [{ name: 'unknown', value: 1, color: theme.components.graph.primary }],
    [theme.components.graph.primary],
  )
  const data = [
    { name: 'used', value: used, color: theme.components.graph.success },
    { name: 'unused', value: capacity - used, color: theme.components.graph.tray },
  ]
  const items = used === 0 ? emptyData : capacity === -1 ? unlimitedData : data

  return (
    <PieChart width={width} height={height}>
      <Pie
        dataKey="value"
        data={items}
        startAngle={startAngle}
        endAngle={endAngle}
        cx={radius}
        cy={radius}
        innerRadius={radius - arcWidth}
        outerRadius={radius}
        paddingAngle={0}
      >
        {items.map((entry) => (
          <Cell key={entry.name} fill={entry.color} />
        ))}
      </Pie>
    </PieChart>
  )
}

const DashboardPage = () => {
  const classes = useStyles()
  const { prefs } = useScopedPreferences()
  const { currentTenant } = prefs
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const {
    userDetails: { displayName, role, id: userId },
    features,
  } = session

  const [quotas, setQuotas] = useState<any>({})

  useEffect(() => {
    if (!userId || !currentTenant) {
      return
    }
    const makeCalls = async () => {
      let computeQuotas = {}
      let cinderQuotas = {}
      let networkQuotas = {}
      try {
        computeQuotas = await getComputeQuotaUsage({ tenantId: currentTenant })
      } catch (err) {
        console.error(err)
      }
      try {
        cinderQuotas = await getCinderQuotaUsage({ tenantId: currentTenant })
      } catch (err) {
        console.error(err)
      }
      try {
        networkQuotas = await getNetworkQuotaUsage({ tenantId: currentTenant })
      } catch (err) {
        console.error(err)
      }
      setQuotas({
        ...computeQuotas,
        ...cinderQuotas,
        ...networkQuotas,
      })
    }
    makeCalls()
  }, [userId, currentTenant])

  return (
    <>
      <DocumentMeta title="Dashboard" />
      <HeaderTitlePortal>
        <Text id="dashboard-title" variant="subtitle1">
          Welcome{displayName ? ` ${displayName}` : ''}!
        </Text>
      </HeaderTitlePortal>
      <div className={classes.cards}>
        <Card title="Compute Quotas" className={classes.card}>
          <div className={classes.cardBody}>
            <div className={classes.statistic}>
              <div className={classes.center}>
                <PieGraph used={quotas?.cores?.in_use} capacity={quotas?.cores?.limit} />
              </div>
              <Text variant="body2">
                {quotas?.cores?.in_use} /{' '}
                {quotas?.cores?.limit === -1 ? 'unlimited' : quotas?.cores?.limit} used
              </Text>
              <Text variant="body2">Cores</Text>
            </div>
            <div className={classes.statistic}>
              <div className={classes.center}>
                <PieGraph used={quotas?.ram?.in_use} capacity={quotas?.ram?.limit} />
              </div>
              <Text variant="body2">
                {quotas?.ram?.in_use} /{' '}
                {quotas?.ram?.limit === -1 ? 'unlimited' : quotas?.ram?.limit} MiB used
              </Text>
              <Text variant="body2">RAM</Text>
            </div>
            <div className={classes.statistic}>
              <div className={classes.center}>
                <PieGraph used={quotas?.instances?.in_use} capacity={quotas?.instances?.limit} />
              </div>
              <Text variant="body2">
                {quotas?.instances?.in_use} /{' '}
                {quotas?.instances?.limit === -1 ? 'unlimited' : quotas?.instances?.limit} used
              </Text>
              <Text variant="body2">VMs</Text>
            </div>
          </div>
        </Card>
        <Card title="Block Storage Quotas" className={classes.card}>
          <div className={classes.cardBody}>
            <div className={classes.statistic}>
              <div className={classes.center}>
                <PieGraph used={quotas?.gigabytes?.in_use} capacity={quotas?.gigabytes?.limit} />
              </div>
              <Text variant="body2">
                {quotas?.gigabytes?.in_use} /{' '}
                {quotas?.gigabytes?.limit === -1 ? 'unlimited' : quotas?.gigabytes?.limit} used
              </Text>
              <Text variant="body2">Total Block Storage (GB)</Text>
            </div>
            <div className={classes.statistic}>
              <div className={classes.center}>
                <PieGraph used={quotas?.volumes?.in_use} capacity={quotas?.volumes?.limit} />
              </div>
              <Text variant="body2">
                {quotas?.volumes?.in_use} /{' '}
                {quotas?.volumes?.limit === -1 ? 'unlimited' : quotas?.volumes?.limit} used
              </Text>
              <Text variant="body2">Volumes</Text>
            </div>
            <div className={classes.statistic}>
              <div className={classes.center}>
                <PieGraph used={quotas?.snapshots?.in_use} capacity={quotas?.snapshots?.limit} />
              </div>
              <Text variant="body2">
                {quotas?.snapshots?.in_use} /{' '}
                {quotas?.snapshots?.limit === -1 ? 'unlimited' : quotas?.snapshots?.limit} used
              </Text>
              <Text variant="body2">Volume Snapshots</Text>
            </div>
            <div className={classes.statistic}>
              <div className={classes.center}>
                <PieGraph
                  used={quotas?.per_volume_gigabytes?.in_use}
                  capacity={quotas?.per_volume_gigabytes?.limit}
                />
              </div>
              <Text variant="body2">
                {quotas?.per_volume_gigabytes?.in_use} /{' '}
                {quotas?.per_volume_gigabytes?.limit === -1
                  ? 'unlimited'
                  : quotas?.per_volume_gigabytes?.limit}{' '}
                used
              </Text>
              <Text variant="body2">Max Volume Size (GB)</Text>
            </div>
          </div>
        </Card>
        <Card title="Network Quotas" className={classes.card}>
          <div className={classes.cardBody}>
            <div className={classes.statistic}>
              <div className={classes.center}>
                <PieGraph used={quotas?.network?.used} capacity={quotas?.network?.limit} />
              </div>
              <Text variant="body2">
                {quotas?.network?.used} /{' '}
                {quotas?.network?.limit === -1 ? 'unlimited' : quotas?.network?.limit} used
              </Text>
              <Text variant="body2">Networks</Text>
            </div>
            <div className={classes.statistic}>
              <div className={classes.center}>
                <PieGraph used={quotas?.subnet?.used} capacity={quotas?.subnet?.limit} />
              </div>
              <Text variant="body2">
                {quotas?.subnet?.used} /{' '}
                {quotas?.subnet?.limit === -1 ? 'unlimited' : quotas?.subnet?.limit} used
              </Text>
              <Text variant="body2">Subnets</Text>
            </div>
            <div className={classes.statistic}>
              <div className={classes.center}>
                <PieGraph used={quotas?.router?.used} capacity={quotas?.router?.limit} />
              </div>
              <Text variant="body2">
                {quotas?.router?.used} /{' '}
                {quotas?.router?.limit === -1 ? 'unlimited' : quotas?.router?.limit} used
              </Text>
              <Text variant="body2">Routers</Text>
            </div>
            <div className={classes.statistic}>
              <div className={classes.center}>
                <PieGraph used={quotas?.port?.used} capacity={quotas?.port?.limit} />
              </div>
              <Text variant="body2">
                {quotas?.port?.used} /{' '}
                {quotas?.port?.limit === -1 ? 'unlimited' : quotas?.port?.limit} used
              </Text>
              <Text variant="body2">Ports</Text>
            </div>
            <div className={classes.statistic}>
              <div className={classes.center}>
                <PieGraph used={quotas?.floatingip?.used} capacity={quotas?.floatingip?.limit} />
              </div>
              <Text variant="body2">
                {quotas?.floatingip?.used} /{' '}
                {quotas?.floatingip?.limit === -1 ? 'unlimited' : quotas?.floatingip?.limit} used
              </Text>
              <Text variant="body2">Public IPs</Text>
            </div>
            <div className={classes.statistic}>
              <div className={classes.center}>
                <PieGraph
                  used={quotas?.security_group?.used}
                  capacity={quotas?.security_group?.limit}
                />
              </div>
              <Text variant="body2">
                {quotas?.security_group?.used} /{' '}
                {quotas?.security_group?.limit === -1 ? 'unlimited' : quotas?.security_group?.limit}{' '}
                used
              </Text>
              <Text variant="body2">Security Groups</Text>
            </div>
            <div className={classes.statistic}>
              <div className={classes.center}>
                <PieGraph
                  used={quotas?.security_group_rule?.used}
                  capacity={quotas?.security_group_rule?.limit}
                />
              </div>

              <Text variant="body2">
                {quotas?.security_group_rule?.used} /{' '}
                {quotas?.security_group_rule?.limit === -1
                  ? 'unlimited'
                  : quotas?.security_group_rule?.limit}{' '}
                used
              </Text>
              <Text variant="body2">Security Group Rules</Text>
            </div>
          </div>
        </Card>
      </div>
    </>
  )
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  cards: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 16,
  },
  card: {
    width: 'max-content',
  },
  cardBody: {
    display: 'flex',
    gap: 24,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  statistic: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
}))

export default DashboardPage
