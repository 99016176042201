import { makeStyles } from '@material-ui/styles'
import Text from 'core/elements/Text'
import React, { useEffect, useMemo, useState } from 'react'
import Modal from 'core/elements/modal'
import Button from 'core/elements/button/Button'
import { getVmEventDetails } from '../actions'
import Progress from 'core/components/progress/Progress'
import moment from 'moment'
import { reverse } from 'ramda'
import Grid from 'core/elements/grid'
import { GridViewColumn } from 'core/elements/grid/Grid'

const maxDialogWidth = 1000

const columns: GridViewColumn<any>[] = [
  {
    key: 'event',
    label: 'Event',
  },
  {
    key: 'start_time',
    label: 'Start Time',
    render: (time) => moment(time).format('LL LTS'),
  },
  {
    key: 'finish_time',
    label: 'Finish Time',
    render: (time) => moment(time).format('LL LTS'),
  },
  {
    key: 'result',
    label: 'Result',
  },
]

const TaskDetailsDialog = ({ vm, requestId, onClose }) => {
  const classes = useStyles()
  const [detailsLoaded, setDetailsLoaded] = useState(false)
  const [taskDetails, setTaskDetails] = useState(null)
  const reversedEvents = useMemo<any>(() => {
    if (taskDetails) {
      return reverse(taskDetails?.events)
    }
    return []
  }, [taskDetails])

  useEffect(() => {
    const getDetails = async () => {
      const details = await getVmEventDetails({ vmId: vm.id, requestId })
      setTaskDetails(details)
      setDetailsLoaded(true)
    }
    getDetails()
  }, [vm, requestId])

  return (
    <Modal
      title="Task Details"
      onClose={onClose}
      maxWidth={maxDialogWidth}
      panel="dialog"
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </>
      }
      open
    >
      <Progress loading={!detailsLoaded}>
        <div className={classes.details}>
          <Grid
            uniqueIdentifier="event"
            data={reversedEvents}
            columns={columns}
            compact
            disableToolbar
          />
        </div>
      </Progress>
    </Modal>
  )
}

export default TaskDetailsDialog

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'grid',
    gap: 16,
  },
  events: {
    display: 'grid',
    gridTemplateColumns: 'max-content auto',
    gap: '8px 16px',
  },
  label: {
    fontWeight: 600,
  },
}))
