import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'

export const stacksSelector = createSharedSelector(
  getDataSelector<DataKeys.Stacks>(DataKeys.Stacks),
  (stacks) => {
    return stacks.map((stack) => {
      // any model changes go here
      return stack
    })
  },
)

export const stacksByIdSelector = createSharedSelector(stacksSelector, (stacks) => {
  return stacks.reduce((accum, stack) => {
    return {
      ...accum,
      [stack.id]: stack,
    }
  }, {})
})
