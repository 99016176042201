import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useParams from 'core/hooks/useParams'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import {
  updateHostAggregate,
  listHypervisors,
  addHostToAggregate,
  removeHostFromAggregate,
  updateHostAggregateMetadata,
} from '../actions'
import { hypervisorsSelector } from '../selectors'
import ModalForm from 'core/elements/modal/ModalForm'
import TextField from 'core/components/validatedForm/TextField'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { useSelector } from 'react-redux'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import PicklistField from 'core/components/validatedForm/DropdownField'
import AvailabilityZonesPicklist from '../availability-zones/AvailabilityZonesPicklist'
import KeyValuesField from 'core/components/validatedForm/KeyValuesField'
import ListTableField from 'core/components/validatedForm/ListTableField'
import useListAction from 'core/hooks/useListAction'
import { listAvailabilityZones } from '../availability-zones/actions'
import { without } from 'ramda'

const useStyles = makeStyles<Theme>((theme) => ({}))

interface Props {
  rows: any[]
  onClose: () => void
}

const hypervisorColumns = [
  {
    id: 'hypervisor_hostname',
    label: 'Host',
  },
  {
    id: 'host_ip',
    label: 'IP Address',
  },
]

export default function EditHostAggregateModal({ rows: [hostAggregate], onClose }: Props) {
  const classes = useStyles()
  const initialKeys = useMemo(() => {
    return Object.keys(hostAggregate.metadata).filter((key) => key !== 'availability_zone')
  }, [hostAggregate.metadata])

  const defaultParams = useMemo(
    () => ({
      name: hostAggregate.name,
      // availabilityZone: hostAggregate.availability_zone ? hostAggregate.availability_zone : '',
      // newAvailabilityZone: false,
      // newAvailabilityZoneName: '',
      hosts: [],
      metadata: Object.entries(hostAggregate.metadata)
        .map(([key, value]) => {
          return { key, value }
        })
        .filter(({ key }) => key !== 'availability_zone'),
    }),
    [hostAggregate],
  )

  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)
  const [submitting, setSubmitting] = useState(false)

  const { update, updating, error, reset } = useUpdateAction(updateHostAggregate)
  const {
    update: addHost,
    updating: addingHost,
    error: errorAddingHost,
    reset: resetAddHost,
  } = useUpdateAction(addHostToAggregate)
  const {
    update: removeHost,
    updating: removingHost,
    error: errorRemovingHost,
    reset: resetRemoveHost,
  } = useUpdateAction(removeHostFromAggregate)
  const {
    update: updateMetadata,
    updating: updatingMetadata,
    error: errorUpdatingMetadata,
    reset: resetUpdateMetadata,
  } = useUpdateAction(updateHostAggregateMetadata)

  // const { reload: reloadAvailabilityZones } = useListAction(listAvailabilityZones)
  const { loading: loadingHypervisors } = useListAction(listHypervisors, {
    params: {},
  })
  const hypervisorsList = useSelector(hypervisorsSelector)

  useEffect(() => {
    const selectedHypervisors = hypervisorsList.filter((hypervisor) => {
      return hostAggregate.hosts?.includes(hypervisor?.service?.host)
    })
    updateParams({ hosts: selectedHypervisors })
  }, [hostAggregate.hosts, hypervisorsList])

  const submitForm = useCallback(async () => {
    setSubmitting(true)
    const body = {
      aggregate: {
        name: params.name,
        // availability_zone: params.newAvailabilityZone
        //   ? params.newAvailabilityZoneName
        //   : params.availabilityZone
        //   ? params.availabilityZone
        //   : undefined,
      },
    }
    const metadata = params.metadata.reduce((accum, pair) => {
      // Don't let user set availability zone
      if (pair.key === 'availability_zone') {
        return accum
      }
      return {
        ...accum,
        [pair.key]: pair.value,
      }
    }, {})
    const currentKeys = Object.keys(metadata)
    const removedKeys = without(currentKeys, initialKeys)
    const removeObject = removedKeys.reduce((accum, key) => {
      return {
        ...accum,
        [key]: null,
      }
    }, {})
    const metadataBody = {
      set_metadata: {
        metadata: {
          ...metadata,
          ...removeObject,
        },
      },
    }
    const hostIdsToAdd = params.hosts
      .filter((host) => {
        return !hostAggregate.hosts.includes(host.service.host)
      })
      .map((host) => host.service.host)
    const hostIdsToRemove = hostAggregate.hosts.filter((host) => {
      const desiredHostIds = params.hosts.map((host) => host.service.host)
      return !desiredHostIds.includes(host)
    })

    const { success } = await update({ id: hostAggregate.id, body })
    if (!success) {
      setSubmitting(false)
      return
    }
    updateMetadata({ id: hostAggregate.id, body: metadataBody })
    const addPromises = hostIdsToAdd.map((host) => {
      return addHost({
        id: hostAggregate.id,
        body: {
          add_host: {
            host: host,
          },
        },
      })
    })
    const removePromises = hostIdsToRemove.map((host) => {
      return removeHost({
        id: hostAggregate.id,
        body: {
          remove_host: {
            host: host,
          },
        },
      })
    })
    await Promise.allSettled([...addPromises, ...removePromises])
    handleClose()
  }, [hostAggregate, params])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    resetAddHost()
    resetRemoveHost()
    resetUpdateMetadata()
    // reloadAvailabilityZones(true, true)
    onClose()
  }

  return (
    <ModalForm
      title={`Edit Host Aggregate`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={submitting}
      error={error}
      submitTitle={`Update Host Aggregate`}
      open
    >
      <>
        <FormFieldSection title="Host Aggregate Settings">
          <TextField
            id="name"
            label="Name"
            onChange={getParamsUpdater('name')}
            value={params.name}
            required
          />
          {/* <CheckboxField
            id="newAvailabilityZone"
            label="Create New Availability Zone"
            value={params.newAvailabilityZone}
            onChange={getParamsUpdater('newAvailabilityZone')}
          />
          {!!params.newAvailabilityZone && (
            <TextField
              id="newAvailabilityZoneName"
              label="New Availability Zone Name"
              onChange={getParamsUpdater('newAvailabilityZoneName')}
              value={params.newAvailabilityZoneName}
              required
            />
          )}
          {!params.newAvailabilityZone && (
            <PicklistField
              DropdownComponent={AvailabilityZonesPicklist}
              name="availabilityZone"
              id="availabilityZone"
              label="Availability Zone"
              compact={false}
              value={params.availabilityZone}
              onChange={getParamsUpdater('availabilityZone')}
              includeNoneOption
            />
          )} */}
          <KeyValuesField
            id="metadata"
            label="Metadata"
            value={params.metadata}
            onChange={getParamsUpdater('metadata')}
            addLabel="Add Metadata"
            allowMultipleValues
          />
        </FormFieldSection>
        <FormFieldSection title="Select Hosts to Add">
          <ListTableField
            id="hosts"
            data={hypervisorsList}
            loading={loadingHypervisors}
            columns={hypervisorColumns}
            onChange={getParamsUpdater('hosts')}
            value={params.hosts}
            uniqueIdentifier="id"
            searchTargets={['hypervisor_hostname']}
            multiSelection
          />
        </FormFieldSection>
      </>
    </ModalForm>
  )
}
