import React from 'react'
import Dropdown, { DropdownProps } from 'core/elements/dropdown/Dropdown'
import { IPVersions } from 'app/constants'

export const IPOptions = [
  { label: 'IPv4', value: IPVersions.IPv4 },
  { label: 'IPv6', value: IPVersions.IPv6 },
]

export default function IpVersionPicklist({
  name = 'ipVersion',
  label = 'IP Version',
  compact = false,
  ...rest
}) {
  return <Dropdown compact={compact} name={name} label={label} items={IPOptions} {...rest} />
}
