import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import DeleteAction from 'core/actions/DeleteAction'
// import CustomAction from 'core/actions/CustomAction'
import CreateAction from 'core/actions/CreateAction'
import UpdateAction from 'core/actions/UpdateAction'
import store from 'app/store'

const { dispatch } = store
const { neutron } = ApiClient.getInstance()

export const floatingIpActions = ActionsSet.make<DataKeys.FloatingIps>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.FloatingIps,
  cacheKey: DataKeys.FloatingIps,
})

export const listFloatingIps = floatingIpActions.add(
  new ListAction<DataKeys.FloatingIps>(async () => {
    return neutron.getFloatingIps()
  })
    .addDependency(DataKeys.OpenstackVirtualMachines)
    .addDependency(DataKeys.OpenstackNetworks),
)

export const createFloatingIp = floatingIpActions.add(
  new CreateAction<DataKeys.FloatingIps, { body }>(async ({ body }) => {
    const created = await neutron.createFloatingIp(body)
    const floatingIpId = created.id
    const newFloatingIp = await neutron.getFloatingIp(floatingIpId)
    return newFloatingIp
  }),
)

export const updateFloatingIp = floatingIpActions.add(
  new UpdateAction<
    DataKeys.FloatingIps,
    {
      id: string
      body: unknown
    }
  >(async ({ id, body }) => {
    const updatedFloatingIp = await neutron.updateFloatingIp({ id, body })
    return updatedFloatingIp
  }),
)

export const deleteFloatingIp = floatingIpActions.add(
  new DeleteAction<DataKeys.FloatingIps, { id: string }>(async ({ id }) => {
    await neutron.deleteFloatingIp(id)
  }),
)
