import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import DeleteAction from 'core/actions/DeleteAction'
import CustomAction from 'core/actions/CustomAction'
import CreateAction from 'core/actions/CreateAction'
import UpdateAction from 'core/actions/UpdateAction'
import store from 'app/store'
import { noop } from 'utils/fp'

const { dispatch } = store
const { neutron } = ApiClient.getInstance()

export const routerActions = ActionsSet.make<DataKeys.OpenstackRouters>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.OpenstackRouters,
  cacheKey: DataKeys.OpenstackRouters,
})

export const listRouters = routerActions.add(
  new ListAction<DataKeys.OpenstackRouters>(async () => {
    return neutron.getRouters()
  }).addDependency(DataKeys.OpenstackNetworks),
)

export const createRouter = routerActions.add(
  new CreateAction<DataKeys.OpenstackRouters, { body }>(async ({ body }) => {
    const created = await neutron.createRouter(body)
    const routerId = created.id
    const newRouter = await neutron.getRouter(routerId)
    return newRouter
  }),
)

export const updateRouter = routerActions.add(
  new UpdateAction<
    DataKeys.OpenstackRouters,
    {
      id: string
      body: unknown
    }
  >(async ({ id, body }) => {
    const updatedRouter = await neutron.updateRouter({ id, body })
    return updatedRouter
  }),
)

export const deleteRouter = routerActions.add(
  new DeleteAction<DataKeys.OpenstackRouters, { id: string }>(async ({ id }) => {
    await neutron.deleteRouter(id)
  }),
)

export const createRouterInterface = routerActions.add(
  new CustomAction<DataKeys.OpenstackRouters, { id: string; body?: any }>(
    'createRouterInterface',
    async ({ id, body }) => {
      await neutron.addInterface({ id, body })
    },
    (result, { id }) => {
      noop()
    },
  ),
)

export const removeRouterInterface = routerActions.add(
  new CustomAction<DataKeys.OpenstackRouters, { id: string; body?: any }>(
    'removeRouterInterface',
    async ({ id, body }) => {
      await neutron.removeInterface({ id, body })
    },
    (result, { id }) => {
      noop()
    },
  ),
)
