import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

const options = [
  { label: 'GiB', value: 'GiB' },
  { label: 'TiB', value: 'TiB' },
]

interface Props {}

export default function UnitPicklist({
  name = 'unit',
  label = 'Unit',
  compact = false,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  return <AsyncDropdown compact={compact} name={name} label={label} items={options} {...rest} />
}
