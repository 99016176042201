import React, { useMemo } from 'react'
import Dropdown from 'core/elements/dropdown'
import withProgress from 'core/components/progress/withProgress'
import useListAction from 'core/hooks/useListAction'
import { listNetworks } from 'openstack/components/networks/actions'
import { useSelector } from 'react-redux'
import { networksSelector } from 'openstack/components/networks/selectors'
import useScopedPreferences from 'core/session/useScopedPreferences'

const AsyncDropdown = withProgress(Dropdown, { inline: true })

export default function NetworkPicklist({
  disabled = false,
  name = 'network',
  label = 'Network',
  ...rest
}) {
  const { prefs } = useScopedPreferences()
  const { currentTenant } = prefs

  const { loading } = useListAction(listNetworks)
  const data = useSelector(networksSelector)

  const networksInTenant = useMemo(() => {
    return data.filter((network) => {
      return network.project_id === currentTenant || network.shared
    })
  }, [data, currentTenant])

  const options = useMemo(() => {
    // will need to filter out taken private IPs
    return networksInTenant.map((network) => {
      return {
        label: network.name || network.id,
        value: network,
      }
    })
  }, [networksInTenant])

  return (
    <AsyncDropdown
      {...rest}
      name={name}
      label={label}
      compact={false}
      disabled={disabled}
      loading={loading}
      items={options}
    />
  )
}
