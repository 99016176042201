import React, { useCallback, useMemo } from 'react'
import PicklistField from 'core/components/validatedForm/DropdownField'
import useParams from 'core/hooks/useParams'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createVolumeType } from './actions'
import ModalForm from 'core/elements/modal/ModalForm'
import { Route } from 'core/plugins/route'
import TextField from 'core/components/validatedForm/TextField'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import KeyValuesField from 'core/components/validatedForm/KeyValuesField'

const useStyles = makeStyles<Theme>((theme) => ({
  withUnits: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  unitsDropdown: {
    position: 'relative',
    bottom: 1,
  },
}))

interface Props {
  addRoute: Route
}

export default function CreateVolumeModal({ addRoute }: Props) {
  const { history } = useReactRouter()
  const classes = useStyles()
  const defaultParams = {
    name: '',
    description: '',
    metadata: [],
  }
  const { params, getParamsUpdater, updateParams, setParams } = useParams(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(createVolumeType)

  const submitForm = useCallback(async () => {
    const metadata = params.metadata.reduce((accum, pair) => {
      return {
        ...accum,
        [pair.key]: pair.value,
      }
    }, {})
    const body = {
      volume_type: {
        name: params.name,
        description: params.description,
        extra_specs: metadata,
      },
    }
    const { success } = await update({ body })
    if (success) handleClose()
  }, [params])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(routes.openstack.volumeTypes.path())
  }

  return (
    <ModalForm
      route={addRoute}
      title={`Create New Volume Type`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      submitTitle={`Create Volume Type`}
      maxWidth={1200}
    >
      <FormFieldSection title="Volume Type Configuration">
        <TextField
          id="name"
          label="Name"
          onChange={getParamsUpdater('name')}
          value={params.name}
          required
        />
        <TextField
          id="description"
          label="Description"
          onChange={getParamsUpdater('description')}
          value={params.description}
          required
        />
        <KeyValuesField
          id="metadata"
          label="Metadata"
          value={params.metadata}
          onChange={getParamsUpdater('metadata')}
          addLabel="Add Metadata"
          allowMultipleValues
        />
      </FormFieldSection>
    </ModalForm>
  )
}
