import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { clientActions } from 'core/client/clientReducers'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import useReactRouter from 'use-react-router'
import DocumentMeta from 'core/components/DocumentMeta'
import { routes } from 'core/utils/routes'
import useListAction from 'core/hooks/useListAction'
import { IHostDetailsPageTabs } from './model'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Overview from './Overview'
import { useSelector } from 'react-redux'
import { resmgrHostsSelector, hostAggregatesSelector, hypervisorsSelector } from '../../selectors'
import {
  listResmgrHosts,
  listHostAggregates,
  createHostAggregate,
  listHypervisors,
  getResmgrRoleConfig,
} from '../../actions'
import HostVmsListPage from './HostVmsListPage'

const useStyles = makeStyles<Theme>((theme) => ({
  grid: {
    display: 'grid',
    gap: 32,
  },
  actions: {
    display: 'grid',
    gridAutoColumns: 'auto max-content',
    gridAutoFlow: 'column',
    gap: 16,
  },
}))

const HostDetailsPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id } = match.params

  const { loading: loadingHypervisors, reload: reloadHypervisors } = useListAction(listHypervisors)
  const { message, loading, reload } = useListAction(listResmgrHosts)
  const hosts = useSelector(resmgrHostsSelector)
  const hypervisors = useSelector(hypervisorsSelector)
  const hostsWithHypervisor = useMemo(() => {
    const hypervisorsByResmgrId = hypervisors.reduce((accum, hypervisor) => {
      return {
        ...accum,
        [hypervisor.service.host]: hypervisor,
      }
    }, {})
    return hosts.map((host) => {
      return {
        ...host,
        hypervisor: hypervisorsByResmgrId[host.id],
      }
    })
  }, [hosts, hypervisors])

  const host = useMemo(() => hostsWithHypervisor.find((h) => h.id === id) || {}, [
    id,
    hostsWithHypervisor,
  ])
  const isHypervisor = host?.displayRoles?.includes('Hypervisor')

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        id: host?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [host?.name, id])

  return (
    <>
      <DocumentMeta title="Server Details" breadcrumbs />
      <Tabs route={routes.openstack.hostDetails}>
        <Tab label="Overview" value={IHostDetailsPageTabs.Overview}>
          <Overview host={host} loading={loading} />
        </Tab>
        {isHypervisor && (
          <Tab label="Virtual Machines" value={IHostDetailsPageTabs.VirtualMachines}>
            <HostVmsListPage host={host} />
          </Tab>
        )}
      </Tabs>
    </>
  )
}

export default HostDetailsPage
