import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Button from 'core/elements/button'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import useListAction from 'core/hooks/useListAction'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import ListTableField from 'core/components/validatedForm/ListTableField'
import { useAppSelector } from 'app/store'
import {
  attachVolume,
  detachVolume,
  listVolumes,
} from 'openstack/components/storage/volumes/actions'
import { volumesSelector } from 'openstack/components/storage/volumes/selectors'
import { humanReadableSize } from 'openstack/helpers'

const enum VolumeStatus {
  Reserved = 'reserved',
  Detaching = 'detaching',
  Attaching = 'attaching',
  Available = 'available',
  InUse = 'in-use',
}
const InProgressStates = ['detaching', 'attaching', 'reserved']
const AvailableStates = ['available', 'detaching', 'attaching', 'reserved']

const volumeColumns = ({ detachVolumeFromVM, attachVolumeToVm }) => [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'id',
    label: 'UUID',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'size',
    label: 'Capacity',
    render: (size) => humanReadableSize(size * 1024 * 1024 * 1024),
  },
  {
    id: 'bootable',
    label: 'Bootable',
  },
  {
    id: 'description',
    label: 'Action',
    render: (_description, item) => {
      const volumeInUse = item.status === VolumeStatus.InUse
      const actionInProgress = InProgressStates.includes(item.status)
      return (
        <Button
          id="attachDetachVolume"
          size="small"
          disabled={actionInProgress}
          loading={actionInProgress}
          onClick={() => (volumeInUse ? detachVolumeFromVM(item) : attachVolumeToVm(item))}
          variant={volumeInUse ? 'tertiary' : 'primary'}
          icon={volumeInUse ? 'trash-alt' : 'plus'}
        >
          {volumeInUse ? 'Detach' : 'Attach'}
        </Button>
      )
    },
  },
]

export default function ManageVolumesModal({ rows: [vm], onClose }) {
  const [timeoutId, setTimeoutId] = useState(null)

  // Update volumes
  const {
    update: attachVolumeFn,
    updating: attachingVolume,
    error: attachVolumeError,
  } = useUpdateAction(attachVolume)
  const {
    update: detachVolumeFn,
    updating: detachingVolume,
    error: detachingVolumeError,
  } = useUpdateAction(detachVolume)

  // Fetch volumes
  const { loading: loadingVolumes, reload } = useListAction(listVolumes, {})
  const volumes = useAppSelector(volumesSelector)
  const availableVolumes = useMemo(() => {
    return volumes.filter(
      (volume) =>
        AvailableStates.includes(volume.status) ||
        (volume.status === VolumeStatus.InUse && volume?.attachedVm?.id === vm.id),
    )
  }, [volumes, vm])

  // Cleanup timeout
  const cleanup = () => clearTimeout(timeoutId)

  // Make sure to reload volumes when the modal is opened
  useEffect(() => {
    reload(true, true)

    return cleanup()
  }, [])

  // Refresh volumes if for any of the volume action is in progress
  useEffect(() => {
    const actionInProgress = availableVolumes.find((volume) => {
      return InProgressStates.includes(volume.status)
    })
    if (actionInProgress && !timeoutId) refreshVolumes()
  }, [availableVolumes, timeoutId])

  // Refresh volumes after 5 seconds
  const refreshVolumes = useCallback(() => {
    const timeout = setTimeout(() => {
      reload(true, true)
      setTimeoutId(null)
    }, 5000)
    setTimeoutId(timeout)
  }, [reload])

  // Attach volume to VM
  const attachVolumeToVm = useCallback(
    async ({ id }) => {
      const body = {
        volumeAttachment: {
          volumeId: id,
        },
      }
      const { success } = await attachVolumeFn({ vmId: vm.id, id, body })
      if (success) refreshVolumes()
    },
    [vm],
  )

  // Detach volume from VM
  const detachVolumeFromVM = useCallback(
    async ({ id }) => {
      const { success } = await detachVolumeFn({ vmId: vm.id, volumeId: id })
      if (success) refreshVolumes()
    },
    [vm],
  )

  const loading = loadingVolumes || attachingVolume || detachingVolume

  return (
    <ModalForm
      open
      title={`Manage Volumes`}
      onClose={onClose}
      submitting={attachingVolume}
      error={attachVolumeError || detachingVolumeError}
      submitTitle={`Attach Volumes`}
      maxWidth={1200}
    >
      <FormFieldSection title={`Volumes to attach or detach from VM ${vm.name || vm.id}`}>
        <ListTableField
          id="attachDetachVolumes"
          uniqueIdentifier="id"
          searchTargets={['name', 'id']}
          data={availableVolumes}
          loading={loading}
          columns={volumeColumns({ attachVolumeToVm, detachVolumeFromVM })}
          onReload={() => reload(true, true)}
          emptyText="No volumes available"
          showCheckboxes={false}
        />
      </FormFieldSection>
    </ModalForm>
  )
}
