import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import DeleteAction from 'core/actions/DeleteAction'
// import CustomAction from 'core/actions/CustomAction'
import CreateAction from 'core/actions/CreateAction'
import UpdateAction from 'core/actions/UpdateAction'
import store from 'app/store'

const { dispatch } = store
const { neutron } = ApiClient.getInstance()

export const networkActions = ActionsSet.make<DataKeys.OpenstackNetworks>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.OpenstackNetworks,
  cacheKey: DataKeys.OpenstackNetworks,
})

export const listNetworks = networkActions.add(
  new ListAction<DataKeys.OpenstackNetworks>(async () => {
    return neutron.getNetworks()
  })
    .addDependency(DataKeys.OpenstackSubnets)
    .addDependency(DataKeys.NetworkIpAvailability)
    .addDependency(DataKeys.OpenstackPorts),
  // .addDependency(DataKeys.ManagementTenants),
)

export const createNetwork = networkActions.add(
  new CreateAction<DataKeys.OpenstackNetworks, { body }>(async ({ body }) => {
    const created = await neutron.createNetwork(body)
    const networkId = created.id
    const newNetwork = await neutron.getNetwork(networkId)
    return newNetwork
  }),
)

export const updateNetwork = networkActions.add(
  new UpdateAction<
    DataKeys.OpenstackNetworks,
    {
      id: string
      body: unknown
    }
  >(async ({ id, body }) => {
    const updatedNetwork = await neutron.updateNetwork({ id, body })
    return updatedNetwork
  }),
)

export const deleteNetwork = networkActions.add(
  new DeleteAction<DataKeys.OpenstackNetworks, { id: string }>(async ({ id }) => {
    await neutron.deleteNetwork(id)
  }),
)

export const subnetActions = ActionsSet.make<DataKeys.OpenstackSubnets>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.OpenstackSubnets,
  cacheKey: DataKeys.OpenstackSubnets,
})

export const listSubnets = subnetActions.add(
  new ListAction<DataKeys.OpenstackSubnets>(async () => {
    return neutron.getSubnets()
  }),
)

export const createSubnet = subnetActions.add(
  new CreateAction<DataKeys.OpenstackSubnets, { body }>(async ({ body }) => {
    const created = await neutron.createSubnet(body)
    const subnetId = created.id
    const newSubnet = await neutron.getSubnet(subnetId)
    return newSubnet
  }),
)

export const updateSubnet = subnetActions.add(
  new UpdateAction<
    DataKeys.OpenstackSubnets,
    {
      id: string
      body: unknown
    }
  >(async ({ id, body }) => {
    const updatedSubnet = await neutron.updateSubnet({ id, body })
    return updatedSubnet
  }),
)

export const deleteSubnet = subnetActions.add(
  new DeleteAction<DataKeys.OpenstackSubnets, { id: string }>(async ({ id }) => {
    await neutron.deleteSubnet(id)
  }),
)

export const networkAvailabilityActions = ActionsSet.make<DataKeys.NetworkIpAvailability>({
  uniqueIdentifier: 'network_id',
  entityName: entityNamesByKey.NetworkIpAvailability,
  cacheKey: DataKeys.NetworkIpAvailability,
})

export const listNetworkAvailability = networkAvailabilityActions.add(
  new ListAction<DataKeys.NetworkIpAvailability>(async () => {
    return neutron.getNetworkIpAvailability()
  }),
)

export const portActions = ActionsSet.make<DataKeys.OpenstackPorts>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.OpenstackPorts,
  cacheKey: DataKeys.OpenstackPorts,
})

export const listPorts = portActions.add(
  new ListAction<DataKeys.OpenstackPorts>(async () => {
    return neutron.getPorts()
  }).addDependency(DataKeys.SecurityGroups),
)

export const createPort = portActions.add(
  new CreateAction<DataKeys.OpenstackPorts, { body }>(async ({ body }) => {
    const created = await neutron.createPort(body)
    const portId = created.id
    const newPort = await neutron.getPort(portId)
    return newPort
  }),
)

export const updatePort = portActions.add(
  new UpdateAction<
    DataKeys.OpenstackPorts,
    {
      id: string
      body: unknown
    }
  >(async ({ id, body }) => {
    const updatedPort = await neutron.updatePort({ id, body })
    return updatedPort
  }),
)

export const deletePort = portActions.add(
  new DeleteAction<DataKeys.OpenstackPorts, { id: string }>(async ({ id }) => {
    await neutron.deletePort(id)
  }),
)

export const checkNetworksApi = async () => {
  return neutron.getApis()
}
