import React, { useMemo } from 'react'
import Dropdown from 'core/elements/dropdown'
import withProgress from 'core/components/progress/withProgress'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { listPorts } from 'openstack/components/networks/actions'
import { portsSelector } from 'openstack/components/networks/selectors'

const AsyncDropdown = withProgress(Dropdown, { inline: true })

export default function UsedFloatingIpsPicklist({
  disabled = false,
  name = 'fixedIp',
  label = 'Private IP',
  vmId,
  ...rest
}) {
  const { loading } = useListAction(listPorts)
  const ports = useSelector(portsSelector)

  const vmPorts = useMemo(() => {
    return ports.filter((port) => port.device_id === vmId)
  }, [ports])

  const options = useMemo(() => {
    return vmPorts.map((port) => {
      const ipAddressString = port.fixed_ips?.map((ip) => ip.ip_address)?.join(', ')
      return {
        label: ipAddressString,
        value: port.id,
      }
    })
  }, [vmPorts])

  return (
    <AsyncDropdown
      {...rest}
      name={name}
      label={label}
      compact={false}
      disabled={disabled}
      loading={loading}
      items={options}
    />
  )
}
