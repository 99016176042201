import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import UpdateAction from 'core/actions/UpdateAction'
import DeleteAction from 'core/actions/DeleteAction'
import CustomAction from 'core/actions/CustomAction'
import CreateAction from 'core/actions/CreateAction'
import store from 'app/store'
import { cacheActions } from 'core/caching/cacheReducers'
import { listImages } from 'openstack/components/images/actions'
import { listPorts } from 'openstack/components/networks/actions'

const { dispatch } = store
const { nova } = ApiClient.getInstance()

export const getNovaVersion = () => {
  return nova.getVersionDetails()
}

export const allVirtualMachineActions = ActionsSet.make<DataKeys.AllOpenstackVirtualMachines>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.AllOpenstackVirtualMachines,
  cacheKey: DataKeys.AllOpenstackVirtualMachines,
})

export const listAllVirtualMachines = allVirtualMachineActions.add(
  new ListAction<DataKeys.AllOpenstackVirtualMachines>(async () => {
    return nova.getAllInstances()
  })
    .addDependency(DataKeys.ManagementUsers)
    .addDependency(DataKeys.Images)
    .addDependency(DataKeys.Flavors)
    .addDependency(DataKeys.AllOpenstackVolumes)
    .addDependency(DataKeys.ServerGroups),
)

export const virtualMachineActions = ActionsSet.make<DataKeys.OpenstackVirtualMachines>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.OpenstackVirtualMachines,
  cacheKey: DataKeys.OpenstackVirtualMachines,
  addCacheKey: DataKeys.AllOpenstackVirtualMachines,
})

export const listVirtualMachines = virtualMachineActions.add(
  new ListAction<DataKeys.OpenstackVirtualMachines>(async () => {
    return nova.getInstances()
  })
    .addDependency(DataKeys.ManagementUsers)
    .addDependency(DataKeys.Images)
    .addDependency(DataKeys.Flavors)
    .addDependency(DataKeys.OpenstackVolumes)
    .addDependency(DataKeys.ServerGroups),
)

export const createVirtualMachine = virtualMachineActions.add(
  new CreateAction<DataKeys.OpenstackVirtualMachines, { body }>(async ({ body }) => {
    const created = await nova.createInstance(body)
    const adminPass = created?.adminPass
    const instanceId = created?.id
    const newVm = await nova.getInstance(instanceId)
    return {
      ...newVm,
      adminPass,
    }
  }),
)

export const updateVirtualMachine = virtualMachineActions.add(
  new UpdateAction<DataKeys.OpenstackVirtualMachines, { id: string; body: any }>(
    async ({ id, body }) => {
      const updatedVm = await nova.updateInstance(id, body)
      return updatedVm
    },
  ),
)

export const deleteVirtualMachine = virtualMachineActions.add(
  new DeleteAction<DataKeys.OpenstackVirtualMachines, { id: string }>(async ({ id }) => {
    await nova.deleteInstance(id)
  }),
)

export const refreshVirtualMachine = virtualMachineActions.add(
  new CustomAction<DataKeys.OpenstackVirtualMachines, { id: string }>(
    'vmRefresh',
    async ({ id }) => {
      const vm = await nova.getInstance(id)
      return vm
    },
    (result, { id }) => {
      // Update the vm in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.OpenstackVirtualMachines,
          params: { id },
          item: result,
        }),
      )
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.AllOpenstackVirtualMachines,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
    },
  ),
)

// Todo: When this action is taken when showing allTenants, the allVms
// store is not updated, just the normal vms store
export const powerVirtualMachine = virtualMachineActions.add(
  new CustomAction<DataKeys.OpenstackVirtualMachines, { id: string; body?: any }>(
    'vmOperation',
    async ({ id, body }) => {
      await nova.instanceAction({ id, body })
      const vm = await nova.getInstance(id)
      return vm
    },
    (result, { id }) => {
      // Update the cluster in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.OpenstackVirtualMachines,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.AllOpenstackVirtualMachines,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
    },
  ),
)

export const addSecurityGroups = virtualMachineActions.add(
  new CustomAction<
    DataKeys.OpenstackVirtualMachines,
    { id: string; securityGroupNames?: string[] }
  >(
    'addSecurityGroups',
    async ({ id, securityGroupNames }) => {
      for (const securityGroupName of securityGroupNames) {
        await nova.addSecurityGroupAction({ id, securityGroupName })
      }

      const vm = await nova.getInstance(id)
      return vm
    },
    (result, { id }) => {
      // Update the cluster in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.OpenstackVirtualMachines,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.AllOpenstackVirtualMachines,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
    },
  ),
)

export const removeSecurityGroups = virtualMachineActions.add(
  new CustomAction<
    DataKeys.OpenstackVirtualMachines,
    { id: string; securityGroupNames?: string[] }
  >(
    'removeSecurityGroups',
    async ({ id, securityGroupNames }) => {
      for (const securityGroupName of securityGroupNames) {
        await nova.removeSecurityGroupAction({ id, securityGroupName })
      }

      const vm = await nova.getInstance(id)
      return vm
    },
    (result, { id }) => {
      // Update the cluster in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.OpenstackVirtualMachines,
          params: { id }, // TODO: Double check this works
          item: { ...result, security_groups: result['security_groups'] || [] },
        }),
      )
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.AllOpenstackVirtualMachines,
          params: { id }, // TODO: Double check this works
          item: { ...result, security_groups: result['security_groups'] || [] },
        }),
      )
    },
  ),
)

export const snapshotVirtualMachine = virtualMachineActions.add(
  new CustomAction<DataKeys.OpenstackVirtualMachines, { id: string; body?: any }>(
    'snapshotVm',
    async ({ id, body }) => {
      await nova.instanceAction({ id, body })
      const vm = await nova.getInstance(id)
      listImages.call({})
      return vm
    },
    (result, { id }) => {
      // Update the vm in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.OpenstackVirtualMachines,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.AllOpenstackVirtualMachines,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
    },
  ),
)

export const getConsoleEndpoint = async ({ id }) => {
  const response = await nova.createConsole({ vmId: id })
  return response?.remote_console?.url
}

export const migrateVirtualMachine = virtualMachineActions.add(
  new CustomAction<DataKeys.OpenstackVirtualMachines, { id: string; body?: any }>(
    'migrateVm',
    async ({ id, body }) => {
      await nova.migrateAction({ id, body })
      const vm = await nova.getInstance(id)
      return vm
    },
    (result, { id }) => {
      // Update the cluster in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.OpenstackVirtualMachines,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.AllOpenstackVirtualMachines,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
    },
  ),
)

export const addFixedIpToVm = virtualMachineActions.add(
  new CustomAction<DataKeys.OpenstackVirtualMachines, { id: string; body?: any }>(
    'addFixedIp',
    async ({ id, body }) => {
      await nova.createInterface({ id, body })
      const vm = await nova.getInstance(id)
      listPorts.call({})
      return vm
    },
    (result, { id }) => {
      // Update the vm in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.OpenstackVirtualMachines,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.AllOpenstackVirtualMachines,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
    },
  ),
)

export const detachFixedIpFromVm = virtualMachineActions.add(
  new CustomAction<DataKeys.OpenstackVirtualMachines, { id: string; portId: string }>(
    'detachFixedIp',
    async ({ id, portId }) => {
      await nova.detachInterface({ id, portId })
      const vm = await nova.getInstance(id)
      listPorts.call({})
      return vm
    },
    (result, { id }) => {
      // Update the vm in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.OpenstackVirtualMachines,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.AllOpenstackVirtualMachines,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
    },
  ),
)

export const updateVmMetadata = virtualMachineActions.add(
  new CustomAction<DataKeys.OpenstackVirtualMachines, { id: string; body?: any }>(
    'updateVmMetadata',
    async ({ id, body }) => {
      await nova.setInstanceMetadata({ id, body })
      const vm = await nova.getInstance(id)
      return vm
    },
    (result, { id }) => {
      // Update the cluster in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.OpenstackVirtualMachines,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.AllOpenstackVirtualMachines,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
    },
  ),
)

export const rebuildVirtualMachine = virtualMachineActions.add(
  new CustomAction<DataKeys.OpenstackVirtualMachines, { id: string; body?: any }>(
    'rebuildVm',
    async ({ id, body }) => {
      await nova.instanceAction({ id, body })
      const vm = await nova.getInstance(id)
      return vm
    },
    (result, { id }) => {
      // Update the vm in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.OpenstackVirtualMachines,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.AllOpenstackVirtualMachines,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
    },
  ),
)

export const resizeVirtualMachine = virtualMachineActions.add(
  new CustomAction<DataKeys.OpenstackVirtualMachines, { id: string; body?: any }>(
    'resizeVm',
    async ({ id, body }) => {
      await nova.instanceAction({ id, body })
      const vm = await nova.getInstance(id)
      return vm
    },
    (result, { id }) => {
      // Update the vm in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.OpenstackVirtualMachines,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.AllOpenstackVirtualMachines,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
    },
  ),
)

export const confirmResizeVirtualMachine = virtualMachineActions.add(
  new CustomAction<DataKeys.OpenstackVirtualMachines, { id: string; body?: any }>(
    'confirmResizeVm',
    async ({ id, body }) => {
      await nova.instanceAction({ id, body })
      const vm = await nova.getInstance(id)
      return vm
    },
    (result, { id }) => {
      // Update the vm in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.OpenstackVirtualMachines,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.AllOpenstackVirtualMachines,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
    },
  ),
)

export const getVmEvents = ({ id }) => {
  return nova.getVmEvents({ id })
}

export const getVmEventDetails = ({ vmId, requestId }) => {
  return nova.getVmEventDetails({ vmId, requestId })
}
