import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

const options = [
  { label: 'None', value: '' },
  { label: 'vhd', value: 'vhd' },
  { label: 'vhdx', value: 'vhdx' },
  { label: 'vmdk', value: 'vmdk' },
  { label: 'raw', value: 'raw' },
  { label: 'qcow2', value: 'qcow2' },
  { label: 'iso', value: 'iso' },
]

interface Props {}

export default function DiskFormatPicklist({
  name = 'diskFormat',
  label = 'Disk Format',
  compact = false,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  return <AsyncDropdown compact={compact} name={name} label={label} items={options} {...rest} />
}
