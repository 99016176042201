import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'

export const flavorsSelector = createSharedSelector(
  getDataSelector<DataKeys.Flavors>(DataKeys.Flavors),
  (flavors) => {
    return flavors.map((flavor) => {
      // any model changes go here
      return flavor
    })
  },
)

export const flavorsByIdSelector = createSharedSelector(flavorsSelector, (flavors) => {
  return flavors.reduce((accum, flavor) => {
    return {
      ...accum,
      [flavor.id]: flavor,
    }
  }, {})
})
