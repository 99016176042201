import ApiClient from 'api-client/ApiClient'
const { resMgr } = ApiClient.getInstance()

export const hasHypervisorRole = (host) => {
  return host.roles.includes('pf9-ostackhost-neutron')
}

export const hasNetworkingRole = (host) => {
  // this will change
  return host.roles.includes('pf9-neutron-base')
}

export const hasImageLibraryRole = (host) => {
  return host.roles.includes('pf9-glance-role')
}

export const hasCinderRole = (host) => {
  if (host.roles.includes('pf9-cindervolume-nfs')) {
    return 'nfs'
  }
  if (host.roles.includes('pf9-cindervolume-lvm')) {
    return 'lvm'
  }
  if (host.roles.includes('pf9-cindervolume-ceph')) {
    return 'ceph'
  }
  if (host.roles.includes('pf9-cindervolume-netapp')) {
    return 'netapp'
  }
  if (host.roles.includes('pf9-cindervolume-other')) {
    return 'custom'
  }
  return 'none'
}

export const hasRemoteSupportRole = (host) => {
  return host.roles.includes('pf9-support')
}

export const getHostConfiguredIp = (host) => {
  return ''
}

export const getHostRoleConfig = async (host, role) => {
  const roleConfig = await resMgr.getRole(host.id, role)
  return roleConfig
}
