import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

const options = [
  { label: 'CIDR', value: 'cidr' },
  { label: 'Security Group', value: 'securityGroup' },
]

interface Props {}

export default function RuleDestinationPicklist({
  name = 'destination',
  label = 'Destination',
  compact = false,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  return <AsyncDropdown compact={compact} name={name} label={label} items={options} {...rest} />
}
