import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'

const standardPropertyWhitelist = [
  'checksum',
  'container_format',
  'created_at',
  'disk_format',
  'file',
  'id',
  'min_disk',
  'min_ram',
  'name',
  'owner',
  'protected',
  'schema',
  'self',
  'size',
  'status',
  'tags',
  'updated_at',
  'virtual_size',
  'visibility',
  'os_hidden',
  'os_hash_algo',
  'os_hash_value',
]

export const imagesSelector = createSharedSelector(
  getDataSelector<DataKeys.Images>(DataKeys.Images),
  (images) => {
    return images.map((image) => {
      // any model changes go here
      const otherPropertyKeys = Object.keys(image).filter(
        (key) => !standardPropertyWhitelist.includes(key),
      )
      const properties = otherPropertyKeys.reduce((accum, key) => {
        return {
          ...accum,
          [key]: image[key],
        }
      }, {})
      return {
        ...image,
        properties,
      }
    })
  },
)

export const imagesByIdSelector = createSharedSelector(imagesSelector, (images) => {
  return images.reduce((accum, img) => {
    return {
      ...accum,
      [img.id]: img,
    }
  }, {})
})
