import { customValidator } from 'core/utils/fieldValidators'
import {
  isValidIpv4Address,
  isValidIpv4Cidr,
} from 'app/plugins/infrastructure/components/clusters/form-components/validators'
import * as IpAddress from 'ip-address'

export const getPhysicalNetworkInterfaceLabel = (
  physicalNetworksInterfaceMap,
  vmPhysicalNetwork,
) => {
  return physicalNetworksInterfaceMap?.find((network) => network.interface === vmPhysicalNetwork)
    ?.label
}

export const getPhysicalNetworkInterface = (physicalNetworksInterfaceMap, label) => {
  return physicalNetworksInterfaceMap?.find((network) => network.label === label)?.interface
}

export const getPhysicalNetworkInterfaceIndex = (physicalNetworksInterfaceMap, interfaceName) => {
  return physicalNetworksInterfaceMap?.findIndex((network) => network.interface === interfaceName)
}

export const getNetworkBody = ({ networkType, params, physicalNetworksInterfaceMap }) => {
  if (networkType === 'flat') {
    return {
      network: {
        name: params.vmNetworkName,
        description: 'The default VM network',
        admin_state_up: true,
        'provider:network_type': params.networkType,
        'provider:physical_network': getPhysicalNetworkInterfaceLabel(
          physicalNetworksInterfaceMap,
          params.vmNetworkPhysNet,
        ),
        'router:external': !!params.external,
        shared: !!params.shared,
      },
    }
  } else if (networkType === 'vlan') {
    return {
      network: {
        name: params.vmNetworkName,
        description: 'The default VM network',
        admin_state_up: true,
        'provider:network_type': params.networkType,
        'provider:physical_network': getPhysicalNetworkInterfaceLabel(
          physicalNetworksInterfaceMap,
          params.vmNetworkPhysNet,
        ),
        'provider:segmentation_id': params.segmentationId,
        'router:external': !!params.external,
        shared: !!params.shared,
      },
    }
  } else if (networkType === 'vxlan') {
    return {
      network: {
        name: params.vmNetworkName,
        description: 'The default VM network',
        admin_state_up: true,
        'provider:network_type': params.networkType,
        'provider:segmentation_id': params.segmentationId,
        'router:external': !!params.external,
        shared: !!params.shared,
      },
    }
  }
  return {}
}

export const cidrValidator = customValidator((value) => {
  if (!value) {
    return false
  }
  return isValidIpv4Cidr(value)
}, 'Invalid IPv4 CIDR provided')

export const gatewayInCidrValidator = customValidator((value, formValues) => {
  if (!value) {
    return true
  }
  try {
    const testCidr = new IpAddress.Address4(formValues.cidr)
    return new IpAddress.Address4(value).isInSubnet(testCidr)
  } catch (err) {
    return false
  }
}, 'Gateway IP address is not inside of designated CIDR')

export const gatewayIpValid = customValidator((value, formValues) => {
  if (!value) {
    return true
  }
  return isValidIpv4Address(value)
}, 'Gateway IP address is an invalid IP address')

export const waitSeconds = async (seconds) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve('')
    }, seconds * 1000)
  })
}

export const rangeLabelMap = {
  vlan: 'VLAN Range',
  vxlan: 'VXLAN VNID Range',
  geneve: 'Geneve Tunnel ID Range',
}

export const rangeTooltipMap = {
  vlan:
    'You can specify one or more VLANs, or VLAN ranges, separated by a comma. Each range should follow start_vlan_id-end_vlan_id syntax (eg. 50-60). You may assign VLAN IDs between 2 and 4094.',
  vxlan:
    'You can specify one or more VXLAN VNIDs, or VNID ranges, separated by a comma. Each range should follow start_range-end_range syntax (eg. 3050-3060). You may assign VNIDs between 1 to 4095.',
  geneve:
    'You can specify one or more IDs, or ranges, separated by a comma. Each range should follow start_range-end_range syntax (eg. 6050-6060). You may assign IDs between 1 to 65535.',
}

export const rangePlaceholderMap = {
  vlan: 'eg. 50-60',
  vxlan: 'eg. 3050-3060',
  geneve: 'eg. 50-60',
}

export const allSystemTrafficSelected = (hostConfigs) => {
  for (const config of hostConfigs) {
    const condsFulfilled = config?.networkInterfaces?.reduce(
      (accum, iface) => {
        return {
          management: accum.management || iface.management,
          vmConsole: accum.vmConsole || iface.vmConsole,
          imageLibrary: accum.imageLibrary || iface.imageLibrary,
          tunnel: accum.tunnel || iface.tunnel,
          hostLiveness: accum.hostLiveness || iface.hostLiveness,
        }
      },
      {
        management: false,
        vmConsole: false,
        imageLibrary: false,
        tunnel: false,
        hostLiveness: false,
      },
    )
    if (
      !condsFulfilled.management ||
      !condsFulfilled.vmConsole ||
      !condsFulfilled.imageLibrary ||
      !condsFulfilled.tunnel ||
      !condsFulfilled.hostLiveness
    ) {
      return false
    }
  }
  return true
}
