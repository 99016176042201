import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import DeleteAction from 'core/actions/DeleteAction'
import CustomAction from 'core/actions/CustomAction'
import CreateAction from 'core/actions/CreateAction'
import store from 'app/store'
import { cacheActions } from 'core/caching/cacheReducers'
import { noop } from 'utils/fp'

const { dispatch } = store
const { cinder, nova } = ApiClient.getInstance()

export const allVolumeActions = ActionsSet.make<DataKeys.AllOpenstackVolumes>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.AllOpenstackVolumes,
  cacheKey: DataKeys.AllOpenstackVolumes,
})

export const listAllVolumes = allVolumeActions.add(
  new ListAction<DataKeys.AllOpenstackVolumes>(async () => {
    return cinder.getAllVolumes()
  }).addDependency(DataKeys.OpenstackVirtualMachines),
)

export const volumeActions = ActionsSet.make<DataKeys.OpenstackVolumes>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.OpenstackVolumes,
  cacheKey: DataKeys.OpenstackVolumes,
  addCacheKey: DataKeys.AllOpenstackVolumes,
})

export const listVolumes = volumeActions.add(
  new ListAction<DataKeys.OpenstackVolumes>(async () => {
    return cinder.getVolumes()
  }).addDependency(DataKeys.OpenstackVirtualMachines),
)

export const createVolume = volumeActions.add(
  new CreateAction<DataKeys.OpenstackVolumes, { body }>(async ({ body }) => {
    const created = await cinder.createVolume(body)
    const volumeId = created.id
    const newVolume = await cinder.getVolume(volumeId)
    return newVolume
  }),
)

export const deleteVolume = volumeActions.add(
  new DeleteAction<DataKeys.OpenstackVolumes, { id: string }>(async ({ id }) => {
    await cinder.deleteVolume(id)
  }),
)

export const attachVolume = volumeActions.add(
  new CustomAction<DataKeys.OpenstackVolumes, { id: string; vmId: string; body: any }>(
    'attachVolume',
    async ({ id, vmId, body }) => {
      await nova.attachVolume({ vmId, body })
      const volume = await cinder.getVolume(id)
      return volume
    },
    async (result, { id, vmId }) => {
      // Update the vm and volume in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.OpenstackVolumes,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.AllOpenstackVolumes,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
      const vm = await nova.getInstance(vmId)
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.OpenstackVirtualMachines,
          params: { id: vmId }, // TODO: Double check this works
          item: vm,
        }),
      )
    },
  ),
)

export const detachVolume = volumeActions.add(
  new CustomAction<DataKeys.OpenstackVolumes, { volumeId: string; vmId: string }>(
    'detachVolume',
    async ({ volumeId, vmId }) => {
      await nova.detachVolume({ volumeId, vmId })
      const volume = await cinder.getVolume(volumeId)
      return volume
    },
    async (result, { volumeId, vmId }) => {
      // Update the vm and volume in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.OpenstackVolumes,
          params: { id: volumeId }, // TODO: Double check this works
          item: result,
        }),
      )
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.AllOpenstackVolumes,
          params: { id: volumeId }, // TODO: Double check this works
          item: result,
        }),
      )
      const vm = await nova.getInstance(vmId)
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.OpenstackVirtualMachines,
          params: { id: vmId }, // TODO: Double check this works
          item: vm,
        }),
      )
    },
  ),
)

export const uploadVolumeAsImage = volumeActions.add(
  new CustomAction<DataKeys.OpenstackVolumes, { id: string; body: any }>(
    'uploadVolumeAsImage',
    async ({ id, body }) => {
      const response = await cinder.uploadVolumeAsImage({ id, body })
      return response
    },
    async (result, { id }) => {
      noop()
    },
  ),
)

export const bootableVolume = volumeActions.add(
  new CustomAction<DataKeys.OpenstackVolumes, { id: string; isBootable: boolean }>(
    'setBootable',
    async ({ id, isBootable }) => {
      await cinder.setBootable(id, isBootable)
      const volume = await cinder.getVolume(id)
      return volume
    },
    async (result, { id }) => {
      // Update the vm and volume in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.OpenstackVolumes,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.AllOpenstackVolumes,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
    },
  ),
)

export const extendVolume = volumeActions.add(
  new CustomAction<DataKeys.OpenstackVolumes, { volume: any; size: any }>(
    'extendVolume',
    async ({ volume, size }) => {
      await cinder.extendVolume(volume?.id, size)
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.OpenstackVolumes,
          params: { id: volume?.id }, // TODO: Double check this works
          item: { ...volume, size },
        }),
      )
    },
  ),
)
