import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'

export const volumeTypesSelector = createSharedSelector(
  getDataSelector<DataKeys.VolumeTypes>(DataKeys.VolumeTypes),
  (volumeTypes) => {
    return volumeTypes.map((volumeType) => {
      // any model changes go here
      return {
        ...volumeType,
        // created_at: `${volume.created_at}Z`,
      }
    })
  },
)
