import React, { useEffect, useState } from 'react'
import DocumentMeta from 'core/components/DocumentMeta'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import SubmitButton from 'core/components/buttons/SubmitButton'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import TextField from 'core/components/validatedForm/TextField'
import useParams from 'core/hooks/useParams'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import Text from 'core/elements/Text'
import { HeaderTitlePortal, HeaderPrimaryActionPortal } from 'core/elements/header/portals'
import Breadcrumbs from 'core/elements/breadcrumbs'
import PicklistField from 'core/components/validatedForm/DropdownField'
import IpAddressPicklist from './IpAddressPicklist'
import { listResmgrHosts } from './actions'
import { resmgrHostsSelector } from './selectors'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import Progress from 'core/components/progress/Progress'
import Button from 'core/elements/button'
import ApiClient from 'api-client/ApiClient'
import {
  hasHypervisorRole,
  hasNetworkingRole,
  hasImageLibraryRole,
  getHostRoleConfig,
} from './helpers'

export default function InfrastructurePlanPage() {
  const { resMgr } = ApiClient.getInstance()
  const hasResmgr = resMgr.endpointPresent()
  const classes = useStyles()
  const [dataLoading, setDataLoading] = useState(true)

  const { message, loading, reload } = useListAction(listResmgrHosts, {})
  const hosts = useSelector(resmgrHostsSelector)

  const populateHostData = (hosts, ipsByHost) => {
    // Will eventually need role data as well in order to populate the
    // previously selected IP address
    return hosts.reduce(
      (accum, host) => ({
        valuesByHost: {
          ...accum.valuesByHost,
          [host.id]: {
            ipAddress: ipsByHost[host.id] || host?.interfaces?.[0]?.ip,
            hypervisor: hasHypervisorRole(host),
            networkNode: hasNetworkingRole(host),
            imageLibrary: hasImageLibraryRole(host),
            blockStorage: false,
          },
        },
      }),
      { valuesByHost: {} },
    )
  }

  const getConfiguredHostIps = async (hosts) => {
    const ipsByHost = {}
    for (const host of hosts) {
      if (hasHypervisorRole(host)) {
        const roleConfig: any = await getHostRoleConfig(host, 'pf9-ostackhost-neutron')
        // ipsByHost[host.id] = roleConfig?.cluster_ip
        ipsByHost[host.id] = undefined
      } else if (hasNetworkingRole(host)) {
        const roleConfig: any = await getHostRoleConfig(host, 'pf9-ostackhost-neutron')
        // ipsByHost[host.id] = roleConfig?.cluster_ip
        ipsByHost[host.id] = undefined
      } else if (hasImageLibraryRole(host)) {
        const roleConfig: any = await getHostRoleConfig(host, 'pf9-glance-role')
        ipsByHost[host.id] = roleConfig?.endpoint_address
      }
    }
    return ipsByHost
  }

  const { params, getParamsUpdater, setParams, updateParams } = useParams({ valuesByHost: {} })

  useEffect(() => {
    const asyncOperations = async () => {
      try {
        const ipsByHost = await getConfiguredHostIps(hosts)
        const hostData = populateHostData(hosts, ipsByHost)
        setParams(hostData)
        setDataLoading(false)
      } catch (e) {
        console.error(e)
        const hostData = populateHostData(hosts, {})
        setParams(hostData)
        setDataLoading(false)
      }
    }
    if (!loading) {
      asyncOperations()
    }
  }, [loading, hosts])

  const onSubmit = (values) => {
    console.log(values, 'values')
  }

  return (
    <>
      <DocumentMeta title="Infrastructure Blueprint" />
      <HeaderTitlePortal>
        <Breadcrumbs />
      </HeaderTitlePortal>
      {hasResmgr ? (
        <Progress loading={loading} message={message}>
          {!!hosts?.length ? (
            <ValidatedForm
              onSubmit={onSubmit}
              elevated={false}
              // formActions={
              //   <>
              //     <SubmitButton>Update Infrastructure Plan</SubmitButton>
              //   </>
              // }
            >
              <div className={classes.formContainer}>
                <div className={classes.planContainer}>
                  <>
                    <div className={classes.headerLabel}>
                      <Text variant="caption1">Host</Text>
                    </div>
                    <div className={classes.headerLabel}>
                      <Text variant="caption1">IP Address</Text>
                    </div>
                    <div className={classes.headerLabel}>
                      <Text variant="caption1">Run VMs</Text>
                    </div>
                    <div className={classes.headerLabel}>
                      <Text variant="caption1">Host Networks</Text>
                    </div>
                    <div className={classes.headerLabel}>
                      <Text variant="caption1">Host Images</Text>
                    </div>
                    <div className={classes.headerLabel}>
                      <Text variant="caption1">Export Block Storage</Text>
                    </div>
                  </>
                  {hosts.map((host) => (
                    <>
                      <div>
                        <Text variant="body2">{host.info.hostname}</Text>
                      </div>
                      <div>
                        <PicklistField
                          DropdownComponent={IpAddressPicklist}
                          id={`valuesByHost.${host.id}.ipAddress`}
                          label=""
                          onChange={(value) =>
                            updateParams({
                              valuesByHost: {
                                ...params.valuesByHost,
                                [host.id]: {
                                  ...params.valuesByHost[host.id],
                                  ipAddress: value,
                                },
                              },
                            })
                          }
                          value={params.valuesByHost?.[host.id]?.ipAddress}
                          host={host}
                        />
                      </div>
                      <div className={classes.checkboxCell}>
                        <CheckboxField
                          id={`valuesByHost.${host.id}.hypervisor`}
                          label=""
                          value={params.valuesByHost?.[host.id]?.hypervisor}
                          onChange={(value) =>
                            updateParams({
                              valuesByHost: {
                                ...params.valuesByHost,
                                [host.id]: {
                                  ...params.valuesByHost[host.id],
                                  hypervisor: value,
                                },
                              },
                            })
                          }
                        />
                      </div>
                      <div className={classes.checkboxCell}>
                        <CheckboxField
                          id={`valuesByHost.${host.id}.networkNode`}
                          label=""
                          value={params.valuesByHost?.[host.id]?.networkNode}
                          onChange={(value) =>
                            updateParams({
                              valuesByHost: {
                                ...params.valuesByHost,
                                [host.id]: {
                                  ...params.valuesByHost[host.id],
                                  networkNode: value,
                                },
                              },
                            })
                          }
                        />
                      </div>
                      <div className={classes.checkboxCell}>
                        <CheckboxField
                          id={`valuesByHost.${host.id}.imageLibrary`}
                          label=""
                          value={params.valuesByHost?.[host.id]?.imageLibrary}
                          onChange={(value) =>
                            updateParams({
                              valuesByHost: {
                                ...params.valuesByHost,
                                [host.id]: {
                                  ...params.valuesByHost[host.id],
                                  imageLibrary: value,
                                },
                              },
                            })
                          }
                        />
                      </div>
                      <div className={classes.checkboxCell}>
                        <CheckboxField
                          id={`valuesByHost.${host.id}.blockStorage`}
                          label=""
                          value={params.valuesByHost?.[host.id]?.blockStorage}
                          onChange={(value) =>
                            updateParams({
                              valuesByHost: {
                                ...params.valuesByHost,
                                [host.id]: {
                                  ...params.valuesByHost[host.id],
                                  blockStorage: value,
                                },
                              },
                            })
                          }
                        />
                      </div>
                    </>
                  ))}
                </div>
                <div className={classes.actionButtons}>
                  <Button type="button">Save Infrastructure Blueprint</Button>
                </div>
              </div>
            </ValidatedForm>
          ) : (
            <div className={classes.noHosts}>
              <Text variant="subtitle1">No hosts have been added yet</Text>
              <div className={classes.centerButton}>
                <Button onClick={() => reload(true, true)} icon="sync">
                  Refresh
                </Button>
              </div>
            </div>
          )}
        </Progress>
      ) : (
        <div className={classes.noResmgr}>
          <Text variant="subtitle1">
            Infrastructure Blueprint is only available with Platform9 Managed OpenStack
          </Text>
        </div>
      )}
    </>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  formContainer: {
    padding: '24px',
    background: 'white',
    width: 'max-content',
  },
  planContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, max-content)',
    alignItems: 'center',
    gap: '12px 36px',
  },
  headerLabel: {
    marginBottom: '12px',
  },
  checkboxCell: {
    // display: 'flex',
    // justifyContent: 'center',
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '24px',
  },
  noHosts: {
    display: 'inline-grid',
    padding: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '24px',
    background: 'white',
  },
  centerButton: {
    display: 'inline-grid',
    justifyContent: 'center',
  },
  noResmgr: {
    padding: '40px',
    textAlign: 'center',
    background: 'white',
  },
}))
