import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useParams from 'core/hooks/useParams'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import {
  getSingleResmgrHost,
  getResmgrRoleConfig,
  updateVmwHypervisorRole,
  updateVmwImageLibraryRole,
  updateDiscoveryRole,
  getResmgrServiceConfig,
  updateResmgrServiceConfig,
  listResmgrHosts,
} from '../actions'
import { hypervisorsSelector } from '../selectors'
import ModalForm from 'core/elements/modal/ModalForm'
import TextField from 'core/components/validatedForm/TextField'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { useSelector } from 'react-redux'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import PicklistField from 'core/components/validatedForm/DropdownField'
import AvailabilityZonesPicklist from '../availability-zones/AvailabilityZonesPicklist'
import KeyValuesField from 'core/components/validatedForm/KeyValuesField'
import ListTableField from 'core/components/validatedForm/ListTableField'
import useListAction from 'core/hooks/useListAction'
import { listAvailabilityZones } from '../availability-zones/actions'
import { without } from 'ramda'
import ProgressBar from 'core/components/progress/ProgressBar'
import Text from 'core/elements/Text'
import ImageLibraryAccessPicklist from './ImageLibraryAccessPicklist'
import DvsPicklist from './DvsPicklist'
import FormReviewTable from 'core/components/validatedForm/review-table'
import { waitSeconds } from '../blueprint/helpers'

const useStyles = makeStyles<Theme>((theme) => ({}))

interface Props {
  rows: any[]
  onClose: () => void
}

const clusterColumns = [
  {
    id: 'name',
    label: 'Cluster Name',
  },
  {
    id: 'cpu_usage_mhz',
    label: 'CPU',
    render: (value, item) => {
      const percent = ((item?.cpu_usage_mhz / item?.cpu_capacity_mhz) * 100).toFixed(2)
      return (
        <>
          <ProgressBar width={138} variant="health" percent={percent} showPercent={false} />
          <Text variant="body2" style={{ marginTop: 8 }}>
            {item?.cpu_usage_mhz?.toFixed(2)} MHz / {item?.cpu_capacity_mhz?.toFixed(2)} MHz used
          </Text>
        </>
      )
    },
  },
  {
    id: 'memory_usage_gb',
    label: 'Memory',
    render: (value, item) => {
      const percent = ((item?.memory_usage_gb / item?.memory_capacity_gb) * 100).toFixed(2)
      return (
        <>
          <ProgressBar width={138} variant="health" percent={percent} showPercent={false} />
          <Text variant="body2" style={{ marginTop: 8 }}>
            {item?.memory_usage_gb?.toFixed(2)} GB / {item?.memory_capacity_gb?.toFixed(2)} GB used
          </Text>
        </>
      )
    },
  },
]

const datastoreColumns = [
  {
    id: 'name',
    label: 'Datastore',
  },
  {
    id: 'datacenter_name',
    label: 'Datacenter',
  },
  {
    id: 'storage_capacity_gb',
    label: 'Storage',
    render: (value, item) => {
      const percent = ((item?.storage_usage_gb / item?.storage_capacity_gb) * 100).toFixed(2)
      return (
        <>
          <ProgressBar width={138} variant="health" percent={percent} showPercent={false} />
          <Text variant="body2" style={{ marginTop: 8 }}>
            {item?.storage_usage_gb?.toFixed(2)} GB / {item?.storage_capacity_gb?.toFixed(2)} GB
            used
          </Text>
        </>
      )
    },
  },
]

const reviewColumns = [
  { id: 'cluster', label: 'Cluster Name', renderArray: true, render: (value) => value[0]?.name },
  {
    id: 'datastores',
    label: 'Selected Datastores',
    renderArray: true,
    render: (value) => value.map((datastore) => datastore.name).join(', '),
  },
  { id: 'imageLibraryIp', label: 'Image Library Endpoint' },
  { id: 'customImageLibraryEndpoint', label: 'Custom Image Library Endpoint' },
  { id: 'imageLibraryLocation', label: 'Library Location on filessystem' },
  {
    id: 'imageLibraryStore',
    label: 'Image Library Datastore',
    renderArray: true,
    render: (value) => value[0]?.name,
  },
  { id: 'vmStoragePath', label: 'Virtual Machine Storage Path' },
  { id: 'dvs', label: 'Distributed Virtual Switch' },
]

export default function AuthorizeVmwareGatewayModal({ rows: [host], onClose }: Props) {
  const classes = useStyles()
  const defaultParams = {
    cluster: [],
    datastores: [],
    imageLibraryIp: '',
    imageLibraryStore: [],
    customImageLibraryEndpoint: '',
    dvs: '',
    imageLibraryLocation: '/var/opt/imagelibrary/data',
    vmStoragePath: '/opt/data/instances/',
  }

  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)
  const [submitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const clusterList = useMemo(() => {
    return host?.extensions?.hypervisor_details?.data?.clusters
  }, [host])
  const datastoresList = useMemo(() => {
    return params.cluster[0]?.datastores || []
  }, [params.cluster])
  const selectedDatastoresList = useMemo(() => {
    return params.datastores
  }, [params.datastores])
  const ips = host?.extensions?.ip_address?.data
  const networkSwitches = host?.extensions?.hypervisor_details?.data?.dvs

  useEffect(() => {
    const getCurrentConfig = async () => {
      if (host.roles?.includes('pf9-ostackhost-neutron-vmw')) {
        const hypervisorConfig: any = await getResmgrRoleConfig({
          role: 'pf9-ostackhost-neutron-vmw',
          host,
        })
        const cluster = host?.extensions.hypervisor_details?.data?.clusters?.find(
          (cluster) => cluster.name === hypervisorConfig?.cluster_name,
        )
        const currentDatastores = hypervisorConfig?.datastore_regex?.split(',')
        const datastores =
          cluster?.datastores?.filter((datastore) => {
            return currentDatastores?.includes(datastore.name)
          }) || defaultParams.datastores
        const vmStoragePath = hypervisorConfig?.instances_path || defaultParams.vmStoragePath
        updateParams({
          cluster: cluster ? [cluster] : defaultParams.cluster,
          datastores: datastores,
          vmStoragePath: vmStoragePath,
        })
        // this one inside of hypervisor conditional bc
        // info on the selected cluster is required
        if (host.roles?.includes('pf9-glance-role-vmw')) {
          const glanceConfig: any = await getResmgrRoleConfig({ role: 'pf9-glance-role-vmw', host })
          const imageLibraryIp = ips.includes(glanceConfig?.endpoint_address)
            ? glanceConfig.endpoint_address
            : 'custom'
          const customImageLibraryEndpoint =
            imageLibraryIp === 'custom'
              ? glanceConfig.endpoint_address
              : defaultParams.customImageLibraryEndpoint
          const imageLibraryStore =
            cluster?.datastores?.filter((datastore) => {
              return datastore.name === glanceConfig?.vmware_datastore_name
            }) || defaultParams.imageLibraryStore
          const imageLibraryLocation =
            glanceConfig?.vmware_store_image_dir || defaultParams.imageLibraryLocation
          updateParams({
            imageLibraryIp,
            customImageLibraryEndpoint,
            imageLibraryStore,
            imageLibraryLocation,
          })
        }
      }
      const neutronServerConfig = await getResmgrServiceConfig('neutron-server-vmw')
      const dvs = neutronServerConfig?.dvs_name
      updateParams({
        dvs,
      })
    }
    getCurrentConfig()
  }, [host])

  // const { update, updating, error, reset } = useUpdateAction(updateHostAggregate)

  const { reload } = useListAction(listResmgrHosts, {
    params: {},
  })

  const submitForm = useCallback(async () => {
    setSubmitting(true)
    const sendRequests = async () => {
      const hypervisorBody = {
        cluster_name: params.cluster[0]?.name,
        cpu_allocation_ratio: '16',
        ram_allocation_ratio: '1.5',
        // does this value need to come from the blueprint?
        instances_path: '/opt/pf9/data/instances/',
        datastore_regex: params.datastores.map((datastore) => datastore.name).join(','),
        disk_allocation_ratio: '9999.0',
        reclaim_instance_interval: '0',
        ssl_only: 'False',
      }
      const imageLibraryBody = {
        endpoint_address:
          params.imageLibraryIp === 'custom'
            ? params.customImageLibraryEndpoint
            : params.imageLibraryIp,
        vmware_datacenter_path: params.imageLibraryStore[0]?.datacenter_name,
        vmware_store_image_dir: '/pf9/openstack_glance',
        vmware_datastore_name: params.imageLibraryStore[0]?.name,
        update_public_glance_endpoint: 'true',
        external_endpoint_address: '',
      }
      try {
        await updateVmwHypervisorRole({ host: host, body: hypervisorBody })
        await updateVmwImageLibraryRole({ host: host, body: imageLibraryBody })
      } catch (e) {
        setSubmitting(false)
        setErrorMessage('Error occurred while authorizing roles')
        return
      }
      let hostConverged = false
      while (!hostConverged) {
        try {
          const resmgrHost = await getSingleResmgrHost(host.id)
          if (resmgrHost.role_status === 'ok') {
            hostConverged = true
          }
          await waitSeconds(5)
        } catch {
          // just in case the API errors out
          await waitSeconds(5)
        }
      }
      const neutronBody = {
        core_plugin: 'vmware_nsx.plugins.dvs.pf9_plugin.Pf9NsxDvsV2',
        service_plugins: 'segments',
        dvs_name: params.dvs,
        host_ip: '127.0.0.1',
        insecure: 'True',
      }
      try {
        await updateResmgrServiceConfig('neutron-server-vmw', neutronBody)
      } catch (e) {
        setSubmitting(false)
        setErrorMessage('Error occurred while updating neutron server configuration')
        return
      }
      try {
        const discoveryBody = {
          dc_path: params.imageLibraryStore[0]?.datacenter_name,
          glance_ds: params.imageLibraryStore[0]?.name,
          clusters: params.cluster[0]?.name,
          datastores: params.datastores.map((datastore) => datastore.name).join(','),
        }
        await updateDiscoveryRole({ host: host, body: discoveryBody })
      } catch (e) {
        setSubmitting(false)
        setErrorMessage('Error occurred while pushing discovery role')
        return
      }
      reload(true, true)
      handleClose()
    }
    sendRequests()
  }, [host, params])

  const handleClose = () => {
    setParams(defaultParams)
    // reset()
    onClose()
  }

  return (
    <ModalForm
      title={`Manage VMware Gateway Configuration`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={submitting}
      error={errorMessage ? { message: errorMessage } : null}
      submitTitle={`Update Configuration`}
      maxWidth={1000}
      loading={submitting}
      loadingMessage={
        'Updating vSphere Gateway Configuration... This may take a few minutes. Please wait until the spinner stops to ensure that all settings are applied.'
      }
      open
    >
      <>
        <FormFieldSection title="Select a Cluster to Import">
          <ListTableField
            id="cluster"
            data={clusterList}
            loading={false}
            columns={clusterColumns}
            onChange={getParamsUpdater('cluster')}
            value={params.cluster}
            uniqueIdentifier="cluster_moid"
            searchTargets={['name']}
            checkboxCond={(cluster) => cluster.cluster_usable}
            required
          />
        </FormFieldSection>
        {!!params.cluster?.length && (
          <FormFieldSection title="Select Datastores to Import">
            <Text variant="body2">
              Only datastores from the selected cluster will be shown below.
            </Text>
            <ListTableField
              id="datastores"
              data={datastoresList}
              loading={false}
              columns={datastoreColumns}
              onChange={getParamsUpdater('datastores')}
              value={params.datastores}
              uniqueIdentifier="moid"
              searchTargets={['name']}
              multiSelection
              required
            />
          </FormFieldSection>
        )}
        {!!params.datastores?.length && (
          <FormFieldSection title="Image Library Configuration">
            <PicklistField
              DropdownComponent={ImageLibraryAccessPicklist}
              id="imageLibraryIp"
              label="Image Library Access Endpoint"
              ips={ips}
              onChange={getParamsUpdater('imageLibraryIp')}
              value={params.imageLibraryIp}
              required
            />
            {params.imageLibraryIp === 'custom' && (
              <TextField
                id="customImageLibraryEndpoint"
                label="Custom Image Library Endpoint"
                onChange={getParamsUpdater('customImageLibraryEndpoint')}
                value={params.customImageLibraryEndpoint}
                info="This will be the endpoint for running the Image Library service. This may either be an IP address or FQDN."
                required
              />
            )}
            <TextField
              id="imageLibraryLocation"
              label="Library Location on filesystem"
              onChange={getParamsUpdater('imageLibraryLocation')}
              value={params.imageLibraryLocation}
              info="The path where the image library will be located"
              required
            />
            <Text variant="caption1">Select the Datastore that should back your Image Library</Text>
            <Text variant="body2">
              Any new images uploaded to the Image Library via the Glance REST API or CLI will be
              stored on this Datastore.
            </Text>
            <ListTableField
              id="imageLibraryStore"
              data={selectedDatastoresList}
              loading={false}
              columns={datastoreColumns}
              onChange={getParamsUpdater('imageLibraryStore')}
              value={params.imageLibraryStore}
              uniqueIdentifier="moid"
              searchTargets={['name']}
              required
            />
          </FormFieldSection>
        )}
        {!!params.imageLibraryStore?.length && (
          <FormFieldSection title="Other Configuration">
            <TextField
              id="vmStoragePath"
              label="Virtual Machine Storage Path"
              onChange={getParamsUpdater('vmStoragePath')}
              value={params.vmStoragePath}
              info="The path where VM data will be located"
              required
            />
            <PicklistField
              DropdownComponent={DvsPicklist}
              id="dvs"
              label="Distributed Virtual Switch"
              switches={networkSwitches}
              onChange={getParamsUpdater('dvs')}
              value={params.dvs}
              required
            />
          </FormFieldSection>
        )}
        {!!params.dvs && (
          <FormFieldSection title="Review Configuration">
            <FormReviewTable data={params} columns={reviewColumns} />
          </FormFieldSection>
        )}
      </>
    </ModalForm>
  )
}
