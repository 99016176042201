import React, { useMemo } from 'react'
import Dropdown from 'core/elements/dropdown'
import withProgress from 'core/components/progress/withProgress'
import useListAction from 'core/hooks/useListAction'
import { listPorts } from 'openstack/components/networks/actions'
import { useSelector } from 'react-redux'
import {
  networksByIdSelector,
  subnetsByIdSelector,
  portsSelector,
} from 'openstack/components/networks/selectors'
import useScopedPreferences from 'core/session/useScopedPreferences'

const AsyncDropdown = withProgress(Dropdown, { inline: true })

export default function PortPicklist({
  disabled = false,
  name = 'port',
  label = 'Port',
  networkId = '',
  free = true,
  includeNoneOption = false,
  ...rest
}) {
  const { prefs } = useScopedPreferences()
  const { currentTenant } = prefs

  const { loading } = useListAction(listPorts)
  const networksById = useSelector(networksByIdSelector)
  const subnetsById = useSelector(subnetsByIdSelector)
  const ports = useSelector(portsSelector)

  const options = useMemo(() => {
    // will need to filter out taken private IPs
    const filteredPorts = ports.filter((port) => {
      if (port.project_id !== currentTenant) {
        return false
      }
      if (free) {
        if (port.status !== 'DOWN') {
          return false
        }
      }
      if (!networkId) {
        return true
      }
      return port.network_id === networkId
    })
    const portOptions = filteredPorts.map((port) => {
      const ipAddressString = port.fixed_ips?.map((ip) => ip.ip_address)?.join(', ')
      return {
        label: `${ipAddressString} (${port.name || port.id})`,
        value: port,
        key: port.id,
      }
    })
    return includeNoneOption ? [{ label: 'None', value: null }, ...portOptions] : portOptions
  }, [networkId, free, currentTenant, ports])

  return (
    <AsyncDropdown
      {...rest}
      name={name}
      label={label}
      compact={false}
      disabled={disabled}
      loading={loading}
      items={options}
    />
  )
}
