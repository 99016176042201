import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { clientActions } from 'core/client/clientReducers'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import useReactRouter from 'use-react-router'
import DocumentMeta from 'core/components/DocumentMeta'
import { routes } from 'core/utils/routes'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listRouters } from './actions'
import { routersSelector } from './selectors'
import { IRouterDetailsPageTabs } from './model'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Overview from './Overview'
import RouterInterfacesPage from './RouterInterfacesPage'

const useStyles = makeStyles<Theme>((theme) => ({
  grid: {
    display: 'grid',
    gap: 32,
  },
  actions: {
    display: 'grid',
    gridAutoColumns: 'auto max-content',
    gridAutoFlow: 'column',
    gap: 16,
  },
}))

const RouterDetailsPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id } = match.params
  const { loading } = useListAction(listRouters)
  const routers = useSelectorWithParams(routersSelector, {})
  const router = useMemo(() => routers.find((router) => router.id === id) || {}, [id, routers])

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        id: router?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [router?.name, id])

  return (
    <>
      <DocumentMeta title="Router Details" breadcrumbs />
      <Tabs route={routes.openstack.routerDetails}>
        <Tab label="Overview" value={IRouterDetailsPageTabs.Overview}>
          <Overview router={router} loading={loading} />
        </Tab>
        <Tab label="Interfaces" value={IRouterDetailsPageTabs.Interfaces}>
          <RouterInterfacesPage router={router} loading={loading} />
        </Tab>
      </Tabs>
    </>
  )
}

export default RouterDetailsPage
