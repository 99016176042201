import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { getFieldsForCard } from 'core/components/InfoPanel'
import InfoCard from 'k8s/components/common/entity/info-card'
import Progress from 'core/components/progress/Progress'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
}))

const metadataFields = [
  {
    id: 'name',
    title: 'Name',
  },
  {
    id: 'id',
    title: 'ID',
  },
  {
    id: 'router:external',
    title: 'External Network',
    render: (external) => (external ? 'True' : 'False'),
  },
  {
    id: 'provider:network_type',
    title: 'Network Type',
  },
  {
    id: 'provider:physical_network',
    title: 'Physical Network',
  },
  {
    id: 'provider:segmentation_id',
    title: 'Segmentation ID',
  },
  {
    id: 'port_security_enabled',
    title: 'Port Security',
    render: (enabled) => (enabled ? 'Enabled' : 'Disabled'),
  },
  {
    id: 'status',
    title: 'Status',
  },
  {
    id: 'admin_state_up',
    title: 'Admin State',
    render: (isUp) => (isUp ? 'Up' : 'Down'),
  },
  {
    id: 'shared',
    title: 'Shared',
    render: (shared) => (shared ? 'True' : 'False'),
  },
]

export default function Overview({ network, loading }) {
  const classes = useStyles({})

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, network)
  }, [network])

  return (
    <Progress loading={loading}>
      <div className={classes.overview}>
        <div className={classes.column}>
          <InfoCard items={metadata} title="Properties" />
        </div>
      </div>
    </Progress>
  )
}
