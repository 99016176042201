import React, { useCallback, useEffect, useState } from 'react'
import useParams from 'core/hooks/useParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { listNetworks, updateSubnet } from '../actions'
import ModalForm from 'core/elements/modal/ModalForm'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { BasicSubnetFields, AdvancedSubnetFields } from '../SubnetFormFields'
import {
  getIPv6AdditionalFields,
  getIPv6AddressConfigurationMode,
} from '../IPv6AddressConfigurationModePicklist'
import useListAction from 'core/hooks/useListAction'

const useStyles = makeStyles<Theme>((theme) => ({}))

export default function EditSubnetModal({ rows: [subnet], onClose }) {
  const [loaded, setLoaded] = useState(false)
  const classes = useStyles()

  const defaultParams = {}
  const { params, getParamsUpdater, setParams, updateParams } = useParams<{
    name?: string
    cidr?: string
    gatewayIp?: string
    disableGateway?: boolean
    allocationPools?: any[]
    dnsNameServers?: string[]
    hostRoutes?: string[]
    enableDhcp?: boolean
    ipv6AddressConfigurationMode?: string
  }>(defaultParams)

  const { reload } = useListAction(listNetworks, {})
  const { update, updating, error, reset } = useUpdateAction(updateSubnet)

  useEffect(() => {
    // Prevent resetting of form from background reloads
    if (loaded) {
      return
    }
    updateParams({
      name: subnet.name,
      cidr: subnet.cidr,
      gatewayIp: subnet.gateway_ip,
      disableGateway: subnet.gateway_ip === null,
      allocationPools: subnet.allocation_pools?.map((pool) => `${pool.start} - ${pool.end}`),
      dnsNameServers: subnet.dns_nameservers,
      hostRoutes: subnet.host_routes?.map((route) => `${route.destination}, ${route.nexthop}`),
      enableDhcp: subnet.enable_dhcp,
      ipv6AddressConfigurationMode: getIPv6AddressConfigurationMode(subnet),
    })
    setLoaded(true)
  }, [subnet, loaded])

  const updateSubnetFn = async (params, subnetId) => {
    const allocationPools = params.allocationPools.map((entry) => {
      const [start, end] = entry.replace(/\s/g, '').split('-')
      return {
        start,
        end,
      }
    })
    const hostRoutes = params.hostRoutes.map((entry) => {
      const [cidr, nexthop] = entry.replace(/\s/g, '').split(',')
      return {
        destination: cidr,
        nexthop,
      }
    })
    const body = {
      subnet: {
        name: params.name,
        gateway_ip: params.disableGateway ? null : params.gatewayIp ? params.gatewayIp : undefined,
        allocation_pools: allocationPools.length ? allocationPools : undefined,
        dns_nameservers: params.dnsNameServers.length ? params.dnsNameServers : undefined,
        host_routes: hostRoutes.length ? hostRoutes : undefined,
        enable_dhcp: params.enableDhcp,
        ...getIPv6AdditionalFields(params),
      },
    }
    const { success } = await update({ id: subnetId, body })
    return { success }
  }

  const submitForm = useCallback(async () => {
    const { success } = await updateSubnetFn(params, subnet.id)
    if (success) {
      reload(true, true)
      handleClose()
    }
  }, [params, subnet.id])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    onClose()
  }

  return (
    <ModalForm
      open
      title={`Edit Subnet`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      submitTitle={`Update Subnet`}
      loading={!loaded}
    >
      <>
        <BasicSubnetFields params={params} getParamsUpdater={getParamsUpdater} inSubnetEdit />
        <AdvancedSubnetFields params={params} updateParams={updateParams} />
      </>
    </ModalForm>
  )
}
