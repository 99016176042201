import React, { useCallback, useEffect, useState } from 'react'
import useParams from 'core/hooks/useParams'
import ModalForm from 'core/elements/modal/ModalForm'
import { updateNetwork } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import TextField from 'core/components/validatedForm/TextField'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import AdminStatePicklist from './AdminStatePicklist'

export default function EditNetworkModal({ rows: [network], onClose }) {
  const [loaded, setLoaded] = useState(false)
  const defaultParams = {}

  const { params, getParamsUpdater, updateParams, setParams } = useParams<{
    name?: string
    description?: string
    adminState?: string
    shared?: boolean
  }>(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(updateNetwork)

  useEffect(() => {
    // Prevent resetting of form from background reloads
    if (loaded) {
      return
    }
    updateParams({
      name: network.name,
      description: network.description,
      adminState: network.admin_state_up ? 'Up' : 'Down',
      shared: network.shared,
    })
    setLoaded(true)
  }, [network, loaded])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    onClose()
  }

  const submitForm = useCallback(async () => {
    const body = {
      network: {
        name: params.name,
        description: params.description,
        admin_state_up: params.adminState !== 'Down',
        shared: params.shared,
      },
    }
    const { success } = await update({
      id: network.id,
      body,
    })
    if (success) {
      handleClose()
    }
  }, [network, params, handleClose])

  return (
    <ModalForm
      open
      title={`Edit Network`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      submitTitle={`Update Network`}
      loading={!loaded}
    >
      <FormFieldSection title="Network Configuration">
        <TextField
          id="name"
          label="Name"
          onChange={getParamsUpdater('name')}
          value={params.name}
          required
        />
        <TextField
          id="description"
          label="Description"
          onChange={getParamsUpdater('description')}
          value={params.description}
        />
        <AdminStatePicklist value={params.adminState} onChange={getParamsUpdater('adminState')} />
        <CheckboxField
          id="shared"
          label="Make Shared"
          value={params.shared}
          onChange={getParamsUpdater('shared')}
          info="If checked, network will be available for every project."
        />
      </FormFieldSection>
    </ModalForm>
  )
}
