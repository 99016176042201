import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { networksByIdSelector } from '../selectors'
import { virtualMachinesByIpSelector } from 'openstack/components/vms/selectors'

export const floatingIpsSelector = createSharedSelector(
  getDataSelector<DataKeys.FloatingIps>(DataKeys.FloatingIps),
  virtualMachinesByIpSelector,
  networksByIdSelector,
  (floatingIps, vmsByIp, networksById) => {
    return floatingIps.map((floatingIp) => {
      return {
        ...floatingIp,
        allocation: vmsByIp?.[floatingIp?.fixed_ip_address],
        network: networksById?.[floatingIp?.floating_network_id],
      }
    })
  },
)

export const floatingIpsByDeviceIdSelector = createSharedSelector(
  floatingIpsSelector,
  (floatingIps) =>
    floatingIps.reduce((accum, floatingIp) => {
      return floatingIp?.port_details?.device_id
        ? accum[floatingIp.port_details.device_id]
          ? {
              ...accum,
              [floatingIp.port_details.device_id]: [
                ...accum[floatingIp.port_details.device_id],
                floatingIp,
              ],
            }
          : {
              ...accum,
              [floatingIp.port_details.device_id]: [floatingIp],
            }
        : accum
    }, {}),
)
