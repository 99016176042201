import React, { useCallback, useEffect, useMemo } from 'react'
import useParams from 'core/hooks/useParams'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { updateImage } from './actions'
import ModalForm from 'core/elements/modal/ModalForm'
import TextField from 'core/components/validatedForm/TextField'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { useSelector } from 'react-redux'
import { prop } from 'ramda'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { RootState } from 'app/store'
import { isAdminRole } from 'k8s/util/helpers'
import CheckboxField from 'core/components/validatedForm/CheckboxField'

const useStyles = makeStyles<Theme>((theme) => ({}))

interface Props {
  rows: any[]
  onClose: () => void
}

export default function EditImageModal({ rows: [image], onClose }: Props) {
  const classes = useStyles()
  const defaultParams = {
    name: '',
    public: false,
    protected: false,
  }

  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  useEffect(() => {
    updateParams({
      name: image.name,
      public: image.visibility === 'public',
      protected: image.protected,
    })
  }, [])

  const { update, updating, error, reset } = useUpdateAction(updateImage)

  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const isAdmin = useMemo(() => isAdminRole(session), [session])

  const submitForm = useCallback(async () => {
    const body = [
      {
        op: 'replace',
        path: '/name',
        value: params.name,
      },
      {
        op: 'replace',
        path: '/visibility',
        value: params.public ? 'public' : 'private',
      },
      {
        op: 'replace',
        path: '/protected',
        value: params.protected,
      },
    ]
    const { success, response: updatedUser } = await update({ id: image.id, body })
    if (!success) return
    handleClose()
  }, [image?.id, isAdmin, params])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    onClose()
  }

  return (
    <ModalForm
      title={`Edit Image`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      submitTitle={`Update Image`}
      open
    >
      <>
        <FormFieldSection title="Image Settings">
          <TextField
            id="name"
            label="Name"
            onChange={getParamsUpdater('name')}
            value={params.name}
            required
          />
          {!!isAdmin && (
            <CheckboxField
              id="public"
              label="Make Public"
              onChange={getParamsUpdater('public')}
              value={params.public}
              info="Public images will be available across all tenants. Private images will only be available within the same tenant."
            />
          )}
          <CheckboxField
            id="protected"
            label="Protected"
            onChange={getParamsUpdater('protected')}
            value={params.protected}
            info="Protect images from deletion."
          />
        </FormFieldSection>
      </>
    </ModalForm>
  )
}
