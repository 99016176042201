import React, { useCallback } from 'react'
import useParams from 'core/hooks/useParams'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createSubnet, listNetworks } from '../actions'
import ModalForm from 'core/elements/modal/ModalForm'
import { Route } from 'core/plugins/route'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { BasicSubnetFields, AdvancedSubnetFields } from '../SubnetFormFields'
import { getIPv6AdditionalFields } from '../IPv6AddressConfigurationModePicklist'
import useListAction from 'core/hooks/useListAction'
import { INetworkDetailsPageTabs } from './model'
import { useSelector } from 'react-redux'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { RootState } from 'app/store'
import { prop } from 'ramda'

const useStyles = makeStyles<Theme>((theme) => ({}))

interface Props {
  addRoute: Route
  isVirtualNetwork?: boolean
}

export default function CreateSubnetModal({ addRoute, isVirtualNetwork = false }: Props) {
  const classes = useStyles()
  const { history, match } = useReactRouter()
  const { id: networkId } = match.params
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const { features } = session
  const isVmware = features?.experimental?.pmov2_du_type === 'vmware'

  const defaultParams = {
    name: '',
    ipVersion: 4,
    cidr: '',
    gatewayIp: '',
    disableGateway: false,
    allocationPools: [],
    dnsNameServers: [],
    hostRoutes: [],
    enableDhcp: true,
    ipv6AddressConfigurationMode: '',
  }
  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  const { reload } = useListAction(listNetworks, {})
  const { update, updating, error, reset } = useUpdateAction(createSubnet)
  const parentRoute = isVmware
    ? routes.openstack.vmwareNetworkDetails
    : isVirtualNetwork
    ? routes.openstack.virtualNetworkDetails
    : routes.openstack.networkDetails

  const createSubnetFn = async (params, networkId) => {
    const allocationPools = params.allocationPools.map((entry) => {
      const [start, end] = entry.replace(/\s/g, '').split('-')
      return {
        start,
        end,
      }
    })
    const hostRoutes = params.hostRoutes.map((entry) => {
      const [cidr, nexthop] = entry.replace(/\s/g, '').split(',')
      return {
        destination: cidr,
        nexthop,
      }
    })
    const body = {
      subnet: {
        name: params.name,
        network_id: networkId,
        ip_version: params.ipVersion,
        cidr: params.cidr,
        gateway_ip: params.disableGateway ? null : params.gatewayIp ? params.gatewayIp : undefined,
        allocation_pools: allocationPools.length ? allocationPools : undefined,
        dns_nameservers: params.dnsNameServers.length ? params.dnsNameServers : undefined,
        host_routes: hostRoutes.length ? hostRoutes : undefined,
        enable_dhcp: params.enableDhcp,
        ...getIPv6AdditionalFields(params),
      },
    }
    const { success } = await update({ body })
    return { success }
  }

  const submitForm = useCallback(async () => {
    const { success } = await createSubnetFn(params, networkId)
    if (success) {
      reload(true, true)
      handleClose()
    }
  }, [params, networkId])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(
      parentRoute.path({
        id: networkId,
        tab: INetworkDetailsPageTabs.Subnets,
      }),
    )
  }

  return (
    <ModalForm
      route={addRoute}
      title={`Create Subnet`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      submitTitle={`Create Subnet`}
    >
      <>
        <BasicSubnetFields params={params} getParamsUpdater={getParamsUpdater} />
        <AdvancedSubnetFields params={params} updateParams={updateParams} />
      </>
    </ModalForm>
  )
}
