import React, { useMemo } from 'react'
import Dropdown from 'core/elements/dropdown'
import { MultiDropdownProps } from 'core/elements/dropdown/MultiDropdown'
import withProgress from 'core/components/progress/withProgress'
import useListAction from 'core/hooks/useListAction'
import { listNetworks } from '../actions'
import { useSelector } from 'react-redux'
import { externalNetworksSelector } from '../selectors'

const AsyncDropdown = withProgress(Dropdown, { inline: true })

export default function ExternalNetworkPicklist({
  disabled = false,
  name = 'externalNetwork',
  label = 'External Network',
  includeNoneOption = false,
  ...rest
}) {
  const { loading } = useListAction(listNetworks)
  const externalNetworks = useSelector(externalNetworksSelector)

  const options = useMemo(() => {
    const networkOptions = externalNetworks.map((network) => ({
      label: network.name || network.id,
      value: network.id,
    }))
    return includeNoneOption ? [{ label: 'None', value: '' }, ...networkOptions] : networkOptions
  }, [externalNetworks, includeNoneOption])

  return (
    <AsyncDropdown
      {...rest}
      name={name}
      label={label}
      compact={false}
      disabled={disabled}
      loading={loading}
      items={options}
    />
  )
}
