import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import TextField from 'core/components/validatedForm/TextField'
import Tooltip from 'core/elements/tooltip'
import { topMiddle } from 'core/elements/menu/defaults'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { remove, update } from 'ramda'
import { customValidator } from 'core/utils/fieldValidators'
import * as IpAddress from 'ip-address'
import { IPVersions } from 'app/constants'

const dnsNameServerValidIp = customValidator((value, formValues) => {
  if (!value) {
    return true
  }
  try {
    const ip =
      formValues.ipVersion === IPVersions.IPv4
        ? new IpAddress.Address4(value)
        : new IpAddress.Address6(value)
    return !!ip
  } catch (err) {
    return false
  }
}, 'DNS Name Server must be a valid IPv4/IPv6 address')

export default function DnsNameServersField({ params, updateParams }) {
  const classes = useStyles()

  const addNameserver = useCallback(() => {
    updateParams({ dnsNameServers: [...params.dnsNameServers, ''] })
  }, [params.dnsNameServers])

  const removeNameserver = useCallback(
    (index) => {
      updateParams({ dnsNameServers: remove(index, 1, params.dnsNameServers) })
    },
    [params.dnsNameServers],
  )

  return (
    <div className={classes.subnetField}>
      <div className={classes.fieldLabel}>
        <Text variant="caption1">DNS Name Servers</Text>
        <Tooltip
          message="Specify the IP addresses that will be used as DNS name servers for this subnet. By default, Instances utilize Neutron for DNS resolution."
          align={topMiddle.align}
          offset={topMiddle.offset}
          origin="right center"
        >
          <Text variant="inputLabel" className={classes.hint}>
            <FontAwesomeIcon>question-circle</FontAwesomeIcon>
          </Text>
        </Tooltip>
      </div>
      <div>
        {params.dnsNameServers.map((nameserver, index) => (
          <div key={index} className={classes.inputRow}>
            <TextField
              id={`dnsNameServers.${index}`}
              label=""
              placeholder={`DNS Name Server ${index + 1}`}
              value={nameserver}
              onChange={(value) =>
                updateParams({ dnsNameServers: update(index, value, params.dnsNameServers) })
              }
              validations={[dnsNameServerValidIp]}
              required
            />
            <FontAwesomeIcon className={classes.minus} onClick={() => removeNameserver(index)}>
              minus-circle
            </FontAwesomeIcon>
          </div>
        ))}
        <div className={classes.addEntry}>
          <FontAwesomeIcon className={classes.plus} onClick={() => addNameserver()} size="lg">
            plus-circle
          </FontAwesomeIcon>
          <Text variant="body2" onClick={() => addNameserver()} className={classes.clickable}>
            Add DNS Name Server
          </Text>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  subnetField: {
    display: 'grid',
    gap: 8,
  },
  fieldLabel: {
    display: 'grid',
    maxWidth: 400,
    minWidth: 100,
    width: '100%',
    gridTemplateColumns: 'max-content minmax(0px, max-content)',
    gap: 8,
    alignItems: 'center',
  },
  hint: {
    cursor: 'help',
    transition: 'color .2s ease',
    color: theme.components.input.label.hint,
    '& i': {
      cursor: 'help',
      marginRight: 8,
      transition: 'color .2s ease',
      color: theme.components.input.label.hint,
    },
  },
  multipleInputs: {
    display: 'grid',
    gap: 12,
  },
  inputRow: {
    display: 'grid',
    gridTemplateColumns: '356px 32px',
    gridGap: '12px',
    justifyItems: 'start',
    alignItems: 'center',
  },
  minus: {
    fontWeight: 900,
    color: theme.palette.blue.main,
    justifySelf: 'end',
    position: 'relative',
    top: 4,
  },
  addEntry: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, max-content)',
    marginTop: 12,
    marginLeft: 8,
    alignItems: 'center',
  },
  plus: {
    color: theme.palette.blue.main,
    marginRight: 8,
    fontWeight: 900,
  },
  clickable: {
    cursor: 'pointer',
  },
}))
