import React, { useEffect, useCallback, useMemo, useState } from 'react'
import useParams from 'core/hooks/useParams'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import { Route } from 'core/plugins/route'
import TextField from 'core/components/validatedForm/TextField'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import ProviderNetworkTypePicklist from 'openstack/components/networks/ProviderNetworkTypePicklist'
import AllocationPoolsField from 'openstack/components/networks/AllocationPoolsField'
import DnsNameServersField from 'openstack/components/networks/DnsNameServersField'
import {
  cidrValidator,
  gatewayInCidrValidator,
  gatewayIpValid,
} from 'openstack/components/networks/SubnetFormFields'
import { customValidator } from 'core/utils/fieldValidators'

const useStyles = makeStyles<Theme>((theme) => ({}))

interface Props {
  rows: any[]
  onClose: () => void
}

export default function EditVmwareNetworkModal({ rows: [network], onClose }: Props) {
  const classes = useStyles()

  // based on network
  const defaultParams = {
    networkType: 'flat',
    vmNetworkPhysNet: '',
    name: 'vm-net',
    cidr: '192.168.1.0/24',
    gatewayIpAddress: '192.168.1.1',
    segmentationId: '',
    allocationPools: [],
    dnsNameServers: [],
    external: false,
  }

  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  const submitForm = useCallback(async () => {
    // const network = {
    //   vmNetworkName: params.vmNetworkName,
    //   vmNetworkPhysNet: params.vmNetworkPhysNet,
    //   networkType: params.networkType,
    //   segmentationId: params.segmentationId,
    //   cidr: params.cidr,
    //   gatewayIpAddress: params.gatewayIpAddress,
    //   allocationPools: params.allocationPools,
    //   dnsNameServers: params.dnsNameServers,
    //   external: params.external,
    // }
    // updateNetwork(network)
    handleClose()
  }, [params])

  const handleClose = () => {
    setParams(defaultParams)
    onClose()
  }

  return (
    <ModalForm
      title="Update Network"
      onSubmit={submitForm}
      onClose={handleClose}
      // submitting={updating || updatingSubnet}
      // error={error || subnetError}
      submitTitle="Update Network"
      open
    >
      <FormFieldSection title="Network Settings">
        <TextField
          id="name"
          label="Network Name"
          onChange={getParamsUpdater('name')}
          value={params.name}
          info="The name of the network in neutron"
          required
        />
        <ProviderNetworkTypePicklist
          name="networkType"
          label="Network Type"
          value={params.networkType}
          onChange={getParamsUpdater('networkType')}
        />
        {['vlan', 'vxlan'].includes(params.networkType) && (
          <TextField
            id="segmentationId"
            label="VLAN ID"
            onChange={getParamsUpdater('segmentationId')}
            value={params.segmentationId}
            info="Some description goes here"
            required
          />
        )}
      </FormFieldSection>
    </ModalForm>
  )
}
