import React, { useMemo } from 'react'
import Dropdown from 'core/elements/dropdown'
import withProgress from 'core/components/progress/withProgress'
import useListAction from 'core/hooks/useListAction'
import { listSecurityGroups } from './actions'
import { useSelector } from 'react-redux'
import { securityGroupsSelector } from './selectors'

const AsyncDropdown = withProgress(Dropdown, { inline: true })

export default function SecurityGroupsPicklist({
  disabled = false,
  name = 'securityGroup',
  label = 'Security Group',
  ...rest
}) {
  const { loading } = useListAction(listSecurityGroups)
  const securityGroups = useSelector(securityGroupsSelector)

  const options = useMemo(() => {
    return securityGroups.map((group) => ({
      label: `${group.name || group.id}`,
      value: group,
    }))
  }, [securityGroups])

  return (
    <AsyncDropdown
      {...rest}
      name={name}
      label={label}
      compact={false}
      disabled={disabled}
      loading={loading}
      items={options}
    />
  )
}
